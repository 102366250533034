<ng-template #dynamicUniq>

    <div class="table-responsive my-3">
        <table class="table table-condensed" [ngClass]="clasess">
            <thead>
                <ng-container *ngIf="extraHeader.length > 0">
                    <tr class="extraHead">
                        <th [attr.colspan]="1"
                            class="text-center border border-top-0 border-light"
                            width='30%' *ngFor="let item of [extraHeader]; let i = index">
                            {{item}}
                        </th>
                    </tr>
                </ng-container>
                <ng-container *ngIf='divider !== "extraNormal"'>
                    <tr>
                        <th *ngFor="let colunm of colunms" class="text-center">

                            <ng-template #showHead>
                                {{headerName[colunm]? headerName[colunm]: colunm}}
                            </ng-template>
                            
                            <ng-container *ngIf="colunm === 'check'; else showHead"></ng-container>
            
                        </th>
                    </tr>
                </ng-container>
            </thead>
            <ng-container *ngIf='divider === "extraNormal"'>
                <tbody class="extra">
                    <ng-container *ngFor="let item of data">
                        <tr *ngFor="let colunm of colunms">
                            <th>{{headerName[colunm]? headerName[colunm]: colunm}}</th>
                            <td>{{item[colunm]}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </ng-container>

            <ng-container *ngIf='divider === "normal"'>
                <tbody>
                    <ng-container *ngIf="data.length > 0; else sinData">
                        <tr
                            *ngFor="let item of data | orderBy: 'date': 'desc' | paginate:{itemsPerPage: currentItem, currentPage: currentPage}; let i = index">
                            <td *ngFor="let row of colunms" class="text-center">
                                <ng-container *ngIf="row === 'search' || row === 'edit' || row === 'trash'; else rowNormal">

                                    <ng-container [ngSwitch]="row">
                                        <ng-container *ngSwitchCase="'search'">
                                            <button (click)="funcionalReport(item, row)" class="btn-group border-0"
                                                *ngIf="item['EstadoTransaccion'] === 'COMPLETED' || item['EstadoTransaccion'] === 'CLOSED'">
                                                <a class="formularioReporte">
                                                    <i [class]="'fa fa-'+row"></i>
                                                </a>
                                            </button>
                                        </ng-container>
                                        
                                        <ng-container *ngSwitchCase="'trash'">
                                            <button (click)="funcionalReport(item, row)" class="btn-group border-0">
                                                <a class="formularioReporte">
                                                    <i [class]="'text-danger fa fa-'+row"></i>
                                                </a>
                                            </button>
                                    </ng-container>

                                        <ng-container *ngSwitchDefault>
                                            <ng-container *ngIf="item['EstadoTransaccion'] === 'IP' && contentEditable">
                                                <button (click)="funcionalReport(item, row)" class="btn-group border-0">
                                                    <a class="formularioReporte">
                                                        <i [class]="'fa fa-'+row"></i>
                                                    </a>
                                                </button>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>







                                </ng-container>
                                <ng-template #rowNormal>
                                    <ng-template #tdNormal>
                                        <ng-container *ngIf="icon; else normalValue">
                                            <ng-container *ngIf="iconExcluir === row; else sinExcluir">
                                                {{item[row]}}
                                            </ng-container>

                                            <ng-template #sinExcluir>
                                                <span class="material-icons text-success"
                                                    *ngIf="item['check'] === 1 || item[row] === 1">check</span> 
                                            </ng-template>

                                        </ng-container>

                                        <ng-template #normalValue>
                                            {{item[row]}}
                                        </ng-template>

                                    </ng-template>

                                    <ng-container *ngIf="row === 'EstadoTransaccion' || row === 'date'; else tdNormal">

                                        <ng-container *ngIf="row === 'EstadoTransaccion'; else isDateRow">
                                            <span class="label" [class]='item[row]'>{{item[row]}}</span>
                                        </ng-container>

                                        <ng-template #isDateRow>
                                            {{item[row] | date: 'dd-MM-yyyy'}}
                                        </ng-template>

                                    </ng-container>

                                </ng-template>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-template #sinData>
                        <tr>
                            <td class="text-center" [attr.colspan]="colunms.length">Sin Informacion</td>
                        </tr>
                    </ng-template>
                </tbody>
            </ng-container>
            <ng-container *ngIf='divider === "divider"'>
                <tbody>
                    <ng-container *ngFor="let item of data">
                        <tr *ngFor="let value of item.group" [ngClass]='{"hideRow": valDivider !== item.date}'>
                            <td *ngFor="let row of colunms; let i = index" class="text-center">
                                <ng-template #date>
                                    <span class="text-capitalize"> {{value[row] | date:'LLLL'}}</span>
                                </ng-template>
                                <ng-container *ngIf="i > 0; else date">
                                    {{value[row] | number:'1.0-0'}}
                                </ng-container>
                            </td>
                        </tr>
                        <tr class="dividerTr" (click)='valDivider = item.date' (click)='valDivider = item.date'>
                            <td *ngFor="let row of colunms; let i = index" class="text-center">
                                <ng-template #date>
                                    Total {{item[row]}}
                                </ng-template>
                                <ng-container *ngIf='i > 0; else date'>
                                    <ng-container *ngFor="let total of item.total; let i = index">
                                        {{ row === 'date'? '' : total[row] | number:'1.0-0'}}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </ng-container>
            <ng-container *ngIf='divider === ""'>
                <tbody>
                    <ng-container *ngIf="data.length > 0; else sinData">
                        <tr
                            *ngFor="let item of data | paginate:{itemsPerPage: currentItem, currentPage: currentPage}; let i = index">
                            <td *ngFor="let row of colunms" class="text-center">
                                {{row === 'TOTAL'? ( item | sumTd) : item[row]}}
                            </td>
                        </tr>
                    </ng-container>
                    <ng-template #sinData>
                        <tr>
                            <td class="text-center" [attr.colspan]="colunms.length">Sin Informacion</td>
                        </tr>
                    </ng-template>
                </tbody>
            </ng-container>
        </table>
        <div id="pagination" *ngIf="(!divider || divider === 'normal') && !paginationExcluir">
            <div class="col-xs-10">
                <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
            </div>
            <div class="col-xs-2 text-right">
                <app-paginator-by-items [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
            </div>
        </div>
    </div>
</ng-template>

<div *ngIf="divider === 'group' else dynamicUniq" class="row groupsTable">
    <div class="col-sm-6" *ngFor="let table of data">
        <div class="table-responsive my-3">
            <table class="table table-condensed border border-dark" [ngClass]="clasess">
                <thead>
                    <tr>
                        <th colspan="100%" class="text-center">Año: {{table.date}}</th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <th *ngFor="let colunm of colunms">{{colunm === 'date'? 'meses': colunm}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let groups of table.group; let i = index">
                        <ng-container *ngFor="let group of groups['group1']">
                            <tr *ngFor="let value of [group]">
                                <td *ngFor="let row of colunms; let e = index">
                                    <ng-template #date>
                                        <ng-template #dateFormat>
                                            <span class="text-capitalize"> {{value[row] | date:'LLLL'}}</span>
                                        </ng-template>

                                        <ng-container *ngIf="row === 'fechaPago'; else dateFormat">
                                            {{value[row] | date: 'dd-MM-YYYY'}}
                                        </ng-container>

                                    </ng-template>
                                    <ng-container *ngIf="e > 0 && row !== 'fechaPago'; else date">
                                        <ng-container *ngIf="e > 0 && row !== 'cod.524' && row !== 'cod.91' && row !== 'posterga'; else noDecimal">
                                            {{value[row] | number:'1.0-2'}}
                                        </ng-container>
                                        <ng-template #noDecimal>
                                            {{value[row] | number:'1.0-0'}}
                                        </ng-template>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>

                        <tr class='group'>
                            <td *ngFor="let row of colunms; let e = index">
                                <ng-template #date>

                                    <ng-container *ngIf="row !== 'fechaPago'">
                                        SubTotal
                                    </ng-container>

                                </ng-template>
                                <ng-container *ngIf='e > 0 && row !== "fechaPago"; else date'>
                                    <ng-container *ngIf="e > 0 && row !== 'cod.524' && row !== 'cod.91' && row !== 'posterga'; else noDecimal">
                                        <ng-container *ngFor="let total of groups.total">
                                            <ng-container *ngFor="let inner of total.item1">
                                                {{ inner[row] | number:'1.0-2'}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #noDecimal>
                                        <ng-container *ngFor="let total of groups.total">
                                            <ng-container *ngFor="let inner of total.item1">
                                                {{ inner[row] | number:'1.0-0'}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                            </td>
                        </tr>

                        <ng-container *ngFor="let group of groups['group2']">
                            <tr *ngFor="let value of [group]">
                                <td *ngFor="let row of colunms; let e = index">
                                    <ng-template #date>
                                        <ng-template #dateFormat>
                                            <span class="text-capitalize"> {{value[row] | date:'LLLL'}}</span>
                                        </ng-template>

                                        <ng-container *ngIf="row === 'fechaPago'; else dateFormat">
                                            {{value[row] | date: 'dd-MM-YYYY'}}
                                        </ng-container>
                                    </ng-template>
                                    <ng-container *ngIf="e > 0 && row !== 'fechaPago'; else date">
                                        <ng-container *ngIf="e > 0 && row !== 'cod.524' && row !== 'cod.91' && row !== 'posterga'; else noDecimal">
                                            {{value[row] | number:'1.0-2'}}
                                        </ng-container>
                                        <ng-template #noDecimal>
                                            {{value[row] | number:'1.0-0'}}
                                        </ng-template>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                        <tr class='group'>
                            <td *ngFor="let row of colunms; let f = index">
                                <ng-template #date>
                                    <ng-container *ngIf="row !== 'fechaPago'">
                                        SubTotal
                                    </ng-container>
                                </ng-template>
                                <ng-container *ngIf='f > 0 && row !== "fechaPago"; else date'>
                                    <ng-container *ngIf="f > 0 && row !== 'cod.524' && row !== 'cod.91' && row !== 'posterga'; else noDecimal">
                                        <ng-container *ngFor="let total of groups.total">
                                            <ng-container *ngFor="let inner of total.item2">
                                                {{ inner[row] | number:'1.0-2'}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #noDecimal>
                                        <ng-container *ngFor="let total of groups.total">
                                            <ng-container *ngFor="let inner of total.item2">
                                                {{ inner[row] | number:'1.0-0'}}
                                            </ng-container>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                    <tr class='group'>
                        <td *ngFor="let row of colunms; let f = index">
                            <ng-template #totalRows>
                                <ng-container *ngIf="row !== 'fechaPago'">
                                    Total
                                </ng-container>
                            </ng-template>
                            <ng-container *ngIf='f > 0 && row !== "fechaPago"; else totalRows'>
                                <ng-container *ngIf="f > 0 && row !== 'cod.524' && row !== 'cod.91' && row !== 'posterga'; else noDecimal">
                                    <ng-container *ngFor="let item of table.total">
                                        {{item[row] | number:'1.0-2'}}
                                    </ng-container>
                                </ng-container>
                                <ng-template #noDecimal>
                                    <ng-container *ngFor="let item of table.total">
                                        {{item[row] | number:'1.0-0'}}
                                    </ng-container>
                                </ng-template>
                            </ng-container>
                        </td>
                    </tr>
                    <tr class='group'>
                        <td *ngFor="let row of colunms; let f = index">
                            <ng-template #totalRows>
                                <ng-container *ngIf="row !== 'fechaPago'">
                                    Promedio
                                </ng-container>
                            </ng-template>
                            <ng-container *ngIf='f > 0 && row !== "fechaPago"; else totalRows'>
                                <ng-template #varPromedio>
                                    <ng-container *ngFor="let item of table.promedio">
                                        {{item[row] | number:'1.0-2'}}
                                    </ng-container>        
                                </ng-template>
                                
                                <ng-container *ngIf="row !== 'var'; else varPromedio">
                                    <ng-container *ngFor="let item of table.promedio">
                                        {{item[row] | number:'1.0-0'}}
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>