import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/components/_alert';
import { BalanceManualService } from 'src/app/shared/services/balance-manual.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { forkJoin, from, of, timer } from 'rxjs';
import { catchError, concatMap, delay, delayWhen, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { RUT } from 'src/app/shared/utils/rut';
import { IBalanceManual } from 'src/app/models/balance-manual/balance-manual.model';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { FormularioF22Service } from 'src/app/shared/services/formulario-f22.service';
import { CamposPersonalizadosService } from 'src/app/shared/services/campos-personalizados.service';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
//import { SimpleModalService } from 'ngx-simple-modal';
import { MatDialog } from '@angular/material/dialog';
import { environment as env} from 'src/environments/environment';
import { ListErrorsSimpleDialogComponent } from 'src/app/components/list-errors-simple-dialog/list-errors-simple-dialog.component';
import { ModalBalanceManualLogComponent } from 'src/app/components/modal-balance-manual-log/modal-balance-manual-log.component';
import { ReporteBalanceManualViewComponent } from 'src/app/components/reporte-balance-manual-view/reporte-balance-manual-view.component';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';

@Component({
  selector: 'app-ingreso-balance-manual',
  templateUrl: './ingreso-balance-manual.component.html',
  styleUrls: ['./ingreso-balance-manual.component.scss']
})
export class IngresoBalanceManualComponent implements OnInit {
  @Input() rut: string = '';
  @Output() cerrarReporte = new EventEmitter<void>();
  @ViewChild(ReporteBalanceManualViewComponent) reporteBalanceManualViewComponent!: ReporteBalanceManualViewComponent

  public campos: any = {};
  public definicionBalance: any = {};
  public camposBalance: any = {};
  public secciones: any[] = [];
  public objectKeys = Object.keys;
  public formularioForm!: UntypedFormGroup;
  public showRuts: boolean = false; // muestra lista ruts
  public showList: boolean = false; // muestra el listado
  public showReporte: boolean = false; // mostrar reporte
  public showListaBalances: boolean = false; // mostrar listado balances un rut
  public periodo: number | string = 0;
  public mesInicioIngresado: number | string = '';
  public mesFinalIngresado: number | string = '';
  public listaBalances: any[] = []; // ruts asociados
  public periodos: number[] = []; // anio actual y dos anteriores
  public dataReporte: any[] = [];
  public rutSeleccionado: string = '';
  public groupNameConsulting: string[] = ['listarBalance', 'listarPreBalance'];
  public groupNameConsultingF29F22: string[] = ['f29', 'f22'];
  public respuestaPreBalance: IBalanceManual[] = [];
  public respuestaBalance: IBalanceManual[] = [];
  public listaSeleccionada: any[] = [];
  public arrayContrato: any[] = [];
  
  public tipoBalanceWeb: string = "";
  public tipoBalanceWebOriginal: string = "";
  public tipoBalance2Web: string = "";
  public auditadoWeb: string | boolean = "";
  public monedaWeb: string = "";

  public tipoBalanceWebOptions: {value: string, text: string}[] = [{value: 'balance', text: 'Balance'}, {value: 'prebalance', text: 'Prebalance'}];
  public tipoBalance2WebOptions: {value: string, text: string}[] = [{value: 'Individual', text: 'Individual'}, {value: 'Consolidado', text: 'Consolidado'}, {value: 'Garante', text: 'Garante'}];
  public auditadoWebOptions: {value: boolean, text: string}[] = [{value: true, text: 'Si'}, {value: false, text: 'No'}];
  public monedaWebOptions: {value: string, text: string}[] = [{value: 'M$', text: 'M$'}, {value: 'UF', text: 'UF'}, {value: 'USD', text: 'USD'}, {value: 'EUR', text: 'EUR'}];
  public mesWebOptions: {value: number, text: string}[] = [
    {value: 1, text: 'Enero'}, {value: 2, text: 'Febrero'}, {value: 3, text: 'Marzo'},
    {value: 4, text: 'Abril'}, {value: 5, text: 'Mayo'}, {value: 6, text: 'Junio'},
    {value: 7, text: 'Julio'}, {value: 8, text: 'Agosto'}, {value: 9, text: 'Septiembre'},
    {value: 10, text: 'Octubre'}, {value: 11, text: 'Noviembre'}, {value: 12, text: 'Diciembre'},
  ];

  public listaRuts: any[] = [];
  public rutSelected: any[] = [];
  public periodoSelected: any[] = [];
  public f22: any = {};
  public f29: any = {};
  public showTablaF22F29: boolean = false;
  public currentPagePeriodos = 0;
  public currentPageRuts = 0;
  public rutsConsultados: boolean = false; // se indica si se fue a consultar la obtencion de la lista de ruts por grupos
  public showFormBalances: boolean = false;
  public errores: any[] = [];
  public erroresCampos: any[] = [];
  public erroresRutsDerivados: any[] = [];
  public idTransaccion: string = '';
  
  private access: string[] = [
    ESystemProfileList.WEB,
    ESystemAccess.BALANCE_MANUAL,
    ESystemAccess.BALANCE_MANUAL_BUSCAR,
    ESystemAccess.BALANCE_MANUAL_ADMIN
  ];
  
  public webSection = 'reporteFinanciero-balanceManual';
  public cpListaRutsForm!: UntypedFormGroup; // formulario con campos personalizados
  public garanteForm!: UntypedFormGroup; // formulario de campos garantes
  public contratoCampos: any[] = [];
  public camposPersonalizados: any[] = [];
  public arrayGarantes: any[] = [];
  public camposPersonalizadosValue: any[] = [];
  public razonSocialPrincipal: string = '';
  public today = '';
  public hasUserAccess = false;
  public currentItem = env.initItemPerPage;
  public currentPageBalance = 0;

  public showOpcionCarga: boolean = false;
  public cargaFormulario: boolean = true;
  public formularioArchivo!: UntypedFormGroup;
  public advertencias: any[] = [];
  public tempFile!: any;
  public base64Xlsx: string = '';
  
  public disabledButtonPrincipal: boolean = false;
  public listaArchivos: any = [];
  public razonSocialSeleccionado: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public alertService: AlertService,
    private utilsService: UtilsService,
    private balanceManualService: BalanceManualService,
    private spinner: NgxSpinnerService,
    private formularioF29Service: FormularioF29Service,
    private formularioF22Service: FormularioF22Service,
    private camposPersonalizadosService: CamposPersonalizadosService,
    private reporteService: ReporteService,
    private _sessionService: SessionService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (this._sessionService.getUserProfileList().includes(this.access[0]) && (this._sessionService.getUserAccess().includes(this.access[1]) || this._sessionService.getUserAccess().includes(this.access[2]) || this._sessionService.getUserAccess().includes(this.access[3]))) {
      this.hasUserAccess = true;
      this.setToday();
      this.obtenerGruposRut(this.rut);
    }
  }

  validaPermisoCrear(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[1]);
  }

  validaPermisoBuscar(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[2]);
  }

  validaPermisoAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  tieneControles(): boolean {
    return (this.cpListaRutsForm && this.cpListaRutsForm?.controls && Object.keys(this.cpListaRutsForm?.controls).length > 0) ||
    (this.garanteForm && this.garanteForm?.controls && Object.keys(this.garanteForm?.controls).length > 0);
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  bloquearForm(): void {
    if(this.cpListaRutsForm && Object.keys(this.cpListaRutsForm).length > 0 && Object.keys(this.cpListaRutsForm?.controls).length > 0){
      if(this.cpListaRutsForm.disabled){
        this.cpListaRutsForm.enable();
      } else {
        this.cpListaRutsForm.disable();
      }
    }
    if(this.garanteForm && Object.keys(this.garanteForm).length > 0 && Object.keys(this.garanteForm?.controls).length > 0){
      if(this.garanteForm.disabled){
        this.garanteForm.enable();
      } else {
        this.garanteForm.disable();
      }
    }
  }

  asignarGrupo(): void {
    if (!this.cpListaRutsForm.valid) {
      this.utilsService.validateAllFormFields(this.cpListaRutsForm);
      return;
    }

    if (!this.garanteForm.valid) {
      this.utilsService.validateAllFormFields(this.garanteForm);
      return;
    }
    
    const atributosGarantes: any[] = [];
    for(const garante of this.arrayGarantes) {
      if(!this.camposPersonalizados.find(e => e.nameAttribute === garante.nameAttribute)) {
        // estos atributos no deben tener el campo value en el objeto, se debe quitar sin afectar al arrayGarantes
        const garanteTemp = JSON.parse(JSON.stringify(garante));
        delete garanteTemp.value;
        atributosGarantes.push(garanteTemp);
      }
    }

    if(atributosGarantes.length > 0) {
      this.spinner.show();
      this.camposPersonalizadosService.crearContrato(atributosGarantes).subscribe(resp => {
        console.log(`Se han actualizado correctamente los campos`);
        this.spinner.hide();
        this.actualizarValores();
      }, (error) => {
        this.alertService.error(error?.error?.message || 'Ocurrio un error al actualizar los campos personalizados.');
        this.spinner.hide();
      });
    } else {
      this.actualizarValores();
    }
  }

  actualizarValores(): void {
    this.spinner.show();
    this.listaRuts = [];
    this.rutsConsultados = false;
    const form: any[] = [];
    const rutFormatted = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    const formTemp = this.cpListaRutsForm.getRawValue();
    const formTempGarantes = this.garanteForm.getRawValue();

    Object.keys(formTemp).forEach((control: any) => {
      const item = this.camposPersonalizados.filter(e => e.nameAttribute === control)[0];
    
      if (item) {
        form.push({
          nameAttribute: item.nameAttribute,
          value: formTemp[control]
        })
      }
    });

    Object.keys(formTempGarantes).forEach((control: any) => {
      const indexForm = form.findIndex(e => e.nameAttribute === control); 
      const item = this.arrayGarantes.filter(e => e.nameAttribute === control)[0];

      if (indexForm > -1) {
        form[indexForm].value = formTempGarantes[control];
      } else {
        form.push({
          nameAttribute: item.nameAttribute,
          value: formTempGarantes[control]
        })
      }
    });

    const garantesCampos = this.camposPersonalizados.filter(e => this.esGaranteValido(e?.nameAttribute));
    if(garantesCampos && garantesCampos.length > 0) {
      for(const garante of garantesCampos) {
        const indexForm = form.findIndex(e => e.nameAttribute === garante.nameAttribute);
        if(indexForm === -1) {
          form.push({
            nameAttribute: garante.nameAttribute,
            value: ''
          });
        }
      }
    }


    this.camposPersonalizadosService.crearActualizarValores(rutFormatted, form).subscribe(
    (data: any) => {
      const atributos: any[] = data?.CamposPersonalizadosReporte?.Reporte?.CamposPersonalizados?.Attributos || [];
      const filteredArray = atributos.filter(item => 
        item.webSections && item.webSections.includes(this.webSection)
      );
      this.camposPersonalizados = filteredArray || [];
      this.showRuts = false;
      this.disabledButtonPrincipal = false;
      this.cpListaRutsForm = this.formBuilder.group({});
      this.arrayGarantes = [];
      this.initFormCamposPersonalizados();

      const nombre = this.camposPersonalizados.find(e => e.nameAttribute === 'Razon Social');

      if(nombre) this.razonSocialPrincipal = nombre?.value || '';
      if(!this.razonSocialPrincipal) {
        this.buscarReporteBasico();
      } else {
        this.showRuts = true;
        this.spinner.hide();
      }
    },
    ({ error }) => {
      this.alertService.error(error.message || 'Ha ocurrido un error al asignar valor de grupo al rut indicado.');
      this.spinner.hide();
    });
  }

  validaCambioGrupo(): boolean { //valida si hay un cambio de grupo para mostrar distintos botones
    const grupoInicial: any = this.camposPersonalizados.find(e => e?.nameAttribute === "Grupo");
    if(grupoInicial && (grupoInicial?.value || grupoInicial?.value == '0')){
      if((this.cpListaRutsForm.get('Grupo')?.value || this.cpListaRutsForm.get('Grupo')?.value == '0') && 
        this.cpListaRutsForm.get('Grupo')?.value === grupoInicial?.value
      ) {
        return false;
      }
    }
    return true;
  }

  validaCamposUndefined(): boolean {
    const grupoInicial: any = this.camposPersonalizados.find(e => e?.nameAttribute === "Grupo");
    if(grupoInicial && !grupoInicial?.value && this.cpListaRutsForm.get('Grupo')?.value === '') {
      return true;
    }

    return false;
  }

  validaFormGaranteWithData(campo: string): boolean {
    if(this.garanteForm.get(campo)?.value) {
      return true;
    }
    return false;
  }

  validaGarantesInCampos(): boolean {
    const garante: any = this.camposPersonalizados.find(e => this.esGaranteValido(e?.nameAttribute));
    if(garante) {
      return true;
    }

    return false;
  }

  esGaranteValido(cadena: any): boolean {
    if(!cadena) return false;
    if(typeof(cadena)!=='string') return false;

    const patron = /^Garante(\s\d+)?$/;
    return patron.test(cadena);
  }

  initFormCamposPersonalizados(): void { 
    this.cpListaRutsForm = this.formBuilder.group({});
    const garantes: any[] = [];

    this.camposPersonalizados.forEach((element: any) => { 
      if(element?.enabled === true && element?.nameAttribute !== 'Razon Social'){
        if(this.esGaranteValido(element?.nameAttribute)) {
          garantes.push(element);
        } else if(element?.formatAttribute === '0' && element?.typeAttibute === 'Number' && this.objectKeys(element?.allowedValues).length === 0){
          this.cpListaRutsForm.addControl(element.nameAttribute, new UntypedFormControl(element?.value || element?.value == 0 ? (element?.value) : 
          '', [Validators.required, Validators.pattern("^[0-9]*$")]));
        } else if(element?.allowedValues && this.objectKeys(element?.allowedValues).length > 0){
          const key = Object.keys(element.allowedValues)[Object.values(element.allowedValues).indexOf(element.value)];
          if (key)
          this.cpListaRutsForm.addControl(element.nameAttribute, new UntypedFormControl(key || key == '0' ? (key) : 
          '', [Validators.required, Validators.pattern("^[0-9]*$")]));
          else
          this.cpListaRutsForm.addControl(element.nameAttribute, new UntypedFormControl(element?.value || element?.value == 0 ? (element?.value) : 
          '', [Validators.required, Validators.pattern("^[0-9]*$")]));
        } else {
          this.cpListaRutsForm.addControl(element.nameAttribute, new UntypedFormControl(element?.value || element?.value == 0 ? (element?.value) : 
          '', Validators.required));
        }
      }
    });
    this.cpListaRutsForm.disable();

    if(garantes && garantes.length > 0) {
      this.generarListaCamposGarantes(garantes);
    }
  }

  generarListaCamposGarantes(garantes: any[]): void {
    this.arrayGarantes = [];
    for(const garante of garantes) {
      if(garante?.typeAttibute === 'String' && (!garante?.allowedValues || !(this.objectKeys(garante?.allowedValues).length > 0)) && (garante?.value)) {
        this.arrayGarantes.push(garante);
      }
    }
    this.orderGarantes();
  }

  orderGarantes(): void {
    if(this.arrayGarantes && this.arrayGarantes.length > 0) {
      this.arrayGarantes.sort((a, b) => {
        if(a.nameAttribute === 'Garante') return -1;
        if(b.nameAttribute === 'Garante') return 1;
        const aNum = parseInt(a.nameAttribute.replace('Garante ', ''));
        const bNum = parseInt(b.nameAttribute.replace('Garante ', ''));
        return aNum - bNum;
      });
      this.updateGaranteArray();
    }
  }

  updateGaranteArray(): void {
    this.arrayGarantes.forEach((element: any) => {
      if(this.garanteForm.get(element.nameAttribute)?.value) {
        element.value = this.garanteForm.get(element.nameAttribute)?.value;
      }
    });
    this.initFormGarante();
  }

  initFormGarante(): void {
    this.garanteForm = this.formBuilder.group({});
    this.arrayGarantes.forEach((element: any) => {
      this.garanteForm.addControl(element.nameAttribute, new UntypedFormControl(element?.value, [Validators.required, gtpRutValidator()]));
    });
    if(this.cpListaRutsForm.disabled) {
      this.garanteForm.disable();
    }
  }

  addGarante(): void {
    this.updateGaranteArray();
    const garanteFaltante = this.camposPersonalizados.find(e => this.esGaranteValido(e?.nameAttribute) && !this.arrayGarantes.find(f => f.nameAttribute === e.nameAttribute));
    if(garanteFaltante) {
      this.arrayGarantes.push(garanteFaltante);
    } else {
      let num = 1;
      const nombresExistentes = this.arrayGarantes.map(e => e.nameAttribute);
      while (nombresExistentes.includes(num === 1 ? 'Garante' : `Garante ${num}`)) { 
        num++; 
      }

      const garanteContrato = this.camposPersonalizados.find(e => e?.nameAttribute === 'Garante');
      if(garanteContrato) {
        const newGarante = JSON.parse(JSON.stringify(garanteContrato));
        newGarante.nameAttribute = num == 1 ? `Garante` : `Garante ${num}`;
        newGarante.value = '';
        newGarante.defaultValue = '';

        this.arrayGarantes.push(newGarante);
      } else {
        console.error("No se ha encontrado el contrato de campos personalizados de Garante");
      }
    }
    this.orderGarantes();
  }

  removeGarante(garante: any): void {
    this.updateGaranteArray();
    const index = this.arrayGarantes.findIndex(e => e.nameAttribute === garante.nameAttribute);
    if(index > -1) {
      this.arrayGarantes.splice(index, 1);
    }
    this.orderGarantes();
  }

  llamadaParalelo(servicio: string): void {
    const rutsAConsultar = this.listaRuts.filter(e => !e?.nombre); 
    let count = 0;
    let callCount = 0;
    const cantLlamadas = 10; //10
    const tiempoDelay = 500;

    if((servicio === 'buscarCampos' || servicio === 'buscarBasico' || servicio === 'crearBasico') && rutsAConsultar.length > 0) {
      this.spinner.show();
      from(rutsAConsultar).pipe(
        (servicio === 'buscarCampos') ?
        (mergeMap( //campos personalizados
          (service: any) => 
            this.camposPersonalizadosService.listarValores(
              service.rut,
              this.webSection
            )
            .pipe(
              catchError(error => {
                console.error(`Error en la consulta del servicio: ${servicio}`);
                return of(null); // Retorna un observable que emite un valor nulo
              }),
              map(response => {
                if (response !== null) {
                  count++;
                }
                return {
                  service: service,
                  response: response
                };
              }),
              delayWhen(() => {
                callCount++;
                if (callCount % cantLlamadas === 0) {
                  return timer(tiempoDelay);
                }
                return timer(0);
              })
            ),
            cantLlamadas // llamadas simultaneas
          )) :
        (servicio === 'buscarBasico') ?
        (mergeMap( // buscar basico
          (service: any) => 
            this.reporteService.basicReportLastUpdated(
              service.rut,
              '10'
            )
            .pipe(
              catchError(error => {
                console.error(`Error en la consulta del servicio: ${servicio}`);
                return of(null); // Retorna un observable que emite un valor nulo
              }),
              map(response => {
                if (response !== null) {
                  count++;
                }
                return {
                  service: service,
                  response: response
                };
              }),
              delayWhen(() => {
                callCount++;
                if (callCount % cantLlamadas === 0) {
                  return timer(tiempoDelay);
                }
                return timer(0);
              })
            ),
            cantLlamadas // llamadas simultaneas
          )):
        (mergeMap( // crear basico
          (service: any) => 
            this.reporteService.createReport(
              service.rut,
              '10'
            )
            .pipe(
              catchError(error => {
                console.error(`Error en la consulta del servicio: ${service}, ${service.entidad}`);
                return of(null); // Retorna un observable que emite un valor nulo
              }),
              map(response => {
                if (response !== null) {
                  count++;
                }
                return {
                  service: service,
                  response: response
                };
              }),
              delayWhen(() => {
                callCount++;
                if (callCount % cantLlamadas === 0) {
                  return timer(tiempoDelay);
                }
                return timer(0);
              })
            ),
            cantLlamadas // llamadas simultaneas
          )), 
        filter(response => response !== null),
        toArray()
      ).subscribe(responses => {
        if (count === 0) { // Si no hubo respuestas válidas
          this.alertService.error('Ha ocurrido un error al consultar la información de todos los ruts del grupo');
          this.spinner.hide();
          if(servicio ===  'buscarCampos') this.llamadaParalelo('buscarBasico');
          else if(servicio === 'buscarBasico') this.llamadaParalelo('crearBasico');
          else if(servicio === 'crearBasico') this.llamadaParalelo('');
            
        } else if (count < rutsAConsultar.length) { // Si hubo respuestas válidas pero no para todos los servicios
          this.alertService.error('Ha ocurrido un error al consultar la información de algunos de los ruts del grupo');
        }
        if(count > 0){
          if(servicio ===  'buscarCampos') {
            this.listaRuts.forEach(element => {
                const response: any = responses.find((e: any) => e?.service?.rut === element?.rut);
                if(response) {
                  const camposPersonalizados: any = response?.response?.CamposPersonalizadosReporte?.Reporte?.CamposPersonalizados?.Attributos || [];
                  const nombre = camposPersonalizados.find((e: any) => e.nameAttribute === 'Razon Social');
                  if(nombre){
                    element.nombre = nombre?.value || '';
                  }
                }
            });
          } else if(servicio === 'buscarBasico') {
            this.listaRuts.forEach(element => {
              const response: any = responses.find((e: any) => e?.service?.rut === element?.rut);
              if(response) {
                element.nombre = response?.response?.InformacionBasica?.NombreORazonSocial || '';
                if(response?.response?.InformacionBasica?.NombreORazonSocial) element.creado = true;
              }
            });
          } else if(servicio === 'crearBasico') {
            this.listaRuts.forEach(element => {
              const response: any = responses.find((e: any) => e?.service?.rut === element?.rut);
              if(response) {
                element.nombre = response?.response?.InformacionBasica?.NombreORazonSocial || '';
                if(response?.response?.InformacionBasica?.NombreORazonSocial) element.creado = true;
              }
            });
          }

          const rutsSinNombre = this.listaRuts.filter(e => !e?.nombre);
          this.spinner.hide();
          if(rutsSinNombre.length > 0) {
            if(servicio ===  'buscarCampos') this.llamadaParalelo('buscarBasico');
            else if(servicio === 'buscarBasico') this.llamadaParalelo('crearBasico');
            else if(servicio === 'crearBasico') this.llamadaParalelo('');
          } else {
            this.llamadaParalelo('');
          }
        }
      }, error => {
        if (count > 0) {
          this.alertService.error('Ha ocurrido un error al consultar la información de algunos de los ruts del grupo');
        } else {
          this.alertService.error('Ha ocurrido un error al consultar la información de todos los ruts del grupo');
          this.spinner.hide();
        }
      });
    } else {
      const rutsAGuardar = this.listaRuts.filter(e => e?.creado && e?.creado === true); // los que tienen el campo creado y en true
      if(rutsAGuardar && rutsAGuardar.length > 0) {
        // llamada en paralelo con una sola opcion
        this.spinner.show();
        from(rutsAGuardar).pipe(
          mergeMap( //campos personalizados
            (service: any) => 
              this.camposPersonalizadosService.crearActualizarValores(
                service.rut,
                [{"nameAttribute": "Razon Social", "value": service.nombre}]
              )
              .pipe(
                catchError(error => {
                  console.error(`Error en la consulta del servicio: ${servicio}`);
                  return of(null); // Retorna un observable que emite un valor nulo
                }),
                map(response => {
                  if (response !== null) {
                    count++;
                  }
                  return {
                    service: service,
                    response: response
                  };
                }),
                delayWhen(() => {
                  callCount++;
                  if (callCount % cantLlamadas === 0) {
                    return timer(tiempoDelay);
                  }
                  return timer(0);
                })
              ),
            cantLlamadas // llamadas simultaneas
          ), 
          filter(response => response !== null),
          toArray()
        ).subscribe(responses => {
          if (count === 0) { // Si no hubo respuestas válidas
            this.alertService.error('Ha ocurrido un error al guardar la información de todos los ruts del grupo');
          } else if (count < rutsAConsultar.length) { // Si hubo respuestas válidas pero no para todos los servicios
            this.alertService.error('Ha ocurrido un error al guardar la información de algunos de los ruts del grupo');
          }
          this.spinner.hide();
        }, error => {
          if (count > 0) {
            this.alertService.error('Ha ocurrido un error al guardar la información de algunos de los ruts del grupo');
            this.spinner.hide();
          } else {
            this.alertService.error('Ha ocurrido un error al guardar la información de todos los ruts del grupo');
            this.spinner.hide();
          }
        });
      } else {
        this.disabledButtonPrincipal = true;
        this.spinner.hide();
      }
    }
  }

  guardaRazonSocialPrincipal(): void {
    if(this.razonSocialPrincipal) {
      const rutPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
      this.spinner.show();
      const campos = [{"nameAttribute": "Razon Social", "value": this.razonSocialPrincipal}];
      this.camposPersonalizadosService.crearActualizarValores(rutPrincipal, campos).subscribe(
        (data: any) => {
          this.spinner.hide();
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al asignar valor de grupo al rut indicado.');
          this.spinner.hide();
      });
    }
  }

  buscarReporteBasico(): void {
    this.spinner.show();
    const rutPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    this.reporteService.basicReportLastUpdated(rutPrincipal, '10').subscribe(
    (data: any) => {
      if(data && Object.keys(data).length <= 0){
        this.reporteService.createReport(rutPrincipal, '10').subscribe(
        (dataCrear: any) => {
          if (Object.keys(dataCrear).length > 0){
            this.razonSocialPrincipal = dataCrear?.InformacionBasica.NombreORazonSocial || '';
            this.guardaRazonSocialPrincipal();
            this.showRuts = true;
            this.spinner.hide();
          } else {
            this.showRuts = true;
            this.alertService.error('Error Inesperado al recuperar la información del reporte basico');
            this.spinner.hide();
          }
        },
        ({ error }) => {
          this.showRuts = true;
          this.alertService.error(error?.error?.message || 'Error Inesperado en servicio de generación de reporte basico');
          this.spinner.hide();
        });
      } else {
        this.razonSocialPrincipal = data?.InformacionBasica.NombreORazonSocial || '';
        this.guardaRazonSocialPrincipal();
        this.showRuts = true;
        this.spinner.hide();
      }
    },
    ({ error }) => {
      this.alertService.error(error?.error?.message || 'Error Inesperado en servicio de reporte basico');
      this.reporteService.createReport(rutPrincipal, '10').subscribe(
      (dataCrear: any) => {
        if (dataCrear && Object.keys(dataCrear).length > 0){
          this.razonSocialPrincipal = dataCrear?.InformacionBasica.NombreORazonSocial || '';
          this.guardaRazonSocialPrincipal();
          this.showRuts = true;
          this.spinner.hide();
        } else {
          this.showRuts = true;
          this.alertService.error('Error Inesperado al recuperar la información del reporte basico');
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.showRuts = true;
        this.alertService.error(error?.error?.message || 'Error Inesperado en servicio de generación de reporte basico');
        this.spinner.hide();
      });

    })
  }

  setErrorCampos(reporte: string, error: string): void {
    if(reporte === 'listarRuts') {
      this.erroresRutsDerivados.push({
        id: reporte,
        msg: error
      })
    } else {
      this.erroresCampos.push({
        id: reporte,
        msg: error
      })
    }
  }
  
  // Mapeo de respuestas correctas en consultas asincronas

  setResponseCampos(service: string, response: any, campos: any = null): void {
    if (service === 'obtenerContrato'){
      this.contratoCampos = response?.CamposPersonalizadosReporte?.Reporte?.CamposPersonalizados?.Attributos || [];
    }
    else if (service === 'listarValores'){
      this.camposPersonalizadosValue = response?.CamposPersonalizadosReporte?.Reporte?.CamposPersonalizados?.Attributos || [];
    } else if (service === 'listarRuts'){
      if(response?.ruts && response?.ruts.length > 0 && campos && campos.length > 0){
        const relacion = campos[0].nameAttribute || '';
        if(relacion === 'Grupo' || relacion === 'Garante'){
          const ruts = response?.ruts.map(this.formatRut);
          ruts.forEach((element: string) => { // se le agregan nombres
            const rutElement = this.listaRuts.find(e => e.rut === element);
            if(rutElement) {
              if(rutElement?.relacion != 'Cliente') {
                if(!rutElement?.relacion.includes(relacion)) {
                  this.listaRuts.find(e => e.rut === element).relacion += ', ' + relacion;
                }
              }
            } else {
              this.listaRuts.push(
                {
                  rut: element,
                  nombre: RUT.applyBackendFormat(this.rut) === element? (this.razonSocialPrincipal) : (""),
                  relacion: RUT.applyBackendFormat(this.rut) === element? 'Cliente' : relacion
                }
              );
            }

          });
        }
        
      }
    }
  }

  getServicesCamposPersonalizados(service: string, rutFormatted: string, campos: any = null): any {
    const objeto: any = {
      'obtenerContrato': () => {
        return this.camposPersonalizadosService.obtenerContrato()
          .pipe(
            map(resp => {
              this.setResponseCampos(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setErrorCampos(service, error?.error?.message || 'Error Inesperado al obtener el contrato de campos personalizados'), of(null))));
      },
      'listarValores': () => {
        return this.camposPersonalizadosService.listarValores(rutFormatted, this.webSection)
        .pipe(
          map(resp => {
            this.setResponseCampos(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setErrorCampos(service, error?.error?.message || 'Error Inesperado al obtener los valores de campos personalizados'), of(null))));
      },
      'listarRuts': () => {
        return this.camposPersonalizadosService.listarRuts(campos)
        .pipe(
          map(resp => {
            this.setResponseCampos(service, resp, campos);
          })
        )
        .pipe(
          catchError((error) => (this.setErrorCampos(service, error?.error?.message || 'Ha ocurrido un error al consultar lista de ruts'), of(null))));
      }
    };
    return objeto[service]();
  }

  async obtenerGruposRut(rut: string): Promise<void> {
    this.cpListaRutsForm = this.formBuilder.group({});
    this.garanteForm = this.formBuilder.group({})
    this.arrayGarantes = [];
    this.camposPersonalizados = [];
    this.camposPersonalizadosValue = [];
    this.contratoCampos = [];
    this.erroresCampos = [];
    this.razonSocialPrincipal = '';
    const rutFormatted = (rut.includes("-") ? rut : RUT.applyBackendFormat(rut));
    if(rutFormatted){
      const apiServices: any = [];
      apiServices.push(this.getServicesCamposPersonalizados('obtenerContrato', rutFormatted))
      apiServices.push(this.getServicesCamposPersonalizados('listarValores', rutFormatted))
  
      this.spinner.show();
      forkJoin(apiServices).subscribe((resp) => {
        this.spinner.hide();
        if (this.erroresCampos.length > 0){ // caso error
          this.erroresCampos.forEach((element: any) => {
            this.alertService.error(element.msg);
          });
          this.spinner.hide();
        } else { // caso corrrecto
          this.combinacionCampos();
        }
      },
        (error) => {
          this.alertService.error(error?.message || 'Ha ocurrido un error al obtener valores de grupos asignados.');
          this.spinner.hide();
        }
      )
    }

  }

  combinacionCampos(): void {
    const atributosContrato: any[] = this.contratoCampos || [];
    const filteredArrayContrato = atributosContrato.filter(item => item?.webSections && item?.webSections.includes(this.webSection));
    const combinedArray: any[] = []; //...this.camposPersonalizadosValue

    // validar con contrato como base filteredArrayContrato y agregar el value como atributo en caso de tener un valores en los camposPersonalizadosValue
    filteredArrayContrato.forEach(contrato => {
      const valor = this.camposPersonalizadosValue.find(e => e?.nameAttribute && contrato?.nameAttribute && e?.nameAttribute === contrato?.nameAttribute);
      if(valor) {
        contrato.value = valor?.value || '';
      }
      combinedArray.push(contrato);
    });

    this.camposPersonalizados = combinedArray || [];
    this.initFormCamposPersonalizados();

    const nombre = this.camposPersonalizados.find(e => e.nameAttribute === 'Razon Social');
    if(nombre) this.razonSocialPrincipal = nombre?.value || '';
    if(!this.razonSocialPrincipal) {
      this.buscarReporteBasico();
    } else {
      this.showRuts = true;
      this.spinner.hide();
    }
  }

  validaRutPrincipal(elementoRut: string): boolean {
    const rutPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    if(rutPrincipal === elementoRut){
      return true;
    }
    return false;
  }

  formatRut(rut: string) {
    rut = rut.replace(/\./g, ''); // Quitar puntos
    
    if (!rut.includes('-')) {
        // Agregar guion antes del último dígito si no tiene guion.
        rut = rut.slice(0, -1) + '-' + rut.slice(-1);
    }
    
    return rut;
  }
  
  continuarPrincipal(): void {
    this.spinner.show();
    this.listaRuts = [];
    this.rutSelected = [];
    this.generarListaPeriodos();

    const rutFormatted: string = this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut);
    this.rutSelected.push(rutFormatted);
    this.listaRuts.push({rut: rutFormatted, nombre: this.razonSocialPrincipal || "", relacion: 'Cliente'});
    this.rutsConsultados = true;
    this.spinner.hide();

  }

  obtenerListaRutsAsociados(): void {
    this.listaRuts = [];
    this.rutSelected = [];
    this.erroresRutsDerivados = [];
  
    const campoGrupo: any = this.camposPersonalizados.find(e => e?.nameAttribute === 'Grupo');
    if(!campoGrupo || !(this.cpListaRutsForm.get('Grupo')?.value || this.cpListaRutsForm.get('Grupo')?.value == 0)){
      this.alertService.error('Ha ocurrido un error al obtener el valor del grupo.');
      this.spinner.hide();
      return;
    }
  
    this.generarListaPeriodos();
    const valorGrupo: any = campoGrupo?.typeAttibute === 'String' ? this.cpListaRutsForm.get('Grupo')?.value.toString() : Number(this.cpListaRutsForm.get('Grupo')?.value);
    const camposGrupo: any[] = [
      {
        "nameAttribute": campoGrupo?.nameAttribute || '',
        "value": valorGrupo
      }
    ];
  
    const apiServices: any = [];
    apiServices.push(this.getServicesCamposPersonalizados('listarRuts', '', camposGrupo))
  
    
    let garantes: any[] = [];
    if(this.arrayGarantes && this.arrayGarantes.length > 0) {
      garantes = this.arrayGarantes.map(e => this.formatRut(e.value));
      garantes.forEach((element: string) => { // se le agregan nombres
        const rutElement = this.listaRuts.find(e => e.rut === element);
        if(rutElement) {
          if(rutElement?.relacion != 'Cliente') {
            if(!rutElement?.relacion.includes('Garante')) {
              this.listaRuts.find(e => e.rut === element).relacion += ', Garante';
            }
          }
        } else {
          this.listaRuts.push(
            {
              rut: element,
              nombre: RUT.applyBackendFormat(this.rut) === element? (this.razonSocialPrincipal) : (""),
              relacion: RUT.applyBackendFormat(this.rut) === element? 'Cliente' : 'Garante'
            }
          );
        }

      });
    }
  
    // llamada en paralelo de apiServices
    if(apiServices.length > 0){
      this.spinner.show();
      forkJoin(apiServices).subscribe((resp) => {
        this.spinner.hide();
        if (this.erroresRutsDerivados.length > 0){ // caso error
          this.erroresRutsDerivados.forEach((element: any) => {
            this.alertService.error(element.msg);
          });
        }
  
        if(this.listaRuts.length > 0) {
          const rutFormatted: string = this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut);
          const element = this.listaRuts.find(e => e.rut == rutFormatted);
          if (element) {
            this.listaRuts = this.listaRuts.filter((e: any) => e.rut !== rutFormatted);
            this.listaRuts.unshift(element); // se mueve rut principal al principio
            this.rutSelected.push(rutFormatted);
            this.llamadaParalelo('buscarCampos'); // obtener y actualizar nombres
          } else {
            //this.listaRuts = [];
            this.listaRuts.unshift({rut: rutFormatted, nombre: this.razonSocialPrincipal || "", relacion: 'Cliente'});
            this.rutSelected.push(rutFormatted);
            this.llamadaParalelo('buscarCampos'); // obtener y actualizar nombres
            this.alertService.error('No se encontró el rut principal en la lista de ruts recibida.');
          }
        } else {
          this.alertService.error('No se ha encontrado la lista de ruts o la lista se encuentra vacia.');
          this.spinner.hide();
        }
        this.rutsConsultados = true;
      },
        (error) => {
          this.alertService.error(error?.message || 'Ha ocurrido un error al obtener valores de grupos asignados.');
          this.spinner.hide();
        }
      )
    }
  
  }

  seleccionarRut(event: any, rut: string): void {
    if(event?.target?.checked){
      if(this.rutSelected.length < 10){
        this.rutSelected.push(rut.includes("-") ? rut : RUT.applyBackendFormat(rut));
      } else {
        event.target.checked = false;
      }
    } else {
      const index = this.rutSelected.findIndex(e => e == (rut.includes("-") ? rut : RUT.applyBackendFormat(rut)));
      if(index >= 0) {
        this.rutSelected.splice(index, 1);
      }
    }
  }

  agregarPeriodo(event: any, periodo: number): void {
    if(event?.target?.checked){
      if(this.periodoSelected.length < 3){
        this.periodoSelected.push(periodo.toString());
      } else {
        event.target.checked = false;
      }
    } else {
      const index = this.periodoSelected.findIndex(e => e == periodo.toString());
      if(index >= 0) {
        this.periodoSelected.splice(index, 1);
      }
    }
  }

  seleccionarRutPeriodo(): void {
    if(this.rutSelected.length <= 0){
      this.alertService.error("Se debe seleccionar un rut");
      return
    }
    if(this.periodoSelected.length <= 0){
      this.alertService.error("Se debe seleccionar por lo menos un periodo");
      return;
    }

    this.showRuts = false;
    this.showList = true;
    this.obtenerLista();
  }

  generarListaPeriodos(): void {
    this.periodoSelected = [];
    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    for(let i = 0; i <= 20; i ++) {
      this.periodos.push((anioActual - i));
    }
  }

  volverListaRuts(): void {
    this.currentPagePeriodos = 0;
    this.currentPageRuts = 0;
    this.definicionBalance = {};
    this.camposBalance = {};
    this.errores = [];
    this.campos = {};
    this.secciones = [];
    this.showRuts = true;
    this.showList = false;
    this.showFormBalances = false;
    this.periodo = 0;
    this.listaBalances = [];
    this.currentItem = env.initItemPerPage;
    this.currentPageBalance = 0;
    this.respuestaPreBalance = [];
    this.respuestaBalance = [];
    this.dataReporte = [];
    this.showListaBalances = false;
    this.listaSeleccionada = [];
    this.f29 = {};
    this.f22 = {};
    this.showTablaF22F29 = false;
    this.rutsConsultados = false;
    this.obtenerGruposRut(this.rut);
    this.showReporte = false;
    this.disabledButtonPrincipal = false;
  }

  validarPeriodo(periodo: number): boolean {
    if(this.periodoSelected.includes(periodo.toString()))
      return true;
    return false;
  }

  validarRutChecked(rut: string): boolean {
    if(rut && this.rutSelected.includes(rut.includes("-") ? rut : RUT.applyBackendFormat(rut)))
      return true;
    return false;
  }

  mapeoListaBalances(): void {
    console.log("PRE MAPEO:", this.respuestaBalance)
    if(this.respuestaBalance && this.respuestaBalance.length > 0){
      this.respuestaBalance.forEach((element: any) => {
        // Obtiene periodo del balance
        const periodo = Number(element?.BalanceManualReporte?.Reporte?.periodo) || null;
        const isConsolidado = element?.BalanceManualReporte?.Reporte?.subTipo == "Consolidado";
        if(periodo){
          const index = this.listaBalances.findIndex(e => periodo == e.periodo 
            && `${element?.BalanceManualReporte?.DatosBasicosSolicitud?.Rut}-${element?.BalanceManualReporte?.DatosBasicosSolicitud?.DV}` === e?.rut);
            // Si el periodo ya existe en la lista de balances para ese rut
            if(index >= 0) {
              // ---TODO: Condicional -> si es consolidado lo agrego a listaBalancesConsolidados---
              if(isConsolidado) {
                this.listaBalances[index].consolidado = element?.BalanceManualReporte || {}; // piso el balance que estaba con este (no se por que)
                this.listaBalances[index].listaConsolidados.push(element?.BalanceManualReporte || {}); // lo agrego al array de balances
              } else {
                this.listaBalances[index].balance = element?.BalanceManualReporte || {}; // piso el balance que estaba con este (no se por que)
                this.listaBalances[index].listaBalances.push(element?.BalanceManualReporte || {}); // lo agrego al array de balances
              }
            } else {
              // Si el periodo no existe en la lista
              const rutElement: string = `${element?.BalanceManualReporte?.DatosBasicosSolicitud?.Rut}-${element?.BalanceManualReporte?.DatosBasicosSolicitud?.DV}` || '';
              let nombreRazonSocial = ''; 
              const elementRuts = this.listaRuts.find(e => e?.rut === rutElement);
              if(elementRuts){
                nombreRazonSocial = elementRuts?.nombre || '';
              }
              // creo el objeto
              const objeto: any = {
                periodo: periodo,
                rut: rutElement,
                nombreRazonSocial: nombreRazonSocial,
                balance: {},
                listaBalances: [],
                listaPreBalances: [],
                preBalance: {},
                consolidado: {},
                listaConsolidados: []
              }

              // ---TODO: Condicional -> si es consolidado lo agrego a listaBalancesConsolidados---
              if(isConsolidado) {
                objeto.consolidado = element?.BalanceManualReporte || {};
                objeto.listaConsolidados.push(element?.BalanceManualReporte || {});
              } else {
                // Le pusheo el balance a su lista de balances
                objeto.balance = element?.BalanceManualReporte || {};
                objeto.listaBalances.push(element?.BalanceManualReporte || {});
              }
              
              // lo agrego a la lista de balances
              this.listaBalances.push(objeto);
            }
        }
      });
    }
    if(this.respuestaPreBalance && this.respuestaPreBalance.length > 0){
      this.respuestaPreBalance.forEach((element: any) => {
        const periodo = Number(element?.BalanceManualReporte?.Reporte?.periodo) || null;
        if(periodo){
          const index = this.listaBalances.findIndex(e => periodo == e.periodo && `${element?.BalanceManualReporte?.DatosBasicosSolicitud?.Rut}-${element?.BalanceManualReporte?.DatosBasicosSolicitud?.DV}` === e?.rut);
          if(index >= 0) {

            this.listaBalances[index].listaPreBalances.push(element?.BalanceManualReporte || {});

            if(Object.keys(this.listaBalances[index]?.preBalance).length > 0){
              if (Number(element?.BalanceManualReporte?.Reporte?.periodo) > Number(this.listaBalances[index]?.preBalance?.Reporte?.periodo) ||
                  (Number(element?.BalanceManualReporte?.Reporte?.periodo) === Number(this.listaBalances[index]?.preBalance?.Reporte?.periodo) && 
                    Number(element?.BalanceManualReporte?.Reporte?.mesFin) > Number(this.listaBalances[index]?.preBalance?.Reporte?.mesFin))) {

                this.listaBalances[index].preBalance = element?.BalanceManualReporte || {};
              }
            } else {
              this.listaBalances[index].preBalance = element?.BalanceManualReporte || {};
            }
          } else {
            const rutElement: string = `${element?.BalanceManualReporte?.DatosBasicosSolicitud?.Rut}-${element?.BalanceManualReporte?.DatosBasicosSolicitud?.DV}` || '';
            let nombreRazonSocial = ''; 
            const elementRuts = this.listaRuts.find(e => e?.rut === rutElement);
            if(elementRuts){
              nombreRazonSocial = elementRuts?.nombre || '';
            }
            const objeto: any = {
              periodo: periodo,
              rut: rutElement,
              nombreRazonSocial: nombreRazonSocial,
              balance: {},
              listaBalances: [],
              listaPreBalances: [],
              preBalance: element?.BalanceManualReporte || {},
            }
            objeto.listaPreBalances.push(element?.BalanceManualReporte || {});

            this.listaBalances.push(objeto);
          }
        }
      });
    }

    this.agruparListadoBalances();
  }

  agruparListadoBalances(): void {
    // Objeto vacío para almacenar los elementos agrupados
    const elementosAgrupados: any = {};

    // Iterar sobre cada elemento del array original
    this.listaBalances.forEach(elemento => {
      const rut = elemento?.rut;

      // Comprobar si el periodo ya existe como clave en el objeto de elementos agrupados
      if (!elementosAgrupados.hasOwnProperty(rut)) {
        // Si no existe, crear una nueva clave con un array vacío
        elementosAgrupados[rut] = [];
      }

      // Agregar el elemento actual al array correspondiente al periodo
      elementosAgrupados[rut].push(elemento);
    });

    
    // Convertir el objeto de elementos agrupados en un array de pares clave-valor
    const resultado = Object.entries(elementosAgrupados);
    
    resultado.forEach((element: any) => {
      if(element[1]){
        element[1].sort((a:any, b:any) => b?.periodo - a?.periodo);
      }
    });
    
    this.listaBalances = resultado || [];

    const rutFormatted = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    this.listaBalances.sort((a, b) => {
      if (a[0] === rutFormatted) return -1;
      if (b[0] === rutFormatted) return 1;
      return 0;
    });

    console.log("POST MAPEO:" ,this.listaBalances)
  }

  validaAutomaticos(f22: any[]): any[] {
    return f22.filter(e => (e.Formulario22Reporte?.Reporte?.tipo !== 'manual' || (e.Formulario22Reporte?.Reporte && !e.Formulario22Reporte?.Reporte?.tipo)));
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any, rut: string): void {
    // console.log(response)
    if (service === 'listarPreBalance'){
      this.respuestaPreBalance.push(...(response || []));
    }
    else if (service === 'listarBalance'){
      this.respuestaBalance.push(...(response || []));
    }
    else if (service === 'f22'){
      this.f22[rut] = this.validaAutomaticos(response);
      this.f22[rut] = this.formularioF22Service.calculosVariacion(this.f22[rut]);
    }
    else if (service === 'f29'){
      this.f29[rut] = response;
    } else if (service === 'listaArchivos'){
      if(response?.archivos && response?.archivos.length > 0){
        for(const element of response?.archivos){
          if(element?.id_transaccion){
            this.listaArchivos.push(element?.id_transaccion);
          }
        }
      }
    }
  }

  getServices(service: string, rut: string): any {
    const objeto: any = {
      'listarBalance': () => {
        return this.balanceManualService.listarBalance(rut, this.periodoSelected)
          .pipe(
            map(resp => {
              this.setResponse(service, resp, rut);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de balances'), of(null))));
      },
      'listarPreBalance': () => {
        return this.balanceManualService.listarPreBalance(rut, this.periodoSelected).pipe(
          map(resp => {
            this.setResponse(service, resp, rut);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de pre balances'), of(null))));
      },
      'f29': () => {
        return this.formularioF29Service.buscarReportes(rut, null)
          .pipe(
            map(resp => {
              this.setResponse(service, resp, rut);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de f29'), of(null))));
      },
      'f22': () => {
        return this.formularioF22Service.listarRenta(rut).pipe(
          map(resp => {
            this.setResponse(service, resp, rut);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de f22'), of(null))));
      },
    };
    return objeto[service]();
  }

  getServiceArchivo(service: string, rut: string, periodo: string, tipo: string): any {
    const objeto: any = {
      'listaArchivos': () => {
        return this.balanceManualService.obtenerListaArchivos(rut, periodo, tipo) // cambiar servicio
          .pipe(
            map(resp => {
              this.setResponse(service, resp, rut);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Error Inesperado en servicio de archivos'), of(null))));
      }
    };
    return objeto[service]();
  }

  async obtenerLista(): Promise<void> {
    this.respuestaBalance = [];
    this.respuestaPreBalance = [];
    this.listaBalances = [];
    this.listaArchivos = [];
    this.currentItem = env.initItemPerPage;
    this.currentPageBalance = 0;
    this.showTablaF22F29 = false;
    this.f22 = {};
    this.f29 = {};
    this.errores = [];
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const rut of this.rutSelected) {
        for await (const value of this.groupNameConsulting) {
          apiServices.push({ name: value, service: () => this.getServices(value, rut) });
        }
      }
    }

    for await (const rut of this.rutSelected) {
      for await (const value of this.periodoSelected) { // periodos
        apiServices.push({ name: value, service: () => this.getServiceArchivo('listaArchivos', rut, value, 'balance') });
        apiServices.push({ name: value, service: () => this.getServiceArchivo('listaArchivos', rut, value, 'prebalance') });
      }
    }

    if (this.groupNameConsultingF29F22.length > 0) {
      for await (const rut of this.rutSelected) {
        for await (const value of this.groupNameConsultingF29F22) {
          apiServices.push({ name: value, service: () => this.getServices(value, rut) });
        }
      }
    }

    const apiServicesGroups: any[] = [];
    const groupSize = 10; // cantidad de consultas por grupo

    for (let i = 0; i < apiServices.length; i += groupSize) {
      apiServicesGroups.push(apiServices.slice(i, i + groupSize));
    }

    this.spinner.show();

    forkJoin(
      from(apiServicesGroups).pipe(
        concatMap(group => forkJoin(group.map((item: any) => item.service())).pipe(
          delay(500)
        ))
      ).pipe(toArray())
    ).subscribe((resp) => {
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
      }
    
      this.mapeoListaBalances();
      this.showTablaF22F29 = true;
      this.spinner.hide();
    },
    (error) => {
      this.alertService.error(error?.message || 'Ha ocurrido un error al intentar consultar los servicios necesarios');
      this.spinner.hide();
    });
  }

  obtenerIndicadorBalance(tipo: string, periodo: number | string, rut: string): boolean {
    const elemento = this.listaBalances.find(e => e?.[0] === rut);
    if(elemento && elemento[1] && elemento[1].length > 0){
      const objeto = elemento[1].find((e: any) => e.periodo === periodo);
      if(objeto){
        if(objeto?.[tipo] && Object.keys(objeto?.[tipo]).length > 0){
          return true;
        }
      }
    }
    return false;
  }

  obtenerMeses(tipo: string, periodo: number | string, rut: string): string {
    const elemento = this.listaBalances.find(e => e?.[0] === rut);
    if(elemento && elemento[1] && elemento[1].length > 0){
      const objeto = elemento[1].find((e: any) => e.periodo === periodo);
      if(objeto){
        if(objeto?.[tipo] && Object.keys(objeto?.[tipo]).length > 0){
          const nombresMeses = [
            "ENERO",
            "FEBRERO",
            "MARZO",
            "ABRIL",
            "MAYO",
            "JUNIO",
            "JULIO",
            "AGOSTO",
            "SEPTIEMBRE",
            "OCTUBRE",
            "NOVIEMBRE",
            "DICIEMBRE"
          ];
      
          // Obtener el nombre del mes de inicio y fin
          const mesInicio = nombresMeses[Number(objeto?.[tipo]?.Reporte?.mesInicio) - 1];
          const mesFin = nombresMeses[Number(objeto?.[tipo]?.Reporte?.mesFin) - 1];
      
          return `${mesInicio}-${mesFin}`;
        }
      }
    }

    return 'ENERO-DICIEMBRE';
  }

  volverLista(): void {
    this.formularioForm?.reset();
    this.rutSeleccionado = '';
    this.razonSocialSeleccionado = '';
    this.idTransaccion = '';
    this.periodo = 0;
    this.showList = true;
    this.showReporte = false;
    this.showListaBalances = false;
    this.showFormBalances = false;
    this.showRuts = false;
    this.definicionBalance = {};
    this.camposBalance = {};
    this.campos = {};
    this.listaSeleccionada = [];
    this.dataReporte = [];
    this.formularioArchivo?.reset();
    this.advertencias = [];
    this.tempFile = {};
    this.arrayContrato = [];
    this.tipoBalanceWeb = "";
    this.tipoBalanceWebOriginal = "";
    this.tipoBalance2Web = "";
    this.auditadoWeb = "";
    this.monedaWeb = "";
    this.periodo = '';
    this.mesInicioIngresado = '';
    this.mesFinalIngresado = '';
    this.base64Xlsx = '';
    this.obtenerLista();
  }

  crearPreBalance(fromWeb: boolean = true): void {
    this.arrayContrato = [];
    if(fromWeb){
      this.tipoBalanceWeb = "";
      this.tipoBalanceWebOriginal = "";
      this.tipoBalance2Web = "";
      this.auditadoWeb = "";
      this.monedaWeb = "";
      this.periodo = '';
      this.mesInicioIngresado = '';
      this.mesFinalIngresado = '';
    }
    this.showOpcionCarga = true;
    const rutFormattedPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    const elemento = this.listaBalances.find(e => e[0] == rutFormattedPrincipal);
    if(elemento && elemento[1] && elemento[1].length > 0){
      this.razonSocialSeleccionado = elemento[1][0].nombreRazonSocial || '';
    }
    this.spinner.show();
    this.balanceManualService.obtenerContratoBalanceManual().subscribe(
      (data: any) => {
        this.definicionBalance = data?.input;
        this.rutSeleccionado = rutFormattedPrincipal || '';
        this.obtenerSecciones();
        this.initForm();
        this.arrayContrato = this.generarContratoFromContrato();
        this.showList = false;
        this.showReporte = false;
        this.showListaBalances = false;
        this.cargaFormulario = true;
        this.showFormBalances = true;
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener formulario a ingresar');
        this.spinner.hide();
    });
  }

  onChangeTipo(): void {
    const elemento = this.dataReporte[0] || {};
    const reporteTipo = elemento?.Reporte?.tipo;

    if (elemento && Object.keys(elemento).length > 0) {  // edición
      if (this.tipoBalanceWeb === 'balance') {
        if (reporteTipo === 'balance') {
          this.mesInicioIngresado = elemento.Reporte.mesInicio || '';
          this.mesFinalIngresado = elemento.Reporte.mesFin || '';
        } else if (reporteTipo === 'prebalance') {
          this.mesInicioIngresado = 1;
          this.mesFinalIngresado = 12;
        }
      } else if (this.tipoBalanceWeb === 'prebalance') {
        if (reporteTipo === 'prebalance') {
          this.mesInicioIngresado = elemento.Reporte.mesInicio || '';
          this.mesFinalIngresado = elemento.Reporte.mesFin || '';
        } else if (reporteTipo === 'balance') {
          this.mesInicioIngresado = '';
          this.mesFinalIngresado = '';
        }
      }
    } else {  // creación
      if (this.tipoBalanceWeb === 'balance') {
        this.mesInicioIngresado = 1;
        this.mesFinalIngresado = 12;
      } else {
        this.mesInicioIngresado = '';
        this.mesFinalIngresado = '';
      }
    }
  }

  initForm(): void { 
    this.formularioForm = this.formBuilder.group({});

    Object.keys(this.definicionBalance).forEach(key => { 
      this.formularioForm.addControl(key, new UntypedFormControl((this.campos[key] || this.campos[key] == 0) ? this.campos[key] : 
      ( this.definicionBalance[key]?.val?.default?.[0]|| this.definicionBalance[key]?.val?.default?.[0] == 0 ? this.definicionBalance[key]?.val?.default?.[0] : ''), 
      [Validators.required]));
    });

  }

  filtroDefinicionesEditar(objeto: any): any {
    const respuesta: any = {};

    for (const key in objeto) {
      if(this.campos[key] || this.campos[key] == 0){
        respuesta[key] = objeto[key];
      }
    }

    return respuesta || {};
  }

  obtenerSecciones(): void {
    const sectionMap = new Map();
  
    for (const key in this.definicionBalance) {
      const nivel1 = this.definicionBalance[key].nivel1;
      const nivel1Name = this.definicionBalance[key].nivel1Name;
      const nivel2 = this.definicionBalance[key].nivel2;
      const nivel2Name = this.definicionBalance[key].nivel2Name;
      const nivel3 = this.definicionBalance[key].nivel3;
      const nivel3Name = this.definicionBalance[key].nivel3Name;
  
      if (!sectionMap.has(nivel1)) {
        sectionMap.set(nivel1, { nivel1, nivel1Name, nivel2: [] });
      }
  
      const sectionObj = sectionMap.get(nivel1);
      const nivel2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
  
      if (!nivel2Obj) {
        sectionObj.nivel2.push({ nivel2, nivel2Name, nivel3: [] });
      }

      const section2Obj = sectionObj.nivel2.find((subSec: any) => subSec.nivel2 === nivel2);
      if(section2Obj){
        const nivel3Obj = section2Obj.nivel3.find((subSec: any) => subSec.nivel3 === nivel3);
    
        if (!nivel3Obj) {
          section2Obj.nivel3.push({ nivel3, nivel3Name });
        }
      }
  
    }
  
    this.secciones = Array.from(sectionMap.values());

    const seccionesOrdenadas = this.secciones.map(sectionObj => ({
      ...sectionObj,
      nivel2: sectionObj?.nivel2?.map((nivel2Obj: any) => ({
        ...nivel2Obj,
        nivel3: nivel2Obj?.nivel3
      }))
    }));
  
    this.secciones = seccionesOrdenadas;
  }

  obtenerTotalSeccion(seccion: string): number {
    let suma = 0;
    for (const key in this.definicionBalance) {
      const e = this.definicionBalance[key];
      if ((e.type === 'number' || e.type === 'decimal') && e.nivel1 === seccion) {
        suma += Number(this.formularioForm.get([key])?.value);
      }
    }
    return suma;
  }

  obtenerTotalSubSeccion(seccion: string, subSeccion: string): number {
    let suma = 0;
    for (const key in this.definicionBalance) {
      const e = this.definicionBalance[key];
      if ((e.type === 'number' || e.type === 'decimal') && e.nivel1 === seccion && e.nivel2 && e.nivel2 === subSeccion) {
        suma += Number(this.formularioForm.get([key])?.value);
      }
    }
    return suma;
  }

  obtenerTotalSeccion3(seccion: string, subSeccion: string, section3: string): number {
    let suma = 0;
    for (const key in this.definicionBalance) {
      const e = this.definicionBalance[key];
      if ((e.type === 'number' || e.type === 'decimal') && e.nivel1 === seccion && e.nivel2 && e.nivel2 === subSeccion && e.nivel3 === section3) {
        suma += Number(this.formularioForm.get([key])?.value);
      }
    }
    return suma;
  }

  colorInput(error: boolean, id: string, mensaje?: string): void {
    if(error === true){
      const elementoHTML = document.getElementById(id);
      if(elementoHTML){
        elementoHTML.className = elementoHTML.className + " error-input";
        elementoHTML.title = mensaje || '';
      }
    } else {
      const elementoHTML = document.getElementById(id);
      if(elementoHTML){
        elementoHTML.className = elementoHTML.className.replace(" error-input", "");
        elementoHTML.title = '';
      }
    }
  }

  onSubmit(): void {
    this.colorInput(false, "periodoIngresoUsuario");
    this.colorInput(false, "mesInicioIngresado");
    this.colorInput(false, "mesFinalIngresado");
    this.colorInput(false, "tipoBalance2Web");
    this.colorInput(false, "auditadoWeb");
    this.colorInput(false, "monedaWeb");

    if(!this.periodo){
      this.alertService.error('Debe ingresar el periodo para guardar correctamente');
      this.colorInput(true, "periodoIngresoUsuario", 'Debe ingresar el periodo para guardar correctamente');
      return;
    }

    if(!this.tipoBalance2Web){
      this.alertService.error('Debe ingresar el subtipo de balance para guardar correctamente');
      this.colorInput(true, "tipoBalance2Web", 'Debe ingresar el subtipo de balance para guardar correctamente');
      return;
    }

    if(this.auditadoWeb != true && this.auditadoWeb != false){
      this.alertService.error('Debe ingresar el campo auditado para guardar correctamente');
      this.colorInput(true, "auditadoWeb", 'Debe ingresar el campo auditado para guardar correctamente');
      return;
    }

    if(!this.monedaWeb){
      this.alertService.error('Debe ingresar la moneda para guardar correctamente');
      this.colorInput(true, "monedaWeb", 'Debe ingresar la moneda para guardar correctamente');
      return;
    }

    if(!this.mesInicioIngresado || (this.mesInicioIngresado && (Number(this.mesInicioIngresado) < 1 || Number(this.mesInicioIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes de inicio para guardar correctamente');
      this.colorInput(true, "mesInicioIngresado", 'Debe ingresar el mes de inicio para guardar correctamente');
      return;
    }

    if(!this.mesFinalIngresado || (this.mesFinalIngresado && (Number(this.mesFinalIngresado) < 1 || Number(this.mesFinalIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes final para guardar correctamente');
      this.colorInput(true, "mesFinalIngresado", 'Debe ingresar el mes final para guardar correctamente');
      return;
    }

    const rutFormattedSeleccionado = (this.rutSeleccionado.includes("-") ? this.rutSeleccionado : RUT.applyBackendFormat(this.rutSeleccionado));
    const elemento = this.listaBalances.find(e => e?.[0] === rutFormattedSeleccionado);
    if(elemento && elemento[1] && elemento[1].length > 0){
      const objeto = elemento[1].find((e: any) => e.periodo === this.periodo);
      if(objeto){
        if(objeto?.listaPreBalances && !(objeto?.listaPreBalances.length <= 12)){
          this.alertService.error('El periodo indicado llegó al maximo de 12 pre balances a ingresar por periodo');
          return;
        }
      }
    }

    if (!this.formularioForm.valid) {
      this.utilsService.validateAllFormFields(this.formularioForm);
      this.alertService.error('Debe completar los datos necesarios para guardar correctamente');
      return;
    }

    const formTemp: any = this.formularioForm.getRawValue();
    Object.keys(formTemp).forEach(key => {
      if(this.definicionBalance[key].type.toUpperCase() === 'BOOLEAN'){
        if(formTemp[key] === 'true'){
          formTemp[key] = true;
        } else if (formTemp[key] === 'false'){
          formTemp[key] = false;
        }
      } else if (this.definicionBalance[key].type.toUpperCase() === 'DATE') {
        const fecha = new Date(formTemp[key]);
        formTemp[key] = fecha.toISOString();
      } else if (this.definicionBalance[key].type.toUpperCase() === 'NUMBER' || this.definicionBalance[key].type.toUpperCase() === 'BIGINT' || this.definicionBalance[key].type.toUpperCase() === 'DECIMAL'){
        formTemp[key] = Number(formTemp[key]);
      }
    });

    let correcto = true;

    for(const [key, value] of Object.entries(formTemp)){
      if(this.definicionBalance[key]?.val?.max){
        if(!this.validaMax(formTemp[key], this.definicionBalance[key]?.val?.max[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} no puede ser mayor a ${this.definicionBalance[key]?.val?.max[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.min){
        if(!this.validaMin(formTemp[key], this.definicionBalance[key]?.val?.min[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} no puede ser menor a ${this.definicionBalance[key]?.val?.min[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.length){
        if(!this.validaLength(formTemp[key], this.definicionBalance[key]?.val?.length[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} debe ser igual a ${this.definicionBalance[key]?.val?.length[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.email){
        if(!this.validaEmail(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe tener formato de email`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.url){
        if(!this.validaUrl(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser una url`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.includes){
        if(!this.validaIncludes(formTemp[key], this.definicionBalance[key]?.val?.includes[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe incluir ${this.definicionBalance[key]?.val?.includes[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.startsWith){
        if(!this.validaStartsWith(formTemp[key], this.definicionBalance[key]?.val?.startsWith[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe iniciar con ${this.definicionBalance[key]?.val?.startsWith[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.endsWith){
        if(!this.validaEndsWith(formTemp[key], this.definicionBalance[key]?.val?.endsWith[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe terminar con ${this.definicionBalance[key]?.val?.endsWith[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.gt){
        if(!this.validaGt(formTemp[key], this.definicionBalance[key]?.val?.gt[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser mayor a ${this.definicionBalance[key]?.val?.gt[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.gte){
        if(!this.validaGte(formTemp[key], this.definicionBalance[key]?.val?.gte[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser mayor o igual a ${this.definicionBalance[key]?.val?.gte[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.lt){
        if(!this.validaLt(formTemp[key], this.definicionBalance[key]?.val?.lt[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser menor a ${this.definicionBalance[key]?.val?.lt[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.lte){
        if(!this.validaLte(formTemp[key], this.definicionBalance[key]?.val?.lte[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser menor o igual a ${this.definicionBalance[key]?.val?.lte[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.int){
        if(!this.validaInt(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser un número`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.nonempty){
        if(!this.validaNonEmpty(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} no puede ser vacio`);
          correcto = false;
        }
      }
    }

    if(!correcto){
      return;
    }

    this.spinner.show();
    this.balanceManualService.crearPreBalance(rutFormattedSeleccionado, this.periodo, this.mesInicioIngresado, this.mesFinalIngresado, formTemp, this.idTransaccion, this.tipoBalance2Web, this.auditadoWeb, this.monedaWeb).subscribe(
      (data: IBalanceManual) => {
        this.dataReporte = [data?.BalanceManualReporte] || [];
        this.showList = false;
        this.showFormBalances = false;
        this.showListaBalances = false;
        this.showReporte = true;
        this.spinner.hide();
      },
      ({ error }) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // scroll up para mostrar mensaje de error
        this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar pre balance.');
        this.spinner.hide();
    });

  }

  indicarErrorVariable(key: string, mensaje: string): void {
    this.formularioForm?.get(key)?.setErrors({
      'errorPersonalizado': { mensaje: mensaje }
    });
    this.alertService.error(mensaje);
  }

  validaMax(valor: string, argumento: number): boolean {
    if(valor.length <= argumento)
      return true
    return false;
  }

  validaMin(valor: string, argumento: number): boolean {
    if(valor.length >= argumento)
      return true
    return false;
  }

  validaLength(valor: string, argumento: number): boolean {
    if(valor.length == argumento)
      return true
    return false;
  }

  validaEmail(valor: string): boolean {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return valor.match(regex) !== null;
  }

  validaUrl(valor: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/\S*)?$/;
    return valor.match(regex) !== null;
  }

  validaIncludes(valor: string, argumento: string): boolean {
    if(valor.includes(argumento))
      return true
    return false;
  }

  validaStartsWith(valor: string, argumento: string): boolean {
    return valor.startsWith(argumento);
  }

  validaEndsWith(valor: string, argumento: string): boolean {
    return valor.endsWith(argumento);
  }

  validaGt(valor: number, argumento: number): boolean {
    if(valor > argumento)
      return true
    return false;
  }

  validaGte(valor: number, argumento: number): boolean {
    if(valor >= argumento)
      return true
    return false;
  }

  validaLt(valor: number, argumento: number): boolean {
    if(valor < argumento)
      return true
    return false;
  }

  validaLte(valor: number, argumento: number): boolean {
    if(valor <= argumento)
      return true
    return false;
  }

  validaInt(valor: any): boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    // Si es un string, asegurar que sea un número sin letras
    if (typeof valor === 'string') {
        const numeroConvertido = parseFloat(valor);
        // Verifica que la conversión sea un número válido y que al convertirlo de vuelta a string sea igual al original
        // (esto asegura que no haya caracteres adicionales como letras).
        return !isNaN(numeroConvertido) && String(numeroConvertido) === valor;
    }

    // Si es un número, verificar que no sea NaN
    if (typeof valor === 'number') {
        return !isNaN(valor);
    }

    // Cualquier otro tipo de valor es inválido
    return false;
  }

  validaNonEmpty(valor: number): boolean {
    return Array.isArray(valor) && valor.length > 0;
  }

  onSubmitBalance(cerrar: boolean = false): void {
    this.colorInput(false, "periodoIngresoUsuario");
    this.colorInput(false, "mesInicioIngresado");
    this.colorInput(false, "mesFinalIngresado");
    this.colorInput(false, "tipoBalance2Web");
    this.colorInput(false, "auditadoWeb");
    this.colorInput(false, "monedaWeb");

    const estado: string = cerrar ? 'closed' : 'in-progress';

    if(!this.periodo){
      this.alertService.error('Debe ingresar el periodo para guardar correctamente');
      this.colorInput(true, "periodoIngresoUsuario", 'Debe ingresar el periodo para guardar correctamente');
      return;
    }

    if(!this.tipoBalance2Web){
      this.alertService.error('Debe ingresar el subtipo de balance para guardar correctamente');
      this.colorInput(true, "tipoBalance2Web", 'Debe ingresar el subtipo de balance para guardar correctamente');
      return;
    }

    if(this.auditadoWeb != false && this.auditadoWeb != true){
      this.alertService.error('Debe ingresar el campo auditado para guardar correctamente');
      this.colorInput(true, "auditadoWeb", 'Debe ingresar el campo auditado para guardar correctamente');
      return;
    }

    if(!this.monedaWeb){
      this.alertService.error('Debe ingresar la moneda para guardar correctamente');
      this.colorInput(true, "monedaWeb", 'Debe ingresar la moneda para guardar correctamente');
      return;
    }

    if(!this.mesInicioIngresado || (this.mesInicioIngresado && (Number(this.mesInicioIngresado) < 1 || Number(this.mesInicioIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes de inicio para guardar correctamente');
      this.colorInput(true, "mesInicioIngresado", 'Debe ingresar el mes de inicio para guardar correctamente');
      return;
    }

    if(!this.mesFinalIngresado || (this.mesFinalIngresado && (Number(this.mesFinalIngresado) < 1 || Number(this.mesFinalIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes final para guardar correctamente');
      this.colorInput(true, "mesFinalIngresado", 'Debe ingresar el mes final para guardar correctamente');
      return;
    }

    if (!this.formularioForm.valid) {
      this.utilsService.validateAllFormFields(this.formularioForm);
      this.alertService.error('Debe completar los datos necesarios para guardar correctamente');
      return;
    }

    const formTemp: any = this.formularioForm.getRawValue();
    Object.keys(formTemp).forEach(key => {
      if(this.definicionBalance[key].type.toUpperCase() === 'BOOLEAN'){
        if(formTemp[key] === 'true'){
          formTemp[key] = true;
        } else if (formTemp[key] === 'false'){
          formTemp[key] = false;
        }
      } else if (this.definicionBalance[key].type.toUpperCase() === 'DATE') {
        const fecha = new Date(formTemp[key]);
        formTemp[key] = fecha.toISOString();
      } else if (this.definicionBalance[key].type.toUpperCase() === 'NUMBER' || this.definicionBalance[key].type.toUpperCase() === 'BIGINT' || this.definicionBalance[key].type.toUpperCase() === 'DECIMAL'){
        formTemp[key] = Number(formTemp[key]);
      }
    });

    let correcto = true;

    for(const [key, value] of Object.entries(formTemp)){
      if(this.definicionBalance[key]?.val?.max){
        if(!this.validaMax(formTemp[key], this.definicionBalance[key]?.val?.max[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} no puede ser mayor a ${this.definicionBalance[key]?.val?.max[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.min){
        if(!this.validaMin(formTemp[key], this.definicionBalance[key]?.val?.min[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} no puede ser menor a ${this.definicionBalance[key]?.val?.min[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.length){
        if(!this.validaLength(formTemp[key], this.definicionBalance[key]?.val?.length[0])) {
          this.indicarErrorVariable(key, `El largo de ${this.definicionBalance[key]?.name} debe ser igual a ${this.definicionBalance[key]?.val?.length[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.email){
        if(!this.validaEmail(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe tener formato de email`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.url){
        if(!this.validaUrl(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser una url`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.includes){
        if(!this.validaIncludes(formTemp[key], this.definicionBalance[key]?.val?.includes[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe incluir ${this.definicionBalance[key]?.val?.includes[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.startsWith){
        if(!this.validaStartsWith(formTemp[key], this.definicionBalance[key]?.val?.startsWith[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe iniciar con ${this.definicionBalance[key]?.val?.startsWith[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.endsWith){
        if(!this.validaEndsWith(formTemp[key], this.definicionBalance[key]?.val?.endsWith[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe terminar con ${this.definicionBalance[key]?.val?.endsWith[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.gt){
        if(!this.validaGt(formTemp[key], this.definicionBalance[key]?.val?.gt[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser mayor a ${this.definicionBalance[key]?.val?.gt[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.gte){
        if(!this.validaGte(formTemp[key], this.definicionBalance[key]?.val?.gte[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser mayor o igual a ${this.definicionBalance[key]?.val?.gte[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.lt){
        if(!this.validaLt(formTemp[key], this.definicionBalance[key]?.val?.lt[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser menor a ${this.definicionBalance[key]?.val?.lt[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.lte){
        if(!this.validaLte(formTemp[key], this.definicionBalance[key]?.val?.lte[0])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser menor o igual a ${this.definicionBalance[key]?.val?.lte[0]}`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.int){
        if(!this.validaInt(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} debe ser un número`);
          correcto = false;
        }
      }
      if(this.definicionBalance[key]?.val?.nonempty){
        if(!this.validaNonEmpty(formTemp[key])) {
          this.indicarErrorVariable(key, `El campo ${this.definicionBalance[key]?.name} no puede ser vacio`);
          correcto = false;
        }
      }
    }

    if(!correcto){
      return;
    }

    this.spinner.show();
    this.balanceManualService.crearBalance(this.rutSeleccionado, this.periodo, this.mesInicioIngresado, this.mesFinalIngresado, formTemp, estado, this.tipoBalance2Web, this.auditadoWeb, this.monedaWeb).subscribe(
      (data: IBalanceManual) => {
        this.dataReporte = [data?.BalanceManualReporte] || [];
        this.showList = false;
        this.showFormBalances = false;
        this.showListaBalances = false;
        this.showReporte = true;
        this.spinner.hide();
      },
      ({ error }) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // scroll up para mostrar mensaje de error
        this.alertService.error(error.message || 'Ha ocurrido un error intentar crear/actualizar balance.');
        this.spinner.hide();
    });
  }

  cerrarBalanceManual(): void {
    this.cerrarReporte.emit();
    this.definicionBalance = {};
    this.camposBalance = {};
    this.campos = {};
    this.secciones = [];
    this.showList = true;
    this.periodo = 0;
    this.listaBalances = [];
    this.currentItem = env.initItemPerPage;
    this.currentPageBalance = 0;
    this.respuestaPreBalance = [];
    this.respuestaBalance = [];
    this.periodos = [];
    this.dataReporte = [];
    this.showListaBalances = false;
    this.listaSeleccionada = [];
    this.showReporte = false;
  }

  verListadoBalances(rut: string): void {
    if(rut){
      const elemento = this.listaBalances.find(e => e?.[0] === rut);
      if(elemento && elemento[1] && elemento[1].length > 0){
        this.listaSeleccionada = elemento[1];
        if(this.listaSeleccionada.length > 0){
          this.dataReporte = [];
          this.showList = false;
          this.showFormBalances = false;
          this.showReporte = false;
          this.showListaBalances = true;
        }
      }
    }
  }

  filtrarObjetoNiveles(objeto: any, valor1: any, valor2: any = undefined, valor3: any = undefined) {
    const resultado = [];
    for (const key in objeto) {
      if (objeto.hasOwnProperty(key) && objeto[key]?.nivel1 === valor1 && objeto[key]?.nivel2 === valor2 && objeto[key]?.nivel3 === valor3) {
        const objetoResultante: any = {
          ...objeto[key],
          key: key || ""
        }
        resultado.push(objetoResultante);
      }
    }
  
    return resultado;
  }

  generarContratoFromContrato(): any[] {
    const respuesta: any[] = [];
    this.secciones.forEach(element1 => {
      if (!element1?.nivel1) {
        const arrayUndefined: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1) || [];
        const objeto: any = { variables: arrayUndefined };
        respuesta.push(objeto);
      } else {
        const objetoNivel1: any = {
          nivel1Name: element1?.nivel1Name || "",
          nivel1: element1?.nivel1 || "",
          variables: [],
          nivel2: []
        };
  
        if (element1?.nivel2 && element1?.nivel2.length > 0) {
          element1.nivel2.forEach((element2: any) => {
            if (!element2?.nivel2) {
              const arrayNivel2: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1, element2?.nivel2) || [];
              const objeto: any = {
                nivel2Name: "",
                nivel2: "",
                variables: arrayNivel2
              };
              objetoNivel1.nivel2.push(objeto);
            } else {
              const objetoNivel2: any = {
                nivel2Name: element2?.nivel2Name || "",
                nivel2: element2?.nivel2 || "",
                variables: [],
                nivel3: []
              };
  
              if (element2?.nivel3 && element2?.nivel3.length > 0) {
                element2.nivel3.forEach((element3: any) => {
                  if (!element3?.nivel3) {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objeto: any = {
                      nivel3Name: "",
                      nivel3: "",
                      variables: arrayNivel3
                    };
                    objetoNivel2.nivel3.push(objeto);
                  } else {
                    const arrayNivel3: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1, element2?.nivel2, element3?.nivel3) || [];
                    const objetoNivel3: any = {
                      nivel3Name: element3?.nivel3Name || "",
                      nivel3: element3?.nivel3 || "",
                      variables: arrayNivel3 || []
                    };
                    objetoNivel2.nivel3.push(objetoNivel3);
                  }
                });
              } else {
                const arrayNivel2: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1, element2?.nivel2) || [];
                objetoNivel2.variables.push(...arrayNivel2);
              }
  
              objetoNivel1.nivel2.push(objetoNivel2);
            }
          });
        } else {
          const arrayNivel1: any = this.filtrarObjetoNiveles(this.definicionBalance, element1?.nivel1) || [];
          objetoNivel1.variables.push(...arrayNivel1);
        }
  
        respuesta.push(objetoNivel1);
      }
    });
  
    return respuesta || [];
  }

  validarBalance(tipo: string, periodo: number | string, rut: string): boolean {
    if(tipo === 'consolidado') {
      const elemento = this.dataReporte.find(e => e?.Reporte?.subTipo === tipo && e?.Reporte?.periodo === periodo && `${e?.DatosBasicosSolicitud?.Rut}-${e?.DatosBasicosSolicitud?.DV}` === rut)
      if(elemento)
        return true;
      return false;
    } else {
      const elemento = this.dataReporte.find(e => e?.Reporte?.tipo === tipo && e?.Reporte?.periodo === periodo && `${e?.DatosBasicosSolicitud?.Rut}-${e?.DatosBasicosSolicitud?.DV}` === rut)
      if(elemento)
        return true;
      return false;
    }
  }

  validarEditar(tipo: string, periodo: number | string, rut: string): boolean {
    const rutFormatted = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    if(periodo && rut && rut === rutFormatted){
      const elemento = this.listaBalances.find(e => e?.[0] === rut);
      if(elemento && elemento[1] && elemento[1].length > 0){
        const objeto = elemento[1].find((e: any) => e.periodo === periodo);
        if(objeto){
          if(tipo === 'balance'){
            if(objeto?.balance && Object.keys(objeto?.balance).length > 0){
              if(objeto?.balance?.Reporte?.estado === 'in-progress'){
                return true;
              }
            }
          } else if (tipo === 'consolidado'){
            if(objeto?.consolidado && Object.keys(objeto?.consolidado).length > 0){
              if(objeto?.consolidado?.Reporte?.estado === 'in-progress'){
                return true;
              }
            }
          } else { // pre balance
            if(objeto?.preBalance && Object.keys(objeto?.preBalance).length > 0){
              if(objeto?.preBalance?.Reporte?.estado === 'in-progress'){
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  }

  obtenerEstado(tipo: string, periodo: number | string, rut: string): string {
    if(periodo && rut){
      const elemento = this.listaBalances.find(e => e?.[0] === rut);
      if(elemento && elemento[1] && elemento[1].length > 0){
        const objeto = elemento[1].find((e: any) => e.periodo === periodo);
        if(objeto){
          if(tipo === 'balance'){
            if(objeto?.balance && Object.keys(objeto?.balance).length > 0){
              return objeto?.balance?.Reporte?.estado || '';
            }
          } else if (tipo === 'consolidado') {
            if(objeto?.consolidado && Object.keys(objeto?.consolidado).length > 0){
              return objeto?.consolidado?.Reporte?.estado || '';
            }
          } else { // pre balance
            if(objeto?.preBalance && Object.keys(objeto?.preBalance).length > 0){
              return objeto?.preBalance?.Reporte?.estado || '';
            }
          }
        }
      }
    }
    return '';
  }

  editarByTable(tipo: string, periodo: number | string, rut: string): void {
    const rutFormatted = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    if(periodo && rut && rut === rutFormatted){
      const elemento = this.listaBalances.find(e => e?.[0] === rut);
      if(elemento && elemento[1] && elemento[1].length > 0){
        const objeto = elemento[1].find((e: any) => e.periodo === periodo);
        if(objeto){
          if(tipo === 'balance'){
            if(objeto?.balance && Object.keys(objeto?.balance).length > 0){
              if(objeto?.balance?.Reporte?.estado === 'in-progress'){
                this.dataReporte = [];
                this.dataReporte.push(objeto?.balance || {});
                this.editarPreBalance();
              }
            }
          } else if (tipo === 'consolidado') {
            if(objeto?.consolidado && Object.keys(objeto?.consolidado).length > 0){
              if(objeto?.consolidado?.Reporte?.estado === 'in-progress'){
                this.dataReporte = [];
                this.dataReporte.push(objeto?.consolidado || {});
                this.editarPreBalance();
              }
            }
          } else { // pre balance
            if(objeto?.preBalance && Object.keys(objeto?.preBalance).length > 0){
              if(objeto?.preBalance?.Reporte?.estado === 'in-progress'){
                this.dataReporte = [];
                this.dataReporte.push(objeto?.preBalance || {});
                this.editarPreBalance();
              }
            }
          }
        }
      }
    }
  }

  agregarBalanceComparacion(event: any, tipo: string, periodo: number | string, rut: string): void { // agrega balances y pre balances para visualizarlos (vista lista de balances)
    if (event?.target?.checked){

      if(this.dataReporte.length > 0){
        const element = this.dataReporte.find(e => `${e?.DatosBasicosSolicitud?.Rut}-${e?.DatosBasicosSolicitud?.DV}` === rut);
        if(!element){
          event.target.checked = false;
          return
        }
      }

      if(this.dataReporte.length < 3){
        if(periodo && rut){
          const elemento = this.listaBalances.find(e => e?.[0] === rut);
          if(elemento && elemento[1] && elemento[1].length > 0){
            const objeto = elemento[1].find((e: any) => e.periodo === periodo);
            if(objeto){
              if(tipo === 'balance'){
                if(objeto?.balance && Object.keys(objeto?.balance).length > 0){
                  this.dataReporte.push(objeto?.balance || {});
                }
              } else if (tipo === 'consolidado'){
                this.dataReporte.push(objeto?.consolidado || {});
              } else {
                if(objeto?.preBalance && Object.keys(objeto?.preBalance).length > 0){
                  this.dataReporte.push(objeto?.preBalance || {});
                }
              }
            } else {
              event.target.checked = false;
              return
            }
          } else {
            event.target.checked = false;
            return
          }
        } else {
          event.target.checked = false;
          return
        }
      } else {
        event.target.checked = false;
        return
      }
    } else {
      const index = this.dataReporte.findIndex(e => `${e?.DatosBasicosSolicitud?.Rut}-${e?.DatosBasicosSolicitud?.DV}` === rut && e?.Reporte?.tipo === tipo && e?.Reporte?.periodo === periodo);
      if(index >= 0) {
        this.dataReporte.splice(index, 1);
      }
    }
  }

  verSeleccion(): void {
    if(this.dataReporte.length > 0){
      this.rutSeleccionado = `${this.dataReporte[0]?.DatosBasicosSolicitud?.Rut}-${this.dataReporte[0]?.DatosBasicosSolicitud?.DV}`;
      const elemento = this.listaBalances.find(e => e[0] == `${this.dataReporte[0]?.DatosBasicosSolicitud?.Rut}-${this.dataReporte[0]?.DatosBasicosSolicitud?.DV}`);
      if(elemento && elemento[1] && elemento[1].length > 0){
        this.razonSocialSeleccionado = elemento[1][0].nombreRazonSocial || '';
      }
      this.showList = false;
      this.showFormBalances = false;
      this.showListaBalances = false;
      this.showReporte = true;
      this.spinner.hide();
    }
  }

  editarPreBalance(): void {
    this.arrayContrato = [];
    this.tipoBalanceWeb = "";
    this.tipoBalanceWebOriginal = "";
    this.tipoBalance2Web = "";
    this.auditadoWeb = "";
    this.monedaWeb = "";
    if(this.dataReporte.length === 1) {
      const elemento = this.dataReporte[0] || {};
      if(elemento && Object.keys(elemento).length > 0) {

        const elementoRazon = this.listaBalances.find(e => e[0] == (`${elemento?.DatosBasicosSolicitud?.Rut}-${elemento?.DatosBasicosSolicitud?.DV}`));
        if(elementoRazon && elementoRazon[1] && elementoRazon[1].length > 0){
          this.razonSocialSeleccionado = elementoRazon[1][0].nombreRazonSocial || '';
        }

        this.camposBalance = elemento?.Reporte?.balance || {};
        this.campos = elemento?.Reporte?.campos || {};
        this.definicionBalance = this.filtroDefinicionesEditar(elemento?.Reporte?.definicionBalance?.input || {}) || {};
        this.mesInicioIngresado = elemento?.Reporte?.mesInicio || '';
        this.mesFinalIngresado = elemento?.Reporte?.mesFin || '';
        this.periodo = elemento?.Reporte?.periodo || '';
        this.tipoBalanceWeb = elemento?.Reporte?.tipo || '';
        this.tipoBalanceWebOriginal = elemento?.Reporte?.tipo || '';
        this.tipoBalance2Web = elemento?.Reporte?.subTipo || '';
        this.auditadoWeb = elemento?.Reporte?.auditado || elemento?.Reporte?.auditado === false ?  elemento?.Reporte?.auditado : '';
        this.monedaWeb = elemento?.Reporte?.moneda || '';
        this.rutSeleccionado = (`${elemento?.DatosBasicosSolicitud?.Rut}-${elemento?.DatosBasicosSolicitud?.DV}`) || '';
        this.idTransaccion = elemento?.DatosBasicosSolicitud?.IDTransaccion || '';
        this.obtenerSecciones();
        this.initForm();
        this.arrayContrato = this.generarContratoFromContrato();
        this.showList = false;
        this.showReporte = false;
        this.showListaBalances = false;
        this.cargaFormulario = true;
        this.showOpcionCarga = false;
        this.showFormBalances = true;
        this.spinner.hide();
      }
    }
  }

  agregarBalanceFromLista(event: any, element: any): void {
    if (event?.target?.checked){
      if(this.dataReporte.length < 3){
        if(element && Object.keys(element).length > 0) {
          this.dataReporte.push(element);
        }
      } else {
        event.target.checked = false;
        return
      }
    } else {
      if(element && Object.keys(element).length > 0) {
        const index = this.dataReporte.findIndex(e => e?.DatosBasicosSolicitud?.IDTransaccion === element?.DatosBasicosSolicitud?.IDTransaccion && 
          e?.Reporte?.tipo === element?.Reporte?.tipo);
        if(index >= 0) {
          this.dataReporte.splice(index, 1);
        }
      }
    }
  }

  validarBalanceFromLista(element: any): boolean {
    const elemento = this.dataReporte.find(e => e?.DatosBasicosSolicitud?.IDTransaccion === element?.DatosBasicosSolicitud?.IDTransaccion && 
      e?.Reporte?.tipo === element?.Reporte?.tipo);
    if(elemento){
      return true;
    }
    return false;
  }

  borrarBalance(periodo: number, DatosBasicosSolicitud: any, indexI: number, indexJ: number): void {
    const rut = `${DatosBasicosSolicitud?.Rut}-${DatosBasicosSolicitud?.DV}`;
    const IDTransaccion = DatosBasicosSolicitud?.IDTransaccion || '';
    if(periodo){
      this.spinner.show();
      this.balanceManualService.borrarBalance(rut, periodo).subscribe(
        (data: any) => {
          if(this.listaSeleccionada?.[indexI]?.listaBalances && this.listaSeleccionada?.[indexI]?.listaBalances.length > 0){
            this.listaSeleccionada?.[indexI]?.listaBalances.splice(indexJ, 1);
          }
          if(IDTransaccion && this.dataReporte.length > 0) {
            const index = this.dataReporte.findIndex(e => e?.DatosBasicosSolicitud?.IDTransaccion === IDTransaccion && e?.Reporte?.tipo === 'balance');
            if(index >= 0) {
              this.dataReporte.splice(index, 1);
            }
          }
          this.alertService.success(data?.message || 'Se ha eliminado el reporte de balance.');

          this.spinner.hide();        
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al elimnar el balance');
          this.spinner.hide();
      });
    }
  }

  borrarPreBalance(DatosBasicosSolicitud: any, indexI: number, indexJ: number): void {
    const id = DatosBasicosSolicitud?.IDTransaccion || '';
    const rut = `${DatosBasicosSolicitud?.Rut}-${DatosBasicosSolicitud?.DV}`;
    if(id){
      this.spinner.show();
      this.balanceManualService.borrarPrebalance(rut, id).subscribe(
        (data: any) => {
          if(this.listaSeleccionada?.[indexI]?.listaPreBalances && this.listaSeleccionada?.[indexI]?.listaPreBalances.length > 0){
            this.listaSeleccionada?.[indexI]?.listaPreBalances.splice(indexJ, 1);
          }
          if(id && this.dataReporte.length > 0) {
            const index = this.dataReporte.findIndex(e => e?.DatosBasicosSolicitud?.IDTransaccion === id && e?.Reporte?.tipo === 'prebalance');
            if(index >= 0) {
              this.dataReporte.splice(index, 1);
            }
          }
          this.alertService.success(data?.message || 'Se ha eliminado el reporte de prebalance.');

          this.spinner.hide();        
        },
        ({ error }) => {
          this.alertService.error(error.message || 'Ha ocurrido un error al elimnar el prebalance');
          this.spinner.hide();
      });
    }
  }

  validaEditarBalance(): boolean {
    const rutFormattedPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    const rutFormattedSeleccionado = (this.rutSeleccionado.includes("-") ? this.rutSeleccionado : RUT.applyBackendFormat(this.rutSeleccionado));
    if(this.dataReporte.length === 1 && rutFormattedPrincipal === rutFormattedSeleccionado){
      if(this.dataReporte[0]?.Reporte?.tipo === 'prebalance'){
        if((this.dataReporte[0]?.Reporte?.estado?.toString().toUpperCase() || '') === 'IN-PROGRESS') {
          return true;
        }
      } else if (this.dataReporte[0]?.Reporte?.tipo === 'balance') {
        if((this.dataReporte[0]?.Reporte?.estado?.toString().toUpperCase() || '') === 'IN-PROGRESS') {
          return true;
        }
      }
    }
    return false;
  }

  validaBorrarPreBalance(preBalance: any): boolean {
    const rutFormattedPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    const rut = `${preBalance?.DatosBasicosSolicitud?.Rut}-${preBalance?.DatosBasicosSolicitud?.DV}`;
    if(Object.keys(preBalance).length > 0 && rutFormattedPrincipal === rut){
      if(preBalance?.Reporte?.tipo === 'prebalance'){
        if((preBalance?.Reporte?.estado?.toString().toUpperCase() || '') === 'IN-PROGRESS') {
          return true;
        }
      }
    }
    return false;
  }

  validaRutBalance(balance: any): boolean {
    const rutFormattedPrincipal = (this.rut.includes("-") ? this.rut : RUT.applyBackendFormat(this.rut));
    const rut = `${balance?.DatosBasicosSolicitud?.Rut}-${balance?.DatosBasicosSolicitud?.DV}`;
    if(rutFormattedPrincipal === rut){
      return true;
    }
    return false;
  }

  toggleContent(event: any, id: string): void{
    let estadoClosed: boolean | null = null;
    if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TH') {
      if(event?.target?.parentElement) {
        const parentElement: any = event?.target?.parentElement;
        if ( parentElement.classList.contains('closed') ){
          parentElement.classList.remove('closed');
          estadoClosed = false;
        } else {
          parentElement.classList.add('closed');
          estadoClosed = true;
        }
      }
    } else if(event?.target?.tagName && event?.target?.tagName.toUpperCase() === 'TR') {
      const element: any = event?.target;
      if ( element.classList.contains('closed') ){
        element.classList.remove('closed');
        estadoClosed = false;
      } else {
        element.classList.add('closed');
        estadoClosed = true;
      }

    }

    if(estadoClosed != null){
      const elementosHTML: any = document.querySelectorAll(`[id^="${id}"]`);
      for (const elementoHTML of elementosHTML) {
        if(elementoHTML){
          if(estadoClosed === true) {
            elementoHTML.classList.contains('d-none') ? null : elementoHTML.classList.add('d-none');
          } else {
            elementoHTML.classList.contains('d-none') ? elementoHTML.classList.remove('d-none') : null;
            elementoHTML.classList.contains('closed') ? elementoHTML.classList.remove('closed') : null;
          }
        }
      }
    }
  }

  obtenerNombreMes(mes: number): string {
    const meses = [
      "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
      "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    // Verificar que el número esté entre 1 y 12
    if (mes && mes >= 1 && mes <= 12) {
      return meses[mes - 1];
    } else {
      return "";
    }
  }

  obtenerNombreEstado(estado: string): string {
    if (estado) {
      if(estado === 'closed') return 'Cerrado';
      if(estado === 'in-progress') return 'En Progreso';
      return estado || '';
    } else {
      return "";
    }
  }

  changeCarga(valor: boolean): void {
    this.cargaFormulario = valor;
    if(valor === true) {
      this.crearPreBalance(false);
    } else {
      this.cargarPreBalance();
    }
  }

  cargarPreBalance(): void {
    this.initFormArchivo();
    this.advertencias = [];
    this.tempFile = {};
    this.arrayContrato = [];
    this.base64Xlsx = '';
    // obtener xls base
    this.spinner.show();
    this.formularioF22Service.obtenerPlantillaBalanceManual().subscribe(
      (data: any) => {
        this.base64Xlsx = data?.xlsx || '';
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error intentar obtener la plantilla excel del balance.');
        this.spinner.hide();
    });
  }

  initFormArchivo(): void {
    this.formularioArchivo = this.formBuilder.group({
      file: [null]
    })
  }

  validarTipoArchivo(type: string, name: string): boolean {
    if (type && name) {
      const extension = name?.split('.')?.pop()?.toLowerCase() || '';
      switch (extension) {
        case 'xlsx':
          if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return false;
          } else {
            return true;
          }
        case 'xls':
          if (type !== 'application/vnd.ms-excel') {
            return false;
          } else {
            return true;
          }
        default:
          return false;
      }
    }
    return false;
  }

  handleUpload(event: any) {
    const file = event.target.files[0];
    this.advertencias = [];

    if(!this.validarTipoArchivo(file?.type, file?.name)) {
      this.alertService.error('La extensión del archivo es inválida');
      this.tempFile = {};
      this.formularioArchivo?.reset();
      return;
    }

    if(file.size <= 10000000){
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.tempFile = reader?.result?.toString().split(',')[1];
      };
    } else {
      this.alertService.error('El tamaño maximo del archivo debe ser de 10mb');
      this.tempFile = {};
      this.formularioArchivo?.reset();
    }
  }

  showErrors():void {
    const dialogRef = this.dialog.open(ListErrorsSimpleDialogComponent, {
      data: {
        title: 'Errores en Archivo',
        errors: this.advertencias
      }
    });

    dialogRef.afterClosed().subscribe(resp => { // validar
    });

    /*const resp = this.simpleModalService.addModal(ListErrorsSimpleDialogComponent, {
       title: 'Errores en Archivo',
       errors: this.advertencias
     }).subscribe((resp) => {
     });*/
  }

  onSubmitArchivo(estadoCerrado: boolean) {
    let archivoAtributos: string = "";
    if(typeof(this.tempFile) === 'string' && this.tempFile?.length > 0){
      archivoAtributos = this.tempFile.toString();
    } else {
      this.alertService.error('Debe cargar un archivo para guardar correctamente');
      return;
    }

    this.colorInput(false, "periodoIngresoUsuario");
    this.colorInput(false, "mesInicioIngresado");
    this.colorInput(false, "mesFinalIngresado");
    this.colorInput(false, "tipoBalance2Web");
    this.colorInput(false, "auditadoWeb");
    this.colorInput(false, "monedaWeb");

    if(!this.periodo){
      this.alertService.error('Debe ingresar el periodo para guardar correctamente');
      this.colorInput(true, "periodoIngresoUsuario", 'Debe ingresar el periodo para guardar correctamente');
      return;
    }

    if(!this.tipoBalance2Web){
      this.alertService.error('Debe ingresar el subtipo de balance para guardar correctamente');
      this.colorInput(true, "tipoBalance2Web", 'Debe ingresar el subtipo de balance para guardar correctamente');
      return;
    }

    if(this.auditadoWeb != true && this.auditadoWeb != false){
      this.alertService.error('Debe ingresar el campo auditado para guardar correctamente');
      this.colorInput(true, "auditadoWeb", 'Debe ingresar el campo auditado para guardar correctamente');
      return;
    }

    if(!this.monedaWeb){
      this.alertService.error('Debe ingresar la moneda para guardar correctamente');
      this.colorInput(true, "monedaWeb", 'Debe ingresar la moneda para guardar correctamente');
      return;
    }

    if(!this.mesInicioIngresado || (this.mesInicioIngresado && (Number(this.mesInicioIngresado) < 1 || Number(this.mesInicioIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes de inicio para guardar correctamente');
      this.colorInput(true, "mesInicioIngresado", 'Debe ingresar el mes de inicio para guardar correctamente');
      return;
    }

    if(!this.mesFinalIngresado || (this.mesFinalIngresado && (Number(this.mesFinalIngresado) < 1 || Number(this.mesFinalIngresado) > 12))){
      this.alertService.error('Debe ingresar el mes final para guardar correctamente');
      this.colorInput(true, "mesFinalIngresado", 'Debe ingresar el mes final para guardar correctamente');
      return;
    }

    const rutFormattedSeleccionado = (this.rutSeleccionado.includes("-") ? this.rutSeleccionado : RUT.applyBackendFormat(this.rutSeleccionado));
    const elemento = this.listaBalances.find(e => e?.[0] === rutFormattedSeleccionado);

    if(this.tipoBalanceWeb === 'prebalance'){
      if(elemento && elemento[1] && elemento[1].length > 0){
        const objeto = elemento[1].find((e: any) => e.periodo === this.periodo);
        if(objeto){
          if(objeto?.listaPreBalances && !(objeto?.listaPreBalances.length <= 12)){
            this.alertService.error('El periodo indicado llegó al maximo de 12 pre balances a ingresar por periodo');
            return;
          }
        }
      }
    }

    this.spinner.show();
    if(this.tipoBalanceWeb === 'prebalance'){
      this.balanceManualService.crearPreBalance(rutFormattedSeleccionado, this.periodo, this.mesInicioIngresado, this.mesFinalIngresado, null, '', this.tipoBalance2Web, this.auditadoWeb, this.monedaWeb, archivoAtributos).subscribe(
        (data: IBalanceManual) => {
          this.dataReporte = [data?.BalanceManualReporte] || [];
          this.listaArchivos = data?.BalanceManualReporte?.DatosBasicosSolicitud?.IDTransaccion ? [data?.BalanceManualReporte?.DatosBasicosSolicitud?.IDTransaccion] : [];
          this.showList = false;
          this.showFormBalances = false;
          this.showListaBalances = false;
          this.showReporte = true;
          this.spinner.hide();
        },
        ({ error }) => {
          if(error?.issues && error?.issues.length > 0){
            this.advertencias = error?.issues || [];
          }
          this.alertService.error(error.message || 'Ha ocurrido un error al crear/actualizar pre balance.');
          window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // scroll up para mostrar mensaje de error
          this.spinner.hide();
      });
    } else if (this.tipoBalanceWeb === 'balance') {
      const estado: string = estadoCerrado ? 'closed' : 'in-progress';

      this.balanceManualService.crearBalance(this.rutSeleccionado, this.periodo, this.mesInicioIngresado, this.mesFinalIngresado, null, estado, this.tipoBalance2Web, this.auditadoWeb, this.monedaWeb, archivoAtributos).subscribe(
        (data: IBalanceManual) => {
          this.dataReporte = [data?.BalanceManualReporte] || [];
          this.listaArchivos = data?.BalanceManualReporte?.DatosBasicosSolicitud?.IDTransaccion ? [data?.BalanceManualReporte?.DatosBasicosSolicitud?.IDTransaccion] : [];
          this.showList = false;
          this.showFormBalances = false;
          this.showListaBalances = false;
          this.showReporte = true;
          this.spinner.hide();
        },
        ({ error }) => {
          if(error?.issues && error?.issues.length > 0){
            this.advertencias = error?.issues || [];
          }
          this.alertService.error(error.message || 'Ha ocurrido un error intentar crear/actualizar balance.');
          window.scroll({ top: 0, left: 0, behavior: 'smooth' }); // scroll up para mostrar mensaje de error
          this.spinner.hide();
      });
    } else {
      this.spinner.hide();
    }
  }

  descargarExcel(): void {
    const blob = this.base64ToBlob(this.base64Xlsx, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'BaseCargaBalance.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  }

  base64ToBlob(base64: string, type: string): Blob {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: type });
  }

  verLogLista(): void {
    this.dialog.open(ModalBalanceManualLogComponent, {
      data: {
        rut: this.listaSeleccionada?.[0]?.rut || '',
        tipo: '',
        periodo: '',
        tx: ''
      }
    });
  }

  verLog(): void {
    const elemento = this.dataReporte[0] || {};
    if(elemento && Object.keys(elemento).length > 0) {
      const rut = `${elemento?.DatosBasicosSolicitud?.Rut}-${elemento?.DatosBasicosSolicitud?.DV}` || '';
      const tipo = elemento?.Reporte?.tipo || '';
      const periodo = elemento?.Reporte?.periodo || '';
      const idTx = elemento?.DatosBasicosSolicitud?.IDTransaccion || '';
      this.dialog.open(ModalBalanceManualLogComponent, {
        data: {
          rut: rut || '',
          tipo: tipo,
          periodo: periodo, 
          tx: idTx
        }
      });
    }
  }

  exportAsXLSX(): void {
    this.reporteBalanceManualViewComponent.generarExcel();
  }

}
