import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { AlertService } from 'src/app/components/_alert';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { ModalBasicoConsolidadoService } from 'src/app/shared/services/modal-basico-consolidado.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { SolicitudCreditoServiceWorkflow } from 'src/app/shared/services/solicitud-credito-workflow.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

interface AntecedentesCliente {
  razonSocial: string,
  rut: string,
  antiguedadCMT: string,
  inicioActividades: string,
  segmento: string,
  grupo: string,
  responsableDeudor: string,
  claseRiesgo: string,
  impagosDicomM: string | number
}

interface AntecedentesSocietarios {
  socios: ElementoAntecedentesSocietarios[],
  sociedades: ElementoAntecedentesSocietarios[]
}

interface ElementoAntecedentesSocietarios {
  nombre: string,
  rut: string,
  dicomM: number,
  entidad: string,
}

interface SeguroCredito {
  ciaSeguro: string,
  vigenciaDesde: string,
  vigenciaHasta: string,
  fechaResolucion: string,
  montoSolicitadoUF: number | string,
  montoAprobadoUF: number | string,
  estado: string,
  condicionVenta: string,
  obs: string
}

interface AntecedentesInternos {
  lineasCredito: LineaCredito,
  deudaFacturada: DeudaFacturada
}

interface LineaCredito {
  lineaCreditoAseguradaM: number | string,
  lineaCreditoTemporalM: number | string,
  totalAseguradoM: number | string,
  lineaCreditoCMTM: number | string,
  lineaCreditoPuntualM: number | string,
  fechaExpiracionTemporal: string,
  fechaExpiracionPuntual: string,
  fechaExpiracionLineaCreditoCMT: string,
  dso: number | string,
  pppMensual: number | string,
  ppp3Meses: number | string,
  condicionPago: string
}

interface DeudaFacturada {
  tramo: Tramo[],
  totalM: TotalTramo,
  totalFacturadoM: number | string
}

interface Tramo {
  limInf: number | string,
  limSup: number | string,
  vencidoM: number | string,
  porVencerM: number | string
}

interface TotalTramo {
  vencidoM:  number | string,
  porVencerM:  number | string
}

interface AntecedentesInternosCmt {
  body: AntecedentesInternosCmtBody[],
  acumulado: AntecedentesInternosCmtTotales,
  promedio: AntecedentesInternosCmtTotales
}

interface AntecedentesInternosCmtBody {
  periodo: string,
  ventasM: number | string,
  cuentasPorCobrarM: number | string,
  pagosM: number | string
}

interface AntecedentesInternosCmtTotales {
  ventasM: number | string,
  cuentasPorCobrarM: number | string,
  pagosM: number | string
}

interface AntecedentesFinancieros {
  anio: number[],
  tipo: string[],
  periodoInicial: string[],
  periodoFinal: string[],
  moneda: string[],
  body: AntecedentesFinancierosElement[]
}

interface AntecedentesFinancierosElement {
  text: string,
  values: any[]
}

@Component({
  selector: 'app-plantilla-perfil-caratula',
  templateUrl: './plantilla-perfil-caratula.component.html',
  styleUrls: ['./plantilla-perfil-caratula.component.scss']
})
export class PlantillaPerfilCaratulaComponent implements OnInit {
  @Input() plantillaNoEditable: boolean = false;
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();

  public objectKeys = Object.keys;
  public subscriptions: Subscription[] = [];
  public today = '';
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public bodyModelos: any = {};
  public errores: any[] = [];
  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};
  public entidadesDerivadas: any = {};
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public showReportes: boolean = false;
  public showArchivos: boolean = false;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  //Modal:
  public rutSeleccionado: string = '';
  public reportesSeleccionado: any = {};
  public informeComercial: string = '';
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true;
  public consolidadoTitular: any = {};

  public antecedentesCliente: AntecedentesCliente | null = null;
  public antecedentesSocietarios: AntecedentesSocietarios | null = null;
  public seguroCredito: SeguroCredito[] = [];
  public antecedentesInternos: AntecedentesInternos | null = null;
  public antecedentesInternosCmt: AntecedentesInternosCmt | null = null;
  public antecedentesFinancieros: AntecedentesFinancieros | null = null;

  public valorUFActual: number = 0;
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar

  public numberNoDecimal: string[] = [
    'Ingresos',
    'EBITDA',
    'Resultado Ejercicio',
    'Activos',
    'Pasivos',
    'Patrimonio',
    'Existencias',
    'Capital de Trabajo'
  ];
  
  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private modalService: ModalBasicoConsolidadoService,
    private _sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  obtenerFlujo(): void {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;// --> solo en caso de avanzar en el flujo
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          // solo si se avanza de paso descomentar*
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            //this.callServices(); // comentar si se avanza en el flujo
            this.callServicesFicha(); // --> solo si se avanza en el flujo
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      }
      , //--> solo si se avanza en el flujo
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  mapeoFromFicha(ficha: any): void {
    this.rut = ficha?.rut || '';
    this.consolidadoTitular = ficha?.consolidadoTitular || {};
    this.antecedentesCliente = ficha?.antecedentesCliente || {};
    this.antecedentesSocietarios = ficha?.antecedentesSocietarios || {};
    this.seguroCredito = ficha?.seguroCredito || [];
    this.antecedentesInternos = ficha?.antecedentesInternos || {};
    this.antecedentesInternosCmt = ficha?.antecedentesInternosCmt || {};
    this.antecedentesFinancieros = ficha?.antecedentesFinancieros || {};
    this.valorUFActual = ficha?.valorUFActual || 0;
  }

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){

      //this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      //this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
      //this.fechaTx = response?.DatosBasicosSolicitud?.FechaReporte || '';

    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito' || service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = JSON.parse(JSON.stringify(response?.FichaDeCredito?.ficha));
      
      this.mapeoFromFicha(ficha);
      
      (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
    }
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.obtenerUf();
        } else if (etapa === 'callServicesFicha'){
          this.showReportes = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  logicaMapeo(): void {
    this.mapeoReportes();
    this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
    this.spinner.hide();
  }

  obtenerUf(): void {
    const date = new Date();
    date.setUTCHours(0,0,0,0);
    const dateFormatted: string = date.toISOString();
    let uf = 0;
    this.solicitudCreditoService.calcularUF(dateFormatted).subscribe(resp => { //"2021-12-30T00:00:00.000Z" // dateFormatted
      uf = resp?.valores?.[0]?.valor || 0;
      this.valorUFActual = Number(uf);
      if(!(uf > 0)){
        this.alertService.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.');
        console.error('Solicitud de Crédito - No se logró recuperar el valor de la UF.', resp?.valores?.[0]?.valor)
      }
      this.logicaMapeo();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al obtener los indicadores economicos');
      this.valorUFActual = uf;
      this.logicaMapeo();
    })
  }

  mapeoAntecedentesCliente(): void {
    this.antecedentesCliente = new Object() as AntecedentesCliente;
    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;

    const bpCredito = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.bpCredito) : null;

    // Sort bpCredito array based on limitValidDate
    if (bpCredito?.bpCredito && bpCredito?.bpCredito.length > 0) {
      bpCredito.bpCredito.sort((a: any, b: any) => {
        const dateA = a.limitValidDate ? new Date(a.limitValidDate) : new Date('9999-12-31');
        const dateB = b.limitValidDate ? new Date(b.limitValidDate) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime();
      });
    }

    this.antecedentesCliente.razonSocial = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';
    this.antecedentesCliente.rut = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Rut || this.rut || '';
    this.antecedentesCliente.antiguedadCMT = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.crdat || '') : '';
    this.antecedentesCliente.inicioActividades = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades || '';
    this.antecedentesCliente.segmento = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.zdeSegment || '') : '';
    this.antecedentesCliente.grupo = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.rgsbl || '') : '';
    this.antecedentesCliente.responsableDeudor = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.sname || '') : '';
    this.antecedentesCliente.claseRiesgo = ''; // por ahora vacio
    this.antecedentesCliente.impagosDicomM = 
      this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || 
      this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto == 0 ? 
      (this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto) : '';
  }

  obtenerPPPMensual(plazoPromedio: any[]): number | string {
    /*
      Días de Pago = Fecha Factura - Fecha Documento Pago; BLDAT - BLDAT
      Ponderación = Monto del Pago / Total de Pagos; DMBTR / Suma de DMBTR
      Factor = Días de Pago * Ponderación; (BLDAT - BLDAT) * (DMBTR / Suma de DMBTR)
      PPP Mensual = Suma de Factores; Suma de (BLDAT - BLDAT) * (DMBTR / Suma de DMBTR)
    */

    const fechaActual = new Date();  // new Date(2023, 3, 1);
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;

    const plazoPromedioMesActual = plazoPromedio.filter((item: any) => {
      const fecha = new Date(item?.bldat2);
      const anio = fecha.getFullYear();
      const mes = fecha.getMonth() + 1;
      return anio === anioActual && mes === mesActual;
    });
    
    let pppMensual: any = '';
    if(plazoPromedioMesActual && plazoPromedioMesActual.length > 0){
      let totalPagos = 0; //dmbtr2
      pppMensual = 0;

      for (const item of plazoPromedioMesActual) {
        totalPagos += Number(item?.dmbtr2 || 0);
      }

      for(const item of plazoPromedioMesActual) {
        const fecha1 = new Date(item?.bldat1);
        const fecha2 = new Date(item?.bldat2);
        const diffInTime = fecha2.getTime() - fecha1.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
        const ponderacion = Number(item?.dmbtr2 || 0) / Number(totalPagos || 0);
        const factor = Number(diffInDays || 0) * Number(ponderacion || 0);
        pppMensual += (Number(factor || 0));
      }

    }

    return pppMensual;
  }

  obtenerPPP3M(plazoPromedio: any[]): number | string {
    /*
    Ponderación del mes = Monto del Pago del mes / Total de Pagos de los tres meses
    Factor del mes = PPP Mensual del mes * Ponderación del mes
    PPP de los últimos tres meses = Suma de Factores de los tres meses
    */
    
    const fechaActual = new Date();  // new Date(2023, 3, 1);
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth() + 1;
    
    const plazoPromedioMesActual = plazoPromedio.filter((item: any) => {
      const fecha = new Date(item?.bldat2);
      const fechaLimite = new Date(anioActual, mesActual - 4, 1); // Get the date three months ago

      return fecha >= fechaLimite;
    });
    
    let pppMensual: any = '';
    if(plazoPromedioMesActual && plazoPromedioMesActual.length > 0){
      let totalPagos = 0; //dmbtr2
      pppMensual = 0;

      for (const item of plazoPromedioMesActual) {
        totalPagos += Number(item?.dmbtr2 || 0);
      }

      for(const item of plazoPromedioMesActual) {
        const fecha1 = new Date(item?.bldat1);
        const fecha2 = new Date(item?.bldat2);
        const diffInTime = fecha2.getTime() - fecha1.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
        const ponderacion = Number(item?.dmbtr2 || 0) / Number(totalPagos || 0);
        const factor = Number(diffInDays || 0) * Number(ponderacion || 0);
        pppMensual += (Number(factor || 0));
      }

    }

    return pppMensual;
  }

  convertirFechaFormato(fechaISO: any) {
    if(!fechaISO) return '';
    const date = new Date(fechaISO); 
    const dia = String(date.getUTCDate()).padStart(2, '0'); 
    const mes = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const año = date.getUTCFullYear();
    return `${dia}-${mes}-${año}`; 
  }

  mapeoAntecedentesInternosLineasCredito(): void {
    this.antecedentesInternos = new Object() as AntecedentesInternos;
    this.antecedentesInternos.lineasCredito = new Object() as LineaCredito;
    this.antecedentesInternos.deudaFacturada = new Object() as DeudaFacturada;

    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    const puntualesAprobados = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.puntualesAprobados) : null;
    const bpCredito = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.bpCredito) : null;
    const lineaNominadaContinental: any = this.getLineaContinental();
    let plazoPromedio = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.plazoPromedio) : [];
    plazoPromedio = plazoPromedio?.plazoPromedio || [];

    const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);
    
    let montoAprobadoUF: string | number = '';
    if(lineaNominadaContinental){
      montoAprobadoUF = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
    }
    if(lineaAvla){
      const tempMontoAprobadoUF = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
      if(tempMontoAprobadoUF > montoAprobadoUF){
        montoAprobadoUF = tempMontoAprobadoUF;
      }
    }
    
    // Sort bpCredito array based on limitValidDate
    if (bpCredito?.bpCredito && bpCredito?.bpCredito.length > 0) {
      bpCredito.bpCredito.sort((a: any, b: any) => {
        const dateA = a.limitValidDate ? new Date(a.limitValidDate) : new Date('9999-12-31');
        const dateB = b.limitValidDate ? new Date(b.limitValidDate) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime();
      });
    }

    // logica de temporal con continental
    const {lineaTemporalContinental, fechaExpiracionTemporal} = this.utilsService.getTemporalContinentalMiles(this.reportesPrincipalesObj?.consultaSegurosContinental?.reporte?.ConsultaSegurosContinentalLineasNominadas || [], this.valorUFActual, true);

    // logica de linea de credito puntual
    let lineaCreditoPuntualM: any = null;
    let fechaExpiracionPuntual: any = '';
    if (puntualesAprobados?.puntualesAprobados && puntualesAprobados?.puntualesAprobados.length > 0) {
      const fechaActual = new Date();
      const fechaActualString = fechaActual.toISOString();
      // se filtra los datos que cumplan con la condicion de fecha actual y se ordena de mayor a menor
      const lineaPuntualArray = puntualesAprobados?.puntualesAprobados.filter((e: any) => {
        if(!e.dateto || !e.datefr){
          return false;
        }
        const fechaHasta = e?.dateto || '';
        return fechaHasta >= fechaActualString;
      }).sort((a: any, b: any) => {
        const dateA = a.datefr ? new Date(a.datefr) : new Date('9999-12-31');
        const dateB = b.datefr ? new Date(b.datefr) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime(); // Ordenar de mayor a menor
      });
      const lineaPuntual = lineaPuntualArray[0];
      if(lineaPuntual){
        fechaExpiracionPuntual = lineaPuntual?.dateto || '';
        lineaCreditoPuntualM = lineaPuntual?.amnt || lineaPuntual?.amnt == 0 ? this.utilsService.toMiles(lineaPuntual?.amnt) : null;
      } else {
        lineaCreditoPuntualM = null;
      }
    }


    this.antecedentesInternos.lineasCredito.lineaCreditoAseguradaM = (montoAprobadoUF || montoAprobadoUF == 0) && this.valorUFActual > 0 ? 
      ( this.utilsService.toMiles((Number(montoAprobadoUF || 0) * this.valorUFActual)) ) : '';

    this.antecedentesInternos.lineasCredito.lineaCreditoTemporalM = lineaTemporalContinental;
    this.antecedentesInternos.lineasCredito.totalAseguradoM = Number(this.antecedentesInternos.lineasCredito.lineaCreditoAseguradaM || 0) + Number(this.antecedentesInternos.lineasCredito.lineaCreditoTemporalM || 0);
    this.antecedentesInternos.lineasCredito.lineaCreditoCMTM = bpCredito?.bpCredito[0] ? 
      (bpCredito?.bpCredito[0]?.creditLimit || bpCredito?.bpCredito[0]?.creditLimit == 0 ? this.utilsService.toMiles(bpCredito?.bpCredito[0]?.creditLimit) : '') : '';
    this.antecedentesInternos.lineasCredito.lineaCreditoPuntualM = !puntualesAprobados?.puntualesAprobados || puntualesAprobados?.puntualesAprobados.length <= 0 ?
      '' : ((lineaCreditoPuntualM || lineaCreditoPuntualM == 0) ? (lineaCreditoPuntualM || 0) : '');
    this.antecedentesInternos.lineasCredito.fechaExpiracionTemporal = fechaExpiracionTemporal || '';
    this.antecedentesInternos.lineasCredito.fechaExpiracionPuntual = fechaExpiracionPuntual ? this.convertirFechaFormato(fechaExpiracionPuntual) : '';
    this.antecedentesInternos.lineasCredito.fechaExpiracionLineaCreditoCMT = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.limitValidDate || '') : '';
    this.antecedentesInternos.lineasCredito.dso = bpCredito?.bpCredito[0] || bpCredito?.bpCredito[0] == 0 ? (bpCredito?.bpCredito[0]?.idxFigure || '') : '';    
    this.antecedentesInternos.lineasCredito.pppMensual = this.obtenerPPPMensual(plazoPromedio);    
    this.antecedentesInternos.lineasCredito.ppp3Meses = this.obtenerPPP3M(plazoPromedio);
    this.antecedentesInternos.lineasCredito.condicionPago = bpCredito?.bpCredito[0] ? (bpCredito?.bpCredito[0]?.ztermGlosa || '') : '';
    this.mapeoDeudaFacturada();
  }

  mapeoAntecendentesInternosCmt(): void {
    this.antecedentesInternosCmt = new Object() as AntecedentesInternosCmt;
    this.antecedentesInternosCmt.body = [];
    this.antecedentesInternosCmt.acumulado = new Object() as AntecedentesInternosCmtTotales;
    this.antecedentesInternosCmt.promedio = new Object() as AntecedentesInternosCmtTotales;

    const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
    let compraUltima = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.compraUltima) : null;
    let saldoCierre = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.saldoCierre) : null;
    let saldoPago = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.saldoPago) : null;

    const anioActual = new Date().getFullYear();
    const anioAnterior1 = anioActual - 1;
    const anioAnterior2 = anioActual - 2;

    compraUltima = compraUltima?.compraUltima || [];
    saldoCierre = saldoCierre?.saldoCierre || [];
    saldoPago = saldoPago?.saldoPago || [];

    const compraUltimaAnioAnterior1 = compraUltima?.filter((e: any) => e.gjahr == anioAnterior1) || [];
    const saldoCierreAnioAnterior1 = saldoCierre?.filter((e: any) => e.gjahr == anioAnterior1) || [];
    const saldoPagoAnioAnterior1 = saldoPago?.filter((e: any) => e.gjahr == anioAnterior1) || [];

    const compraUltimaAnioAnterior2 = compraUltima?.filter((e: any) => e.gjahr == anioAnterior2) || [];
    const saldoCierreAnioAnterior2 = saldoCierre?.filter((e: any) => e.gjahr == anioAnterior2) || [];
    const saldoPagoAnioAnterior2 = saldoPago?.filter((e: any) => e.gjahr == anioAnterior2) || [];

    let ventasMAnioAnterior2 = 0;
    let cuentasPorCobrarMAnioAnterior2 = 0;
    let pagosMAnioAnterior2 = 0;
    let ventasMAnioAnterior1 = 0;
    let cuentasPorCobrarMAnioAnterior1 = 0;
    let pagosMAnioAnterior1 = 0;
    
    for(let i = 1; i <= 12; i++){
      // calculo anio - 2
      if(compraUltimaAnioAnterior2){
        const compraUltimaAnioAnterio2Mes = compraUltimaAnioAnterior2?.find((e: any) => e.monat == i);
        if(compraUltimaAnioAnterio2Mes)
          ventasMAnioAnterior2 += (Number(this.utilsService.toMiles(compraUltimaAnioAnterio2Mes?.dmbtr || 0)));
      }

      if(saldoCierreAnioAnterior2){
        const saldoCierreAnioAnterio2Mes = saldoCierreAnioAnterior2?.find((e: any) => e.monat == i);
        if(saldoCierreAnioAnterio2Mes)
          cuentasPorCobrarMAnioAnterior2 += (Number(this.utilsService.toMiles(saldoCierreAnioAnterio2Mes?.dmbtr || 0)))
      }

      if(saldoPagoAnioAnterior2){
        const saldoPagoAnioAnterio2Mes = saldoPagoAnioAnterior2?.find((e: any) => e.monat == i);
        if(saldoPagoAnioAnterio2Mes)
          pagosMAnioAnterior2 += (Number(this.utilsService.toMiles(saldoPagoAnioAnterio2Mes?.dmbtr || 0)));
      }


      // calculo anio - 1
      if(compraUltimaAnioAnterior1){
        const compraUltimaAnioAnterio1Mes = compraUltimaAnioAnterior1?.find((e: any) => e.monat == i);
        if(compraUltimaAnioAnterio1Mes)  
          ventasMAnioAnterior1 += (Number(this.utilsService.toMiles(compraUltimaAnioAnterio1Mes?.dmbtr || 0)));
      }

      if(saldoCierreAnioAnterior1){
        const saldoCierreAnioAnterio1Mes = saldoCierreAnioAnterior1.find((e: any) => e.monat == i);
        if(saldoCierreAnioAnterio1Mes)
          cuentasPorCobrarMAnioAnterior1 += (Number(this.utilsService.toMiles(saldoCierreAnioAnterio1Mes?.dmbtr || 0)))
      }

      if(saldoPagoAnioAnterior1){
        const saldoPagoAnioAnterio1Mes = saldoPagoAnioAnterior1.find((e: any) => e.monat == i);
        if(saldoPagoAnioAnterio1Mes)
          pagosMAnioAnterior1 += (Number(this.utilsService.toMiles(saldoPagoAnioAnterio1Mes?.dmbtr || 0)));
      }

    }

    this.antecedentesInternosCmt.body.push({
      periodo: anioAnterior2.toString(),
      ventasM: ventasMAnioAnterior2,
      cuentasPorCobrarM: cuentasPorCobrarMAnioAnterior2,
      pagosM: pagosMAnioAnterior2
    });

    this.antecedentesInternosCmt.body.push({
      periodo: anioAnterior1.toString(),
      ventasM: ventasMAnioAnterior1,
      cuentasPorCobrarM: cuentasPorCobrarMAnioAnterior1,
      pagosM: pagosMAnioAnterior1
    });

    // calculo acumulado
    const mesesOrdenadosCompraUltima = compraUltima.sort((a: any, b: any) => {
      const anioA = a.gjahr;
      const mesA = a.monat;
      const anioB = b.gjahr;
      const mesB = b.monat;

      if (anioA === anioB) {
        return mesA - mesB;
      } else {
        return anioA - anioB;
      }
    });

    const mesesOrdenadosSaldoCierre = saldoCierre.sort((a: any, b: any) => {
      const anioA = a.gjahr;
      const mesA = a.monat;
      const anioB = b.gjahr;
      const mesB = b.monat;

      if (anioA === anioB) {
        return mesA - mesB;
      } else {
        return anioA - anioB;
      }
    });

    const mesesOrdenadosSaldoPago = saldoPago.sort((a: any, b: any) => {
      const anioA = a.gjahr;
      const mesA = a.monat;
      const anioB = b.gjahr;
      const mesB = b.monat;

      if (anioA === anioB) {
        return mesA - mesB;
      } else {
        return anioA - anioB;
      }
    });

    // ultimos 12 meses

    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth() + 1;

    const mesesRecientes: any[] = [];
    for (let i = 0; i < 12; i++) {
      let mes = mesActual - i;
      let anio = anioActual;
      if (mes <= 0) {
        mes += 12;
        anio -= 1;
      }
      mesesRecientes.push({mes: mes, anio: anio});
    }

    const ultimos12MesesCompraUltima = mesesOrdenadosCompraUltima.filter((mes: any) => {
      const anioMes = mes.gjahr;
      const numeroMes = mes.monat;

      // Solo incluir los meses que estén dentro de los últimos 12 meses
      const diferenciaMeses = (anioActual - anioMes) * 12 + mesActual - numeroMes;
      return diferenciaMeses >= 0 && diferenciaMeses < 12;
    });

    const ultimos12MesesSaldoCierre = mesesOrdenadosSaldoCierre.filter((mes: any) => {
      const anioMes = mes.gjahr;
      const numeroMes = mes.monat;

      // Solo incluir los meses que estén dentro de los últimos 12 meses
      const diferenciaMeses = (anioActual - anioMes) * 12 + mesActual - numeroMes;
      return diferenciaMeses >= 0 && diferenciaMeses < 12;
    });

    const ultimos12MesesSaldoPago = mesesOrdenadosSaldoPago.filter((mes: any) => {
      const anioMes = mes.gjahr;
      const numeroMes = mes.monat;

      // Solo incluir los meses que estén dentro de los últimos 12 meses
      const diferenciaMeses = (anioActual - anioMes) * 12 + mesActual - numeroMes;
      return diferenciaMeses >= 0 && diferenciaMeses < 12;
    });

    const ultimos12MesesCompraUltimaLimpios: any[] = [];
    const ultimos12MesesSaldoCierreLimpios: any[] = [];
    const ultimos12MesesSaldoPagoLimpios: any[] = [];

    for(const mes of mesesRecientes) {
      let compraUltimaAnioActualMesValue = 0;
      let saldoCierreAnioActualMesValue = 0;
      let saldoPagoAnioActualMesValue = 0;

      const compraUltimaMes = ultimos12MesesCompraUltima.find((e: any) => e?.monat == mes?.mes && e?.gjahr == mes?.anio);
      if(compraUltimaMes){
        const valor = this.utilsService.toMiles(compraUltimaMes?.dmbtr);
        if(compraUltimaMes.dmbtr) {
          compraUltimaMes.dmbtr = valor;
        }
        ultimos12MesesCompraUltimaLimpios.push(compraUltimaMes);
        compraUltimaAnioActualMesValue = (Number(valor || 0));
      } else {
        ultimos12MesesCompraUltimaLimpios.push({ monat: mes?.mes, dmbtr: null, gjahr: mes?.anio });
      }

      const saldoCierreMes = ultimos12MesesSaldoCierre.find((e: any) => e?.monat == mes?.mes && e?.gjahr == mes?.anio);
      if(saldoCierreMes){
        const valor = this.utilsService.toMiles(saldoCierreMes?.dmbtr);
        if(saldoCierreMes.dmbtr) {
          saldoCierreMes.dmbtr = valor;
        }
        ultimos12MesesSaldoCierreLimpios.push(saldoCierreMes);
        saldoCierreAnioActualMesValue = (Number(valor || 0));
      } else {
        ultimos12MesesSaldoCierreLimpios.push({ monat: mes?.mes, dmbtr: null, gjahr: mes?.anio });
      }

      const saldoPagoMes = ultimos12MesesSaldoPago.find((e: any) => e?.monat == mes?.mes && e?.gjahr == mes?.anio);
      if(saldoPagoMes){
        const valor = this.utilsService.toMiles(saldoPagoMes?.dmbtr);
        if(saldoPagoMes.dmbtr) {
          saldoPagoMes.dmbtr = valor;
        }
        ultimos12MesesSaldoPagoLimpios.push(saldoPagoMes);
        saldoPagoAnioActualMesValue = (Number(valor || 0));
      } else {
        ultimos12MesesSaldoPagoLimpios.push({ monat: mes?.mes, dmbtr: null, gjahr: mes?.anio });
      }

      this.antecedentesInternosCmt.body.push({
        periodo: `${mes?.anio}-${mes?.mes}`,
        ventasM: compraUltimaAnioActualMesValue,
        cuentasPorCobrarM: saldoCierreAnioActualMesValue,
        pagosM: saldoPagoAnioActualMesValue
      });

    }

    const sumaTotalCompraUltima = ultimos12MesesCompraUltimaLimpios.reduce((total: number, mes: any) => total + (Number(mes?.dmbtr || 0) || 0), 0);
    const sumaTotalSaldoCierre = ultimos12MesesSaldoCierreLimpios.reduce((total: number, mes: any) => total + (Number(mes?.dmbtr || 0) || 0), 0);
    const sumaTotalSaldoPago = ultimos12MesesSaldoPagoLimpios.reduce((total: number, mes: any) => total + (Number(mes?.dmbtr || 0) || 0), 0);

    this.antecedentesInternosCmt.acumulado = {
      ventasM: sumaTotalCompraUltima,
      cuentasPorCobrarM: sumaTotalSaldoCierre,
      pagosM: sumaTotalSaldoPago
    }

    const totalMesesCompraUltima = 12;
    const totalMesesSaldoCierre = 12;
    const totalMesesSaldoPago = 12;

    this.antecedentesInternosCmt.promedio = {
      ventasM: Number(sumaTotalCompraUltima) / totalMesesCompraUltima,
      cuentasPorCobrarM: Number(sumaTotalSaldoCierre) / totalMesesSaldoCierre,
      pagosM: Number(sumaTotalSaldoPago) / totalMesesSaldoPago
    }

    // ordenamiento por periodo
    this.antecedentesInternosCmt.body.sort((a, b) => {
      const periodoA = a.periodo.split('-');
      const periodoB = b.periodo.split('-');
      if (periodoA.length === 1 && periodoB.length === 1) {
        return Number(periodoA[0]) - Number(periodoB[0]);
      } else if (periodoA.length === 1 && periodoB.length === 2) {
        return -1;
      } else if (periodoA.length === 2 && periodoB.length === 1) {
        return 1;
      } else {
        const yearDiff = Number(periodoA[0]) - Number(periodoB[0]);
        if (yearDiff !== 0) {
          return yearDiff;
        } else {
          return Number(periodoA[1]) - Number(periodoB[1]);
        }
      }
    });
  }

  mapeoAntecedentesSocietarios(entidad: string): ElementoAntecedentesSocietarios[] { 
    const arrayEntidad: any[] = [];
    const indexEntidad = this.entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let entidadObject: any[] = [];
    if(indexEntidad >= 0){
      entidadObject = this.entidadesDerivadas[indexEntidad].items;
    }

    if(this.reportesDerivadosObj[entidad]){
      for (const [key, value] of Object.entries(this.reportesDerivadosObj[entidad])) {
        const objeto: any = value;
        let nombreSocio = ''; 
        const indexSocio = entidadObject.findIndex((elemento: any) => elemento.rut === key);
        if(indexSocio >= 0){
          const indexAtributo = entidadObject[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributo >= 0){
            nombreSocio = entidadObject[indexSocio].atributos[indexAtributo].value;
          }
        }
        const monto = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
        const objetoArray: ElementoAntecedentesSocietarios = {
          nombre: nombreSocio || '',
          rut: key || '',
          dicomM: monto,
          entidad: entidad
        }

        arrayEntidad.push(objetoArray);
      }

    }

    return arrayEntidad;
    
  }

  mapeosAntecedentesFinancieros(): void {
    const listadoBalances: any = [];

    if(this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances && this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.balanceManual?.reporte?.listadoBalances);
    }
    
    if(this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances && this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances.length > 0) {
      listadoBalances.push(...this.reportesPrincipalesObj?.preBalanceManual?.reporte?.listadoPreBalances);
    }    
    
    if(!listadoBalances || listadoBalances.length === 0){
      return;
    }

    const sortedBalances = listadoBalances.sort((a: any, b:any) => {
      const periodoA = a?.BalanceManualReporte?.Reporte?.periodo || '';
      const periodoB = b?.BalanceManualReporte?.Reporte?.periodo || '';
      const mesInicioA = a?.BalanceManualReporte?.Reporte?.mesInicio || 0;
      const mesInicioB = b?.BalanceManualReporte?.Reporte?.mesInicio || 0;
      const mesFinA = a?.BalanceManualReporte?.Reporte?.mesFin || 0;
      const mesFinB = b?.BalanceManualReporte?.Reporte?.mesFin || 0;

      if (periodoA === '' && periodoB === '') {
        return 0;
      } else if (periodoA === '') {
        return 1;
      } else if (periodoB === '') {
        return -1;
      }

      if (periodoA !== periodoB) {
        return parseInt(periodoB) - parseInt(periodoA);
      }

      if (mesInicioA === 1 && mesFinA === 12) {
        return -1;
      } else if (mesInicioB === 1 && mesFinB === 12) {
        return 1;
      }

      return mesFinB - mesFinA;
    });

    this.antecedentesFinancieros = new Object() as AntecedentesFinancieros;
    this.antecedentesFinancieros.anio = [];
    this.antecedentesFinancieros.tipo = [];
    this.antecedentesFinancieros.periodoInicial = [];
    this.antecedentesFinancieros.periodoFinal = [];
    this.antecedentesFinancieros.moneda = [];
    this.antecedentesFinancieros.body = [
      {text: "Ingresos", values: []},
      {text: "EBITDA", values: []},
      {text: "Resultado Ejercicio", values: []},
      {text: "Activos", values: []},
      {text: "Pasivos", values: []},
      {text: "Patrimonio", values: []},
      {text: "Existencias", values: []},
      {text: "Capital de Trabajo", values: []},
      {text: "Liquidez Corriente (Veces)", values: []},
      {text: "Test Acido (Veces)", values: []},
      {text: "Leverage (Veces)", values: []},
      {text: "Leverage S. Anticipo (Veces)", values: []},
      {text: "Leverage Financiero (Veces)", values: []},
      {text: "DF/EBITDA (Veces)", values: []},
      {text: "EBITDA/GF (Veces)", values: []},
      {text: "Rentabilidad Neta (%)", values: []},
      {text: "Rentabilidad Operacional (%)", values: []},
      {text: "ROE (%)", values: []},
      {text: "ROA (%)", values: []},
      {text: "Permanencia CxC (Días)", values: []},
      {text: "Permanencia CxP (Días)", values: []}
    ];

    const balancesExtraidos: any[] = [];

    for (const balance of sortedBalances) {
      // Mapear los campos y agregarlos a las variables correspondientes
      if(balance?.BalanceManualReporte?.Reporte?.tipo === 'balance' && 
          balance?.BalanceManualReporte?.Reporte?.estado === 'closed' &&
          balance?.BalanceManualReporte?.Reporte?.periodo &&
          balance?.BalanceManualReporte?.Reporte?.mesInicio &&
          balance?.BalanceManualReporte?.Reporte?.mesFin
        ) {
          balancesExtraidos.push(balance?.BalanceManualReporte?.Reporte);
      }
    }

    const prebalances = sortedBalances.filter((e: any) => e.BalanceManualReporte?.Reporte?.tipo === 'prebalance'); // se valida que exista por lo menos un prebalance
    if(prebalances && prebalances.length > 0){
      for (const prebalance of prebalances) {
        if(prebalance && prebalance?.BalanceManualReporte?.Reporte){
          if(balancesExtraidos.length >= 0) {  // si no hay nada mas se agrega directamente
            // si hay validar que no exista un balance con el mismo periodo
            const balance = balancesExtraidos.find((e: any) => e.periodo == prebalance?.BalanceManualReporte?.Reporte?.periodo);
            if(!balance){
              balancesExtraidos.push(prebalance?.BalanceManualReporte?.Reporte);
            }
          } else {
            balancesExtraidos.push(prebalance?.BalanceManualReporte?.Reporte);
          }
        }
      }
    }

    if(balancesExtraidos.length > 0){
      // ordenar balances por periodo, mesInicio y mesFin desc
      balancesExtraidos.sort((a: any, b:any) => {
        const periodoA = a?.periodo || '';
        const periodoB = b?.periodo || '';
        const mesInicioA = a?.mesInicio || 0;
        const mesInicioB = b?.mesInicio || 0;
        const mesFinA = a?.mesFin || 0;
        const mesFinB = b?.mesFin || 0;
  
        if (periodoA === '' && periodoB === '') {
          return 0;
        } else if (periodoA === '') {
          return 1;
        } else if (periodoB === '') {
          return -1;
        }
  
        if (periodoA !== periodoB) {
          return parseInt(periodoB) - parseInt(periodoA);
        }
  
        if (mesInicioA === 1 && mesFinA === 12) {
          return -1;
        } else if (mesInicioB === 1 && mesFinB === 12) {
          return 1;
        }
  
        return mesFinB - mesFinA;
      });
      // validar si son mas de 3 balances, solo se deben mostrar los 3 mas recientes
      if(balancesExtraidos.length > 3){
        balancesExtraidos.splice(3, balancesExtraidos.length - 3);
      }
    }

    this.addBalancesAutomaticos(balancesExtraidos);
  }

  addBalancesAutomaticos(balancesExtraidos: any[]): void {
    const F22 = this.obtenerDatosF22();
    if(F22 && F22.length > 0){ // se agregan los datos desde f22
        let seAgregronBalances = false;
        for(const balanceAutomatico of F22) {
            const balance = balancesExtraidos.find((e: any) => e.tipo != 'automatico' && e.periodo == balanceAutomatico?.calculado?.periodo);
            if(!balance){
                balancesExtraidos.push(balanceAutomatico);
                seAgregronBalances = true;
            }
        }

        if(seAgregronBalances){
            balancesExtraidos.sort((a: any, b:any) => {
                const periodoA = a?.tipo == 'automatico' ? a?.calculado?.periodo : a?.periodo || '';
                const periodoB = b?.tipo == 'automatico' ? b?.calculado?.periodo : b?.periodo || '';
            
                if (periodoA === '' && periodoB === '') {
                    return 0;
                } else if (periodoA === '') {
                    return 1;
                } else if (periodoB === '') {
                    return -1;
                }
            
                return parseInt(periodoB) - parseInt(periodoA);
            });

            if(balancesExtraidos.length > 3){
                balancesExtraidos.splice(3, balancesExtraidos.length - 3);
            }
        }
    }

    // guardar balances
    for (const balance of balancesExtraidos) {
        if(balance.tipo) {
            if(balance.tipo != 'automatico') {
                this.guardarBalance(balance);
            } else {
                this.guardarBalanceAutomatico(balance);
            }
        }
    }
  }

  guardarBalanceAutomatico(reporte: any): void {
    if(this.antecedentesFinancieros && this.antecedentesFinancieros?.anio.length < 3 && 
      this.antecedentesFinancieros?.periodoInicial.length < 3 && 
      this.antecedentesFinancieros?.periodoFinal.length < 3){

        this.antecedentesFinancieros.anio.push(reporte?.calculado?.periodo || '');
        this.antecedentesFinancieros.tipo.push(reporte.tipo);// reporte.tipo  = 'Balance Automático' // (reporte?.tipo.charAt(0).toUpperCase() + reporte?.tipo.slice(1)) : '');
        this.antecedentesFinancieros.periodoInicial.push("1");
        this.antecedentesFinancieros.periodoFinal.push("12");

        const moneda = reporte?.calculado?.moneda && reporte?.calculado?.moneda.toUpperCase() == 'PESOS' ? 'M$' : 
        reporte?.calculado?.moneda && reporte?.calculado?.moneda.toUpperCase() == 'DOLARES' ? 'MUS$' : '';

        this.antecedentesFinancieros.moneda.push(moneda || '');

        this.agregarBodyAntecedentesFinancieros('Ingresos', this.utilsService.toMiles(reporte?.calculado?.ingresosdelaExplotacion));
        this.agregarBodyAntecedentesFinancieros('EBITDA', this.utilsService.toMiles(reporte?.calculado?.ebitda));
        this.agregarBodyAntecedentesFinancieros('Resultado Ejercicio', this.utilsService.toMiles(reporte?.calculado?.utilidadneta));
        this.agregarBodyAntecedentesFinancieros('Activos', this.utilsService.toMiles(reporte?.calculado?.totalactivos));
        this.agregarBodyAntecedentesFinancieros('Pasivos', this.utilsService.toMiles(reporte?.calculado?.totalpasivos));
        this.agregarBodyAntecedentesFinancieros('Patrimonio', this.utilsService.toMiles(reporte?.calculado?.totalpatrimonio));
        this.agregarBodyAntecedentesFinancieros('Existencias', this.utilsService.toMiles(reporte?.calculado?.inventario));
        this.agregarBodyAntecedentesFinancieros('Capital de Trabajo', this.utilsService.toMiles(reporte?.calculado?.capitaldeTrabajo));
        this.agregarBodyAntecedentesFinancieros('Liquidez Corriente (Veces)', reporte?.calculado?.razonCorriente || reporte?.calculado?.razonCorriente == 0 ? reporte?.calculado?.razonCorriente : '');
        this.agregarBodyAntecedentesFinancieros('Test Acido (Veces)', reporte?.calculado?.testAcido || reporte?.calculado?.testAcido == 0 ? reporte?.calculado?.testAcido : '');
        this.agregarBodyAntecedentesFinancieros('Leverage (Veces)', reporte?.calculado?.leverage || reporte?.calculado?.leverage == 0 ? reporte?.calculado?.leverage : '');
        this.agregarBodyAntecedentesFinancieros('Leverage S. Anticipo (Veces)', '');
        this.agregarBodyAntecedentesFinancieros('Leverage Financiero (Veces)', '');
        this.agregarBodyAntecedentesFinancieros('DF/EBITDA (Veces)', '');
        this.agregarBodyAntecedentesFinancieros('EBITDA/GF (Veces)', reporte?.calculado?.ebitdagf || reporte?.calculado?.ebitdagf == 0 ? reporte?.calculado?.ebitdagf : '');
        this.agregarBodyAntecedentesFinancieros('Rentabilidad Neta (%)', reporte?.calculado?.utilidadVentas || reporte?.calculado?.utilidadVentas == 0 ? reporte?.calculado?.utilidadVentas : '');
        this.agregarBodyAntecedentesFinancieros('Rentabilidad Operacional (%)', reporte?.calculado?.resultadooperacionaLporcentaje || reporte?.calculado?.resultadooperacionaLporcentaje == 0 ? reporte?.calculado?.resultadooperacionaLporcentaje : '');
        this.agregarBodyAntecedentesFinancieros('ROE (%)', reporte?.calculado?.roe || reporte?.calculado?.roe == 0 ? reporte?.calculado?.roe : '');
        this.agregarBodyAntecedentesFinancieros('ROA (%)', reporte?.calculado?.roa || reporte?.calculado?.roa == 0 ? reporte?.calculado?.roa : '');
        this.agregarBodyAntecedentesFinancieros('Permanencia CxC (Días)', '');
        this.agregarBodyAntecedentesFinancieros('Permanencia CxP (Días)', '');
    }
  }

  guardarBalance(reporte: any): void {
    if(this.antecedentesFinancieros && this.antecedentesFinancieros?.anio.length < 3 && 
      this.antecedentesFinancieros?.periodoInicial.length < 3 && 
      this.antecedentesFinancieros?.periodoFinal.length < 3){

        this.antecedentesFinancieros.anio.push(reporte?.periodo || '');
        this.antecedentesFinancieros.tipo.push(reporte?.tipo ? (reporte?.tipo.charAt(0).toUpperCase() + reporte?.tipo.slice(1)) : '');
        this.antecedentesFinancieros.periodoInicial.push(reporte?.mesInicio || '');
        this.antecedentesFinancieros.periodoFinal.push(reporte?.mesFin || '');
        this.antecedentesFinancieros.moneda.push(reporte?.moneda || '');

        this.agregarBodyAntecedentesFinancieros('Ingresos', reporte?.balance?.ingresodeactiviadesordinariaser || reporte?.balance?.ingresodeactiviadesordinariaser == 0 ? reporte?.balance?.ingresodeactiviadesordinariaser : '');
        this.agregarBodyAntecedentesFinancieros('EBITDA', reporte?.balance?.ebitda || reporte?.balance?.ebitda == 0 ? reporte?.balance?.ebitda : '');
        this.agregarBodyAntecedentesFinancieros('Resultado Ejercicio', reporte?.balance?.gananciaperdida || reporte?.balance?.gananciaperdida == 0 ? reporte?.balance?.gananciaperdida : '');
        this.agregarBodyAntecedentesFinancieros('Activos', reporte?.balance?.activo || reporte?.balance?.activo == 0 ? reporte?.balance?.activo : '');
        this.agregarBodyAntecedentesFinancieros('Pasivos', reporte?.balance?.pasivo || reporte?.balance?.pasivo == 0 ? reporte?.balance?.pasivo : '');
        this.agregarBodyAntecedentesFinancieros('Patrimonio', reporte?.balance?.patrimonio || reporte?.balance?.patrimonio == 0 ? reporte?.balance?.patrimonio : '');
        this.agregarBodyAntecedentesFinancieros('Existencias', reporte?.balance?.inventarios || reporte?.balance?.inventarios == 0 ? reporte?.balance?.inventariosac : '');
        this.agregarBodyAntecedentesFinancieros('Capital de Trabajo', reporte?.balance?.capitaldetrabajo || reporte?.balance?.capitaldetrabajo == 0 ? reporte?.balance?.capitaldetrabajo : '');
        this.agregarBodyAntecedentesFinancieros('Liquidez Corriente (Veces)', reporte?.balance?.razoncorriente || reporte?.balance?.razoncorriente == 0 ? reporte?.balance?.razoncorriente : '');
        this.agregarBodyAntecedentesFinancieros('Test Acido (Veces)', reporte?.balance?.pruebaacida || reporte?.balance?.pruebaacida == 0 ? reporte?.balance?.pruebaacida : '');
        this.agregarBodyAntecedentesFinancieros('Leverage (Veces)', reporte?.balance?.leverage || reporte?.balance?.leverage == 0 ? reporte?.balance?.leverage : '');
        this.agregarBodyAntecedentesFinancieros('Leverage S. Anticipo (Veces)', reporte?.balance?.leveragesinanticipos || reporte?.balance?.leveragesinanticipos == 0 ? reporte?.balance?.leveragesinanticipos : '');
        this.agregarBodyAntecedentesFinancieros('Leverage Financiero (Veces)', reporte?.balance?.leveragefinanciero || reporte?.balance?.leveragefinanciero == 0 ? reporte?.balance?.leveragefinanciero : '');
        this.agregarBodyAntecedentesFinancieros('DF/EBITDA (Veces)', reporte?.balance?.dfebitda || reporte?.balance?.dfebitda == 0 ? reporte?.balance?.dfebitda : '');
        this.agregarBodyAntecedentesFinancieros('EBITDA/GF (Veces)', reporte?.balance?.ebitdagf || reporte?.balance?.ebitdagf == 0 ? reporte?.balance?.ebitdagf : '');
        this.agregarBodyAntecedentesFinancieros('Rentabilidad Neta (%)', reporte?.balance?.rentabilidadingresos || reporte?.balance?.rentabilidadingresos == 0 ? reporte?.balance?.rentabilidadingresos : '');
        this.agregarBodyAntecedentesFinancieros('Rentabilidad Operacional (%)', reporte?.balance?.resultadooperacionalporcentajedelosingresos || reporte?.balance?.resultadooperacionalporcentajedelosingresos == 0 ? reporte?.balance?.resultadooperacionalporcentajedelosingresos : '');
        this.agregarBodyAntecedentesFinancieros('ROE (%)', reporte?.balance?.rentabilidadpatrimonial || reporte?.balance?.rentabilidadpatrimonial == 0 ? reporte?.balance?.rentabilidadpatrimonial : '');
        this.agregarBodyAntecedentesFinancieros('ROA (%)', reporte?.balance?.rentabilidadactivos || reporte?.balance?.rentabilidadactivos == 0 ? reporte?.balance?.rentabilidadactivos : '');
        this.agregarBodyAntecedentesFinancieros('Permanencia CxC (Días)', reporte?.balance?.permanenciactaxcobrar || reporte?.balance?.permanenciactaxcobrar == 0 ? reporte?.balance?.permanenciactaxcobrar : '');
        this.agregarBodyAntecedentesFinancieros('Permanencia CxP (Días)', reporte?.balance?.permanenciactaxpagar || reporte?.balance?.permanenciactaxpagar == 0 ? reporte?.balance?.permanenciactaxpagar : '');
    }
  }

  agregarBodyAntecedentesFinancieros(text: string, valor: any): void {
    if(this.antecedentesFinancieros?.body && this.antecedentesFinancieros.body.length > 0){
      const index = this.antecedentesFinancieros.body.findIndex((elemento: any) => elemento.text === text);
      if(index >= 0) {
        this.antecedentesFinancieros.body[index].values.push(valor);
      }
    }
  }

  mapeoDeudaFacturada(): void {
    if(this.antecedentesInternos && Object.keys(this.antecedentesInternos).length > 0 && this.antecedentesInternos?.deudaFacturada){
      const reporteDsConstrumart = this.reportesPrincipalesObj?.dsCliente?.reporte?.DSConstrumart;
      const deudaVencida = reporteDsConstrumart && reporteDsConstrumart.length > 0 ? reporteDsConstrumart.find((e: any) => e.deudaVencida) : null;

      if(deudaVencida?.deudaVencida && deudaVencida?.deudaVencida.length > 0){
        deudaVencida.deudaVencida.sort((a: any, b: any) => {
          const r11A = parseFloat(a.r11);
          const r11B = parseFloat(b.r11);
          return r11A - r11B;
        });
        this.antecedentesInternos.deudaFacturada.tramo = [];
        this.antecedentesInternos.deudaFacturada.totalM = new Object() as TotalTramo;
        this.antecedentesInternos.deudaFacturada.totalFacturadoM = '';
        for(const [index, elemento] of deudaVencida.deudaVencida.entries()){
          this.antecedentesInternos?.deudaFacturada.tramo.push({
            limInf: elemento.r11 || '',
            limSup: deudaVencida?.deudaVencida?.[index + 1]?.r11 ? Number(deudaVencida?.deudaVencida?.[index + 1]?.r11) - 1 : '',
            vencidoM: elemento?.vend || elemento?.vend == 0 ? this.utilsService.toMiles(Number(elemento?.vend)) : '',
            porVencerM: elemento?.vece || elemento?.vece == 0 ? this.utilsService.toMiles(Number(elemento?.vece)) : ''
          });
          
          this.antecedentesInternos.deudaFacturada.totalM = {
            vencidoM: Number(this.antecedentesInternos?.deudaFacturada?.totalM?.vencidoM || 0) + Number(this.utilsService.toMiles(elemento?.vend || 0)),
            porVencerM: Number(this.antecedentesInternos?.deudaFacturada?.totalM?.porVencerM || 0) + Number(this.utilsService.toMiles(elemento?.vece || 0))
          }
        }

        this.antecedentesInternos.deudaFacturada.totalFacturadoM = Number(this.antecedentesInternos?.deudaFacturada?.totalM?.vencidoM || 0) + Number(this.antecedentesInternos?.deudaFacturada?.totalM?.porVencerM || 0);
        if(this.antecedentesInternos?.deudaFacturada?.tramo.length > 0 && this.antecedentesInternos?.deudaFacturada?.tramo?.[this.antecedentesInternos.deudaFacturada.tramo.length - 1]?.limSup === ''){
          this.antecedentesInternos.deudaFacturada.tramo[this.antecedentesInternos.deudaFacturada.tramo.length - 1].limInf = '>=' + this.antecedentesInternos.deudaFacturada.tramo[this.antecedentesInternos.deudaFacturada.tramo.length - 1].limInf; 
        }
      }
    }
  }

  getLineaContinental(): any {
    let reporteLineaNominada: any[] = this.reportesPrincipalesObj?.consultaSegurosContinental?.reporte?.ConsultaSegurosContinentalLineasNominadas || null;
    let reporteLineaInnominada: any[] = this.reportesPrincipalesObj?.consultaSegurosContinental?.reporte?.ConsultaSegurosContinentalLineasInnominadas || null;

    let respuesta: any = null;
    // validacion de lineas nominadas
    if(reporteLineaNominada && reporteLineaNominada.length > 0){
      reporteLineaNominada = reporteLineaNominada.filter((linea: any) => linea?.Estado && typeof(linea?.Estado) == 'string' && 
        linea?.Estado.toUpperCase().includes("ACTIVA")
      );

      // Sort reporteLineaNominada array based on FechaResolucion in descending order
      reporteLineaNominada = reporteLineaNominada.sort((a: { FechaResolucion?: string }, b: { FechaResolucion?: string }) => {
        const fechaResolucionA = a?.FechaResolucion || '';
        const fechaResolucionB = b?.FechaResolucion || '';
        return new Date(fechaResolucionB).getTime() - new Date(fechaResolucionA).getTime();
      });

      if(reporteLineaNominada.length > 0){
        respuesta = reporteLineaNominada[0];
      }

    }
    // validacion de lineas innominadas
    if(respuesta === null && reporteLineaInnominada && reporteLineaInnominada.length > 0){
      reporteLineaInnominada = reporteLineaInnominada.filter((linea: any) => linea?.Estado && typeof(linea?.Estado) == 'string' && 
        linea?.Estado.toUpperCase().includes("ACTIVA")
      );
      if(reporteLineaInnominada.length > 0){
        const vigencias: any [] = [];
        for(const element of reporteLineaInnominada){
          if(element?.Vigencias && element?.Vigencias.length > 0){
            vigencias.push(...element?.Vigencias);
          }
        }

        if(vigencias.length > 0) {
          const vigenciasActivas = vigencias.filter((vigencia: any) => vigencia?.Estado && typeof(vigencia?.Estado) == 'string' &&
            vigencia?.Estado.toUpperCase().includes("ACTIVA")
          );
          if(vigenciasActivas.length > 0){
            // Sort vigenciasActivas array based on FechaDesde in descending order
            vigenciasActivas.sort((a: { FechaDesde?: string }, b: { FechaDesde?: string }) => {
              const fechaDesdeA = a?.FechaDesde || '';
              const fechaDesdeB = b?.FechaDesde || '';
              return new Date(fechaDesdeB).getTime() - new Date(fechaDesdeA).getTime();
            });
            const object: any = {
              FechaDesde: vigenciasActivas[0]?.FechaDesde || '',
              FechaHasta: '',
              FechaResolucion: vigenciasActivas[0]?.FechaDesde || '',
              MtoSolicitado: 600,
              MtoAprobado: 600,
              Estado: vigenciasActivas[0]?.Estado || '',
              CondicionVenta: '',
              Observacion: ''
            }
            respuesta = object;
          }
        }
      }
    }

    return respuesta;
  }

  getLineaAvla(reporteLinea: any[]): any {
    let respuesta: any = null;
    if(reporteLinea && reporteLinea.length > 0) {
      const estadoAprobado = [
        "APROBADA",
        "APROBADA PARCIAL",
        "REDUCIDA",
        "REDUCIDA DE APROBADA PARCIAL"
      ];
      reporteLinea = reporteLinea.filter((linea: any) => linea?.stateDto?.name && typeof(linea?.stateDto?.name) == 'string' && 
        estadoAprobado.some(elemento => linea?.stateDto?.name.toUpperCase().includes(elemento))
      );
      
      // Sort reporteLinea array based on createDateStr in descending order
      reporteLinea = reporteLinea.sort((a: { createDateStr?: string }, b: { createDateStr?: string }) => {
        const fechaResolucionA = a?.createDateStr || '';
        const fechaResolucionB = b?.createDateStr || '';
        return new Date(fechaResolucionB).getTime() - new Date(fechaResolucionA).getTime();
      });
      
      if(reporteLinea.length > 0) {
        respuesta = reporteLinea[0];
      }
    }

    return respuesta;
  }

  mapeosSeguroCredito(): void {
    this.seguroCredito = [];
    // Parte continental
    const lineaNominadaContinental = this.getLineaContinental();
    if(lineaNominadaContinental){
      const object = new Object() as SeguroCredito;
      object.ciaSeguro = lineaNominadaContinental ? 'Continental' : '';
      object.vigenciaDesde = lineaNominadaContinental?.FechaDesde || '';
      object.vigenciaHasta = lineaNominadaContinental?.FechaHasta || '';
      object.fechaResolucion = lineaNominadaContinental?.FechaResolucion || '';
      object.montoSolicitadoUF = lineaNominadaContinental?.MtoSolicitado || lineaNominadaContinental?.MtoSolicitado == 0 ? lineaNominadaContinental?.MtoSolicitado : '';
      object.montoAprobadoUF = lineaNominadaContinental?.MtoAprobado || lineaNominadaContinental?.MtoAprobado == 0 ? lineaNominadaContinental?.MtoAprobado : '';
      object.estado = lineaNominadaContinental?.Estado || '';
      object.condicionVenta = lineaNominadaContinental?.CondicionVenta || '';
      object.obs = lineaNominadaContinental?.Observacion || '';
      
      this.seguroCredito.push(object);
    }
    
    // Parte Avla
    const lineaAvla: any = this.getLineaAvla(this.reportesPrincipalesObj?.consultaSegurosAVLA?.reporte?.ConsultaSegurosAVLALineas);
    if(lineaAvla){
      const object = new Object() as SeguroCredito;
      let observacion = '';
      if(lineaAvla?.comments && lineaAvla?.comments.length > 0) {
        observacion = lineaAvla?.comments.map((comentario: any) => comentario?.comments?.commentsToEnsured).join(' | ');
      }

      object.ciaSeguro = lineaAvla ? 'Avla' : '';
      object.vigenciaDesde = lineaAvla?.feStartOfValidityStr || '';
      object.vigenciaHasta = lineaAvla?.feEndOfValidityStr || '';
      object.fechaResolucion = lineaAvla?.createDateStr || '';
      object.montoSolicitadoUF = lineaAvla?.requestedAmount || lineaAvla?.requestedAmount == 0 ? lineaAvla?.requestedAmount : '';
      object.montoAprobadoUF = lineaAvla?.approvedAmount || lineaAvla?.approvedAmount == 0 ? lineaAvla?.approvedAmount : '';
      object.estado = lineaAvla?.stateDto?.name || '';
      object.condicionVenta = '';
      object.obs = observacion || '';

      this.seguroCredito.push(object);
    }

  }

  mapeoReportes(): void {

    this.llenadoBase([
      'antecedentesInternos',
      'antecedentesFinancieros',
      'antecedentesInternosCmt'
    ]);

    const F22Limpio = this.obtenerDatosF22();
    const F29Limpio = this.obtenerDatosF29();

    this.mapeoAntecedentesCliente();
    this.antecedentesSocietarios = new Object() as AntecedentesSocietarios;
    this.antecedentesSocietarios.socios = this.mapeoAntecedentesSocietarios('socios');
    this.antecedentesSocietarios.sociedades = this.mapeoAntecedentesSocietarios('sociedades');
    this.mapeosSeguroCredito();
    this.mapeoAntecedentesInternosLineasCredito();
    this.mapeoAntecendentesInternosCmt();
    this.mapeosAntecedentesFinancieros();
  
    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.showReportes = true;
  }

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};
    const f22Manual = this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes || []; // validar *

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }
    f22Manual.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if(element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  obtenerDatosF29(): any[] {
    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    return F29Limpio;
  }

  abrirModal(rut: any, entidad: string): void{
    this.reportesSeleccionado = {};
    if(rut && entidad)
      this.reportesSeleccionado = this.reportesDerivadosObj?.[entidad]?.[rut] || {};

    if(this.reportesSeleccionado && Object.keys(this.reportesSeleccionado).length > 0){
      this.rutSeleccionado = rut;
      this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
      this.mapeoPoderJudicial();
      this.mapeoVehiculos();
      this.mapeoBienesRaices();
      this.mapeoSbif();
      this.validaObjetos();
      this.plantillaAntecedentes = '';
      this.modalDefault = true;

      this.modalService.open();
    }

  }

  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitular();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rut;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    this.modalService.open();
  }

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoVehiculos(): void{
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 

  }

  validateArray(array: any): boolean {
    if(array && array.length > 0){
      return true;
    }
    return false;
  }

  llenadoBase(elementos: string[]): void {
    if(elementos.includes('antecedentesInternos'))
      this.llenadoAntecedentesInternos();

    if(elementos.includes('antecedentesFinancieros'))
      this.llenadoAntecedentesFinancieros();

    if(elementos.includes('antecedentesInternosCmt'))
      this.llenadoAntecedentesInternosCmt();
  }

  llenadoAntecedentesInternos(): void {
    this.antecedentesInternos = {
      lineasCredito: {
        lineaCreditoAseguradaM: '',
        lineaCreditoTemporalM: '',
        totalAseguradoM: '',
        lineaCreditoCMTM: '',
        lineaCreditoPuntualM: '',
        fechaExpiracionTemporal: '',
        fechaExpiracionPuntual: '',
        fechaExpiracionLineaCreditoCMT: '',
        dso: '',
        pppMensual: '',
        ppp3Meses: '',
        condicionPago: ''
      },
      deudaFacturada: {
        tramo: [
          {
            limInf: 0,
            limSup: 10,
            vencidoM: '',
            porVencerM: ''
          },
          {
            limInf: 11,
            limSup: 20,
            vencidoM: '',
            porVencerM: ''
          },
          {
            limInf: 21,
            limSup: 30,
            vencidoM: '',
            porVencerM: ''
          },
          {
            limInf: 31,
            limSup: 40,
            vencidoM: '',
            porVencerM: ''
          },
          {
            limInf: 41,
            limSup: 50,
            vencidoM: '',
            porVencerM: ''
          },
          {
            limInf: '>=51',
            limSup: '',
            vencidoM: '',
            porVencerM: ''
          }
        ],
        totalM: {
          vencidoM: '',
          porVencerM: ''
        },
        totalFacturadoM: ''
      }
    };
  }

  llenadoAntecedentesFinancieros(): void {
    this.antecedentesFinancieros = {
      anio: [],
      tipo: [],
      periodoInicial: [],
      periodoFinal: [],
      moneda: [],
      body: [
        {text: "Ingresos", values: []},
        {text: "EBITDA", values: []},
        {text: "Resultado Ejercicio", values: []},
        {text: "Activos", values: []},
        {text: "Pasivos", values: []},
        {text: "Patrimonio", values: []},
        {text: "Existencias", values: []},
        {text: "Capital de Trabajo", values: []},
        {text: "Liquidez Corriente (Veces)", values: []},
        {text: "Test Acido (Veces)", values: []},
        {text: "Leverage (Veces)", values: []},
        {text: "Leverage S. Anticipo (Veces)", values: []},
        {text: "Leverage Financiero (Veces)", values: []},
        {text: "DF/EBITDA (Veces)", values: []},
        {text: "EBITDA/GF (Veces)", values: []},
        {text: "Rentabilidad Neta (%)", values: []},
        {text: "Rentabilidad Operacional (%)", values: []},
        {text: "ROE (%)", values: []},
        {text: "ROA (%)", values: []},
        {text: "Permanencia CxC (Días)", values: []},
        {text: "Permanencia CxP (Días)", values: []}
      ]
    };
  }

  llenadoAntecedentesInternosCmt(): void {
    this.antecedentesInternosCmt = {
      body: [
        {
          periodo: '',
          ventasM: '',
          cuentasPorCobrarM: '',
          pagosM: ''
        }
      ],
      acumulado: {
        ventasM: '',
        cuentasPorCobrarM: '',
        pagosM: ''
      },
      promedio: {
        ventasM: '',
        cuentasPorCobrarM: '',
        pagosM: ''
      }
    };
  }

  getAbbreviatedMonthName(month: any): string {
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];

    if (month && month >= 1 && month <= 12) {
      return months[month - 1];
    } else {
      return '';
    }
  }

  toMiles(value: any): string | number {
    if(value || value == 0) {
      return Math.round(Number(value) / 1000);
    }
    return '';
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  guardarFicha(): void {
    if(!(this.valorUFActual > 0)){
      this.alertService.error('Solicitud de Crédito - No se logró recuperar un valor válido de la uf.');
      return;
    }

    const ficha: any = {
      ficha: {
        rut: this.rut,
        consolidadoTitular: this.consolidadoTitular,
        antecedentesCliente: this.antecedentesCliente,
        antecedentesSocietarios: this.antecedentesSocietarios,
        seguroCredito: this.seguroCredito,
        antecedentesInternos: this.antecedentesInternos,
        antecedentesInternosCmt: this.antecedentesInternosCmt,
        antecedentesFinancieros: this.antecedentesFinancieros,
        valorUFActual: this.valorUFActual,
      }
    }

    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  validateNumberNoDecimal(text: string | undefined): boolean {
    if(text) {
      if(this.numberNoDecimal.includes(text))
        return true;
    }
    return false;
  }

  formatterFecha(fechaEntrada: string): string {
    let fechaFormatted = '';
    if(fechaEntrada.includes("/")) { // formato dd/mm/yyyy
      const dateArray = fechaEntrada.split("/"); 
      fechaFormatted = dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2];
    } else if (fechaEntrada.includes("T") && fechaEntrada.includes("Z")) { // formato yyyy-mm-ddT00:00:00.000Z
      const dateArray = fechaEntrada.split("T")[0].split("-");
      fechaFormatted = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    } else if (fechaEntrada.includes("-")) { // formato yyyy-mm-dd
      const dateArray = fechaEntrada.split("-");
      fechaFormatted = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    }
    return fechaFormatted;
  }

}
