import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/components/_alert';
import { environment as env } from 'src/environments/environment';
import { rutValidate } from 'rut-helpers';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CesionFacturasService } from 'src/app/shared/services/cesion-facturas.service';
import { RUT } from 'src/app/shared/utils/rut';
import { AsincronoService } from 'src/app/shared/services/asincrono.service';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';
import { ModalCredencialesService } from 'src/app/shared/services/modal-credenciales.service';
import { SessionService } from '../../shared/services/session.service';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';

@Component({
  selector: 'app-cesion-facturas',
  templateUrl: './cesion-facturas.component.html',
  styleUrls: ['./cesion-facturas.component.scss']
})
export class CesionFacturasComponent implements OnInit {
  private access: string[] = [
    ESystemProfileList.WEB,
    ESystemAccess.REPORTE_CESION_FACTURAS,
    ESystemAccess.REPORTE_CESION_FACTURAS_BUSCAR
  ];
  public rut: string = '';
  public currentPage = 0;
  public currentItem = 10;

  public fechaInicio: string = '';
  public fechaFin: string = '';
  public rutCedente: string = '';
  public rutDeudor: string = '';
  public rutVendedor: string = '';
  
  public hasUserAccess = false;
  public today: string = '';
  public showReport: boolean = false;
  
  public estadoDocumento: string = ''; // estado documento
  public estadoDocumentoOptions: any[] = [{name: "", displayName: "Todos"}];
  public estadoCesion: string = ''; // estado cesion
  public estadoCesionOptions: any[] = [{name: "", displayName: "Todos"}];
  public estadoDocumentoNc: string = ''; // estado cesion
  public estadoNotaCreditoOptions: any[] = [{name: "", displayName: "Todos"}];

  public cantidadRegistros: number = 0; // cantidad de registros que se obtienen de la consulta
  public cantidadPaginas: number = 0; // cantidad de paginas que se muestran, es el calculo entre la cantidad de registros y el total de registros que se muestran por pagina
  public registrosFiltrados: number = 4; // cantidad de registros que se muestran, es el calculo entre la cantidad de paginas y el total de registros que se muestran por pagina
  public paginasActuales: {pagina: number, response: any}[] = [];
  public paginasActualesErrores: {pagina: number, error: string}[] = [];
  public paginaActual: any[] = []; // data que se obtiene de la consulta

  public filtrosUltimaBusqueda: any = {};
  public ultimaPagina: number = 0;

  public erroresCrear: any[] = [];
  public responsesCrear: any[] = [];

  public itemCredencialSelected: any = null; 
  public itemCredencialSelectedType: string = '';
  public credsNoConfiguradas: boolean = false;
  public showModal: boolean = true;
  public showSpinnerEstados: boolean = false;

  constructor(
    public alertService: AlertService,
    private cesionFacturasService: CesionFacturasService,
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private spinner: NgxSpinnerService,
    private asyncService: AsincronoService,
    private usuariosService: UsuariosService,
    private credencialesService: CredencialesService,
    private modalService: ModalCredencialesService,
    private _sessionService: SessionService
  ) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.setToday();
    if (this._sessionService.getUserProfileList().includes(this.access[0]) && this._sessionService.getUserAccess().includes(this.access[1])
      && this._sessionService.getUserAccess().includes(this.access[2])) {
      this.hasUserAccess = true;
      this.showReport = true;
      this.getEstados();
      this.getRutOrg();
    } else {
      this.hasUserAccess = false;
    }
  }

  getEstados(): void {
    this.showSpinnerEstados = true;
    this.cesionFacturasService.obtenerEstados().subscribe(
      (data) => {
        const estadosCesion = data?.estadoCesion || [];
        const estadosDocumento = data?.estadoDocumento || [];
        const estadosNotaCredito = data?.estadoDocumentoNc || [];
        estadosCesion.unshift({name: '', displayName: 'Todos'});
        estadosDocumento.unshift({name: '', displayName: 'Todos'});
        estadosNotaCredito.unshift({name: '', displayName: 'Todos'});
        this.estadoDocumentoOptions = estadosDocumento;
        this.estadoCesionOptions = estadosCesion;
        this.estadoNotaCreditoOptions = estadosNotaCredito;
        this.showSpinnerEstados = false;
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener los estados');
        this.showSpinnerEstados = false;
      }
    );
  }

  getRutOrg(): void {
    this.spinner.show();
    this.usuariosService.getOrganizationDetails().subscribe(
      (data) => {
        this.spinner.hide();
        if(data?.rut && data?.rut != '') {
          this.rut = data.rut || '';
          this.fechaInicio = this.getFecha90Dias();
          this.fechaFin = this.getFechaActualFiltro();
          this.filtrosUltimaBusqueda = {};
          this.filtrosUltimaBusqueda = this.obtenerFiltros();
          this.paginasActuales = [];
          this.obtenerCantidadElementos(false, 1, true);
        } else {
          this.showReport = false;
          this.alertService.error('No se ha podido obtener el rut de la organización');
        }
      },
      ({ error }) => {
        this.showReport = false;
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener los datos de la organización');
        this.spinner.hide();
      }
    );
  }

  getFechaActualFiltro(): string {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = fechaActual.getMonth() + 1; // Añadimos 1 para normalizar el valor del mes
    const dia = fechaActual.getDate();

    const mesFormateado = mes.toString().padStart(2, '0');
    const diaFormateado = dia.toString().padStart(2, '0');

    const fechaFormateada = `${anio}-${mesFormateado}-${diaFormateado}`;
    return fechaFormateada;
  }

  getFecha90Dias(): string {
    const fechaActual = new Date();
    const milisegundosPorDia = 24 * 60 * 60 * 1000; // 1 día en milisegundos
    const fechaHace90Dias = new Date(fechaActual.getTime() - 90 * milisegundosPorDia);
    const anio90Dias = fechaHace90Dias.getFullYear();
    const mes90Dias = fechaHace90Dias.getMonth() + 1;
    const dia90Dias = fechaHace90Dias.getDate();

    const mesFormateado = mes90Dias.toString().padStart(2, '0');
    const diaFormateado = dia90Dias.toString().padStart(2, '0');

    const fechaFormateada90Dias = `${anio90Dias}-${mesFormateado}-${diaFormateado}`;
    return fechaFormateada90Dias;
  }

  obtenerFiltros(refresh: boolean = false): any {
    if(refresh) {
      return this.filtrosUltimaBusqueda;
    }
    const filtros: any = {};
    if(this.fechaInicio) filtros['fechaInicio'] = this.fechaInicio;
    if(this.fechaFin) filtros['fechaFin'] = this.fechaFin;
    if(this.rutCedente) filtros['rutCedente'] = RUT.applyBackendFormat(this.rutCedente);
    if(this.rutDeudor) filtros['rutDeudor'] = RUT.applyBackendFormat(this.rutDeudor);
    if(this.rutVendedor) filtros['rutVendedor'] = RUT.applyBackendFormat(this.rutVendedor);
    if(this.estadoDocumento) filtros['estadoDocumento'] = this.estadoDocumento;
    if(this.estadoCesion) filtros['estadoCesion'] = this.estadoCesion;
    if(this.estadoDocumentoNc) filtros['estadoDocumentoNc'] = this.estadoDocumentoNc;
    return filtros;
  }

  obtenerCantidadElementos(refresh: boolean = false, pagina: number = 1, primeraBusqueda: boolean = false): void {
    const filtros = this.obtenerFiltros(refresh);
    this.spinner.show();
    this.cesionFacturasService.obtenerPagina(this.rut, pagina, this.currentItem, filtros).subscribe(
      (data: any) => {
        this.setResponse(data, pagina);
        this.cantidadRegistros = data?.totalRegistros || 0;
        if(this.cantidadRegistros > 0){
          this.obtenerCantidadPaginasFlujo(refresh, pagina);
        } else {
          this.paginaActual = [];
          this.alertService.error('No se encontraron registros');
          this.spinner.hide();
          if(primeraBusqueda === true) { // la primera ejecucion se realiza al llegar a la pagina sino hay registros se deben crear
            this.actualizar();
          }
        }
      },
      ({ error }) => {
        this.alertService.error(error?.message || error?.error?.message || 'Ha ocurrido un error al obtener los registros');
        this.spinner.hide();
    });
  }

  obtenerCantidadPaginasFlujo(refresh: boolean = false, pagina: number = 1): void {
    if(this.cantidadRegistros > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistros / this.currentItem);
    }
    if(this.cantidadPaginas > 0) {
      this.getPage(pagina, refresh);
    } else {
      this.spinner.hide();
    }

  }

  getPage(event: number, refresh: boolean = false): void {
    this.paginasActualesErrores = [];

    if(this.cantidadRegistros > 0) {
      const elemento: any = this.paginasActuales.find(e => e.pagina == (event))

      if(!elemento){  
        if(event == 1){
          const paginas: number[] = [];
          for(let i = 0; i < 3; i++){
            const pagina = event + i;
            if(pagina > 0 && pagina <= this.cantidadPaginas){
              paginas.push(pagina);
            }
          }
          this.obtenerPaginas(paginas, event, refresh);
        } else if(event > 1 && event <= this.cantidadPaginas) {
          const pagina: number = event;
          const paginas: number[] = [pagina - 1, pagina];
          if(event + 1 <= this.cantidadPaginas)
            paginas.push(pagina + 1);
          this.obtenerPaginas(paginas, event, refresh);
        }
      } else {
        this.paginaActual = elemento?.response || [undefined];
        this.obtenerCantRegistrosFiltrados(event);
        this.spinner.hide();
      }
    } else {
      this.spinner.hide();
    }

    this.currentPage = event; // se actualiza la pagina actual
  }

  obtenerCantRegistrosFiltrados(paginaActual: number): void {
    if(this.cantidadPaginas > 0) {
      this.registrosFiltrados = ((paginaActual - 1) * this.currentItem) + this.paginaActual.length;
    }
  }

  obtenerCantidadPaginas(cantidadRegistros: number): void {
    if(cantidadRegistros || cantidadRegistros == 0) {
      this.cantidadRegistros = cantidadRegistros;
    }
    if(this.cantidadRegistros > 0) {
      this.cantidadPaginas = Math.ceil(this.cantidadRegistros / this.currentItem);
    }
  }

  setError(pagina: number, error: string): void {
    this.paginasActualesErrores.push({
      pagina: pagina,
      error: error
    })
  }

  setResponse(response: any, pagina: number): void {
    this.paginasActuales.push({
      pagina: pagina,
      response: response?.registros || null
    });
    this.obtenerCantidadPaginas(response?.total); // actualiza la cantidad de paginas por si hay cambios
  }

  getServicePagina(pagina: number, cantidadElementos: number, filtros: any): any {
    return this.cesionFacturasService.obtenerPagina(this.rut, pagina, cantidadElementos, filtros)
      .pipe(
        map(resp => {
          this.setResponse(resp, pagina);
        })
      )
      .pipe(
        catchError((error) => (this.setError(pagina, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  obtenerPaginas(paginas:  number[], paginaActual: number, refresh: boolean = false): void {
    const apiServices: any = [];
    if(this.cantidadPaginas > 0 && paginas.length > 0) {
      paginas.forEach(pagina => {
        if(pagina > 0 && pagina <= this.cantidadPaginas && !this.paginasActuales.some(e => e?.pagina == pagina)) {
          const filtros = this.obtenerFiltros(refresh);
          apiServices.push(
            {
              service: this.getServicePagina(pagina, this.currentItem, filtros),
              pagina: pagina
            }
          );

        }
      });

      // se quitan elementos que no se desean consultar
      this.paginasActuales = this.paginasActuales.filter(item =>
        paginas.includes(item.pagina) && 
        item.pagina > 0 && 
        item.pagina <= this.cantidadPaginas
      );

    }
    if(apiServices.length > 0) {
      this.spinner.show();
      this.llamadaObtenerPaginas(apiServices, paginaActual);
    } else {
      this.spinner.hide();
    }
  }

  llamadaObtenerPaginas(apiServices: any[], paginaActual: number): void {
    const services = apiServices.map(e => e?.service);

    forkJoin(services).subscribe(
      resp => {
        if(this.paginasActualesErrores.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
          const mensajesMostrados: Set<string> = new Set();
      
          this.paginasActualesErrores.forEach(e => {
            const mensaje: string = e?.error || 'Ha ocurrido con una de las paginas consultadas';
            if (!mensajesMostrados.has(mensaje)) {
              this.alertService.error(mensaje);
              mensajesMostrados.add(mensaje);
            }
          });
        }

        const elemento: any = this.paginasActuales.find(e => e.pagina == (paginaActual))
        this.paginaActual = elemento?.response || [undefined];
        this.obtenerCantRegistrosFiltrados(paginaActual);
        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por las paginas');
        this.spinner.hide();
      }
    );
  }

  setErrorCrear(tipo: string, error: string): void {
    this.erroresCrear.push({
      tipo: tipo,
      error: error
    })
  }

  setResponseCrear(response: any, tipo: string): void {
    this.responsesCrear.push({
      tipo: tipo,
      response: response || null
    });
  }

  getServiceCrearCesion(organizacion: string, rut: string, tipo: string, credencial: string): any {
    return this.cesionFacturasService.crearReporte(organizacion, rut, tipo, credencial)
      .pipe(
        map(resp => {
          this.setResponseCrear(resp, tipo);
        })
      )
      .pipe(
        catchError((error) => (this.setErrorCrear(tipo, error?.error?.message || 'Error Inesperado en servicio'), of(null))));
  }

  actualizar(): void {
    //this.limpiarFiltros();
    this.erroresCrear = [];
    this.responsesCrear = [];
    this.spinner.show();
    this.credencialesService.buscarSecretos().subscribe(
      (data: any) => {
        if(this.validaCredencialReturn(data?.services)){
          this.credsNoConfiguradas = false;
          this.spinner.hide();
          this.consultarCrearReporte(this.rut);
        } else {
          this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
          this.itemCredencialSelected = 'SII';
          this.itemCredencialSelectedType = 'ORG';
          this.credsNoConfiguradas = true;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
        this.spinner.hide();
    });

  }

  validaCredencialReturn(lista: any[]): boolean {
    if(lista && lista.length > 0){
      const objeto = lista.find(e => e?.name === 'SII')
      if(objeto && objeto?.configurationStatus === true){
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  consultarCrearReporte(rut: string, password: string = ''): void {
    const apiServices: any = [];
    
    let fechaInicioConsulta: string = '';
    let fechaFinConsulta: string = '';

    if(this.fechaInicio && this.fechaFin) {
      const fechaInicio = new Date(this.fechaInicio);
      const fechaFin = new Date(this.fechaFin);
      

      if(fechaInicio > fechaFin) {
        this.alertService.error('La fecha desde no puede ser mayor a la fecha hasta.');
        return;
      }

      // Valida que ambas fechas no sean de hace más de 12 meses
      const fechaLimite = new Date();
      fechaLimite.setMonth(fechaLimite.getMonth() - 12);
      if (fechaInicio < fechaLimite || fechaFin < fechaLimite) {
        this.alertService.error('Las fechas para actualizar no deben ser mas antiguas de 12 meses.');
        return;
      }
      // validar que la fecha inicio y fin no sean mayores a la fecha actual
      const fechaActual = new Date();
      if (fechaInicio > fechaActual || fechaFin > fechaActual) {
        this.alertService.error('Las fechas para actualizar no deben ser mayores a la fecha actual.');
        return;
      }

      // validar que la diferencia de meses no sea mayor a 12
      const diferenciaMeses = (fechaFin.getFullYear() - fechaInicio.getFullYear()) * 12 + (fechaFin.getMonth() - fechaInicio.getMonth());
      if (diferenciaMeses > 12) {
        this.alertService.error('El rango de fechas no debe ser mayor a 12 meses.');
        return;
      }

      fechaInicioConsulta = this.fechaInicio;
      fechaFinConsulta = this.fechaFin;

    } else {
      fechaInicioConsulta = this.getFecha90Dias();
      fechaFinConsulta = this.getFechaActualFiltro();
    }
    const organizacion = this._sessionService.getOrganization();
    this.spinner.show();
    apiServices.push(this.getServiceCrearCesion(organizacion, rut, 'cedente', password));
    apiServices.push(this.getServiceCrearCesion(organizacion, rut, 'cesionario', password));
    if(apiServices.length > 0) {
      this.spinner.show();
      forkJoin(apiServices).subscribe(
        resp => {
          if(this.erroresCrear.length > 0) { // valida si hay errores y si estan repetidos para mostrarlos
            const mensajesMostrados: Set<string> = new Set();
        
            this.erroresCrear.forEach(e => {
              const mensaje: string = e?.error || 'Ha ocurrido al crear uno de los reportes de cesión de facturas';
              if (!mensajesMostrados.has(mensaje)) {
                this.alertService.error(mensaje);
                mensajesMostrados.add(mensaje);
              }
            });
            this.spinner.hide();
          } else {
            // siempre seran dos elementos en el array de respuestas
            const id1 = this.responsesCrear[0]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.IDTransaccion || null;
            const id2 = this.responsesCrear[1]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.IDTransaccion || null;

            const estado1 = this.responsesCrear[0]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion || null;
            const estado2 = this.responsesCrear[1]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.EstadoTransaccion || null;

            const subEstado1 = this.responsesCrear[0]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion || null;
            const subEstado2 = this.responsesCrear[1]?.response?.CesionFacturasReporte?.DatosBasicosSolicitud?.SubEstadoTransaccion || null;

            if(estado1 === 'ERROR' || estado2 === 'ERROR' || subEstado1  === 'ERROR' || subEstado2 === 'ERROR') {
              this.alertService.error('Ha ocurrido un error al crear el reporte de cesión de facturas');
              this.spinner.hide();
              return;
            }

            if(id1 && id2) {
              this.alertService.warn('Se esta ejecutando la actualización del reporte, cuando este finalizado se le notificará.');
              this.asyncService.llamadaCreacionCesionFacturas(organizacion, rut, id1, id2, fechaInicioConsulta, fechaFinConsulta);
            } else {
              this.alertService.error('Ha ocurrido un error al crear el reporte de cesión de facturas');
            }
            this.spinner.hide();
          }
        },
        error => {
          this.alertService.error(error.message || 'Ocurrió un error al crear el reporte de cesión de facturas');
          this.spinner.hide();
        }
      );
    } else {
      this.spinner.hide();
    }
  }

  marcarError(id: string, isError: boolean): void {
    const input = document.getElementById(id) as HTMLElement;
    if(input){
      if(isError === true){
        if ( !input.classList.contains('input-error') ){
          input.classList.add('input-error');
        }
      } else {
        if ( input.classList.contains('input-error') ){
          input.classList.remove('input-error');
        }
      }
    }
  }

  limpiarErrores(): void {
    this.marcarError('fechaInicio', false);
    this.marcarError('fechaFin', false);
    this.marcarError('rutCedente', false);
    this.marcarError('rutDeudor', false);
    this.marcarError('rutVendedor', false);
    this.marcarError('estadoDocumento', false);
    this.marcarError('estadoCesion', false);
    this.marcarError('estadoDocumentoNc', false);
  }

  limpiarFiltros(): void {
    this.fechaInicio = this.getFecha90Dias(),
    this.fechaFin = this.getFechaActualFiltro()
    this.rutCedente = '';
    this.rutDeudor = '';
    this.rutVendedor = '';
    this.estadoDocumento = '';
    this.estadoCesion = '';
    this.estadoDocumentoNc = '';
    this.limpiarErrores();
  }

  buscar(): void {
    this.limpiarErrores();
    // si hay fecha desde debe haber fecha hasta y viceversa, validar que la fecha desde no sea mayor a la fecha hasta
    if((this.fechaInicio && !this.fechaFin) || (!this.fechaInicio && this.fechaFin)) {
      this.alertService.error('Debe ingresar ambas fechas para obtener el reporte.');
      this.marcarError('fechaInicio', true);
      this.marcarError('fechaFin', true);
      return;
    }
    if(this.fechaInicio && this.fechaFin) {
      const fechaInicio = new Date(this.fechaInicio);
      const fechaFin = new Date(this.fechaFin);

      if(fechaInicio > fechaFin) {
        this.marcarError('fechaInicio', true);
        this.marcarError('fechaFin', true);
        this.alertService.error('La fecha desde no puede ser mayor a la fecha hasta.');
        return;
      }
    }
    // si existe rut cedente validar que el rut cedente sea valido
    if(this.rutCedente && !rutValidate(this.rutCedente)) {
      this.alertService.error('El rut cedente no es valido.');
      this.marcarError('rutCedente', true);
      return;
    }
    // si existe rut deudor validar que el rut deudor sea valido
    if(this.rutDeudor && !rutValidate(this.rutDeudor)) {
      this.alertService.error('El rut deudor no es valido.');
      this.marcarError('rutDeudor', true);
      return;
    }
    // si existe rut vendedor validar que el rut vendedor sea valido
    if(this.rutVendedor && !rutValidate(this.rutVendedor)) {
      this.alertService.error('El rut vendedor no es valido.');
      this.marcarError('rutVendedor', true);
      return;
    }
    // si existe estado que sea uno de los estados validos
    if(this.estadoDocumento && !this.estadoDocumentoOptions.find(e=> e.name == this.estadoDocumento)) {
      this.alertService.error('El estado del documento no es valido.');
      this.marcarError('estadoDocumento', true);
      return;
    }

    if(this.estadoCesion && !this.estadoCesionOptions.find(e => e.name == this.estadoCesion)) {
      this.alertService.error('El estado de la cesión no es valido.');
      this.marcarError('estadoCesion', true);
      return;
    }

    if(this.estadoDocumentoNc && !this.estadoNotaCreditoOptions.find(e => e.name == this.estadoDocumentoNc)) {
      this.alertService.error('El estado de la nota de crédito asociada no es valido.');
      this.marcarError('estadoDocumentoNc', true);
      return;
    }
    
    this.filtrosUltimaBusqueda = {};
    this.filtrosUltimaBusqueda = this.obtenerFiltros();
    this.paginasActuales = [];
    this.getEstados();
    this.obtenerCantidadElementos(); // enviar con los filtros
  }

  validarEstado(element: any): string {
    if(element.estado && this.getEstadoDocumento(element?.estado).toUpperCase() === 'RECHAZADA' || 
      this.getEstadoDocumento(element?.estado).toUpperCase() === 'ANULADA') {
      return ' rojo';
    }

    const fechaDoc = new Date(element?.FCH_EMIS_DTE);    
    const today = new Date();
    const diffTime = Math.abs(today.getTime() - fechaDoc?.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if(element.tieneAcuse || (diffDays && diffDays >= 8)) {
      return ' verde';
    }

    if(!element.tieneAcuse || (diffDays && diffDays < 8)) {
      return ' amarillo';
    }

    return '';
  }

  validarEstadoActualizado(): boolean {
    let response = false;
    if(this.paginaActual && this.paginaActual.length > 0) {
      response = this.paginaActual.some((element) => element?.status != 'completado' && element?.estaPagado != 1);
    }
    return response;
  }

  validarEstadoActualizadoElement(element: any): boolean {
    return element?.status == 'completado' || element?.estaPagado == 1 ? false : true;
  }

  actualizarPagado(element: any, index: number): void { 
    if(!element?.FOLIO_DOC){
      this.alertService.error('No se ha podido actualizar el estado de pagado.');
      return;
    }
    this.spinner.show();
    this.cesionFacturasService.actualizarEstadoPagado(this.rut, element?.FOLIO_DOC, element?.TIPO_DOC, element?.VENDEDOR).subscribe(
      (data: any) => {
        this.alertService.success(data?.message || 'Se ha actualizado correctamente el estado de pagado.');
        if(this.paginaActual.length <= 1) {
          this.ultimaPagina = this.currentPage - 1;
        } else {
          this.ultimaPagina = this.currentPage;
        }
        this.paginasActuales = [];
        this.obtenerCantidadElementos(true, this.ultimaPagina); 
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al actualizar el estado de la factura.');
        this.spinner.hide();
    });

    this.obtenerCantRegistrosFiltrados(this.currentPage);
  }

  actualizarRegistro(element: any, index: number): void {
    if(!element?.FOLIO_DOC){
      this.alertService.error('No se ha podido actualizar el estado de pagado.');
      return;
    }
    this.spinner.show();
    this.cesionFacturasService.actualizarDTEUnitario(this.rut, element?.VENDEDOR, element?.FOLIO_DOC, element?.TIPO_DOC).subscribe(
      (data: any) => {
        this.alertService.success(data?.message || 'Se ha actualizado correctamente la factura.');
        if(this.paginaActual.length <= 1) {
          this.ultimaPagina = this.currentPage - 1;
        } else {
          this.ultimaPagina = this.currentPage;
        }
        this.paginasActuales = [];
        this.obtenerCantidadElementos(true, this.ultimaPagina);
        this.spinner.hide();
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al actualizar la factura.');
        this.spinner.hide();
    });
  }

  validaNotaDeCredito(value: string): string {
    if(value){
      return ['60','61','112'].includes(value.toString())? 'Si' : 'No';
    }
    return 'No';
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  configurarCreds(): void {
    if(this.itemCredencialSelected && this.itemCredencialSelectedType) {
      this.modalService.open();
    } else {
      console.error('No se ha seleccionado un tipo de credencial');
    }
  }

  volverModalCredenciales(): void {
    this.showModal = false;
    this.credsNoConfiguradas = false;

    setTimeout(() => {
      this.showModal = true;
    }, 200);

    this.spinner.show();
    this.credencialesService.buscarSecretos().subscribe(
      (data: any) => {
        if(this.validaCredencialReturn(data?.services)){
          this.credsNoConfiguradas = false;
          this.spinner.hide();
        } else {
          this.alertService.warn('No se han encontrado las credenciales del rut sincronizadas.');
          this.itemCredencialSelected = 'SII';
          this.itemCredencialSelectedType = 'ORG';
          this.credsNoConfiguradas = true;
          this.spinner.hide();
        }
      },
      ({ error }) => {
        this.alertService.error(error.message || 'Ha ocurrido un error al obtener las credenciales del rut.');
        this.spinner.hide();
    });
  }

  getEstadoCesion(estado: string): string {
    return this.estadoCesionOptions.find(e => e.name === estado)?.displayName || '';
  }

  getEstadoDocumento(estado: string): string {
    return this.estadoDocumentoOptions.find(e => e.name === estado)?.displayName || '';
  }

  getEstadoNotaCredito(estado: string): string {
    return this.estadoNotaCreditoOptions.find(e => e.name === estado)?.displayName || '';
  }

}
